import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { TRANSFER_ORDER_ARCHIVE_PATH } from '../../model-shared/collection-names';
import { ITransferOrder } from '../../model-shared/transferOrder';

@Injectable({
  providedIn: 'root',
})
export class TransferOrderFirestore extends NgxsFirestore<ITransferOrder> {
  protected path = TRANSFER_ORDER_ARCHIVE_PATH;
}
