<div class="row">
  <div class="col-md-12">
    <nb-card id="updateemail">
      <nb-card-header>Please click the link we sent to {{(userAuth$ | async)?.email}} </nb-card-header>
      <nb-card-body>
        <form class="login100-form validate-form">

          <!-- <button type="button" nbButton status="danger" (click)="navigateEmail()" >Change Email</button>  &nbsp; -->
          <button type="button" nbButton status="danger" (click)="navigateDashboard()">I already clicked the link</button> &nbsp;

          <br/>
          <br/>
          <div *ngIf="emailNotVerifiedYet">Not, you did not </div>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>
