export const environment = {
  production: false,
  firebaseConfig: {},
  firebaseKey: {
    apiKey: 'AIzaSyA4biQ4L9sM5yQ9TTuCd8PSQb54u-Yyums', // # pragma: allowlist secret
    authDomain: 'indie-preprod.firebaseapp.com',
    databaseURL: 'https://indie-preprod.firebaseio.com',
    projectId: 'indie-preprod',
    storageBucket: 'indie-preprod.appspot.com',
    messagingSenderId: '439560949232',
    appId: '1:439560949232:web:c91947640222c67255a135',
    measurementId: 'G-C58BGT1VCE',
  },
  tosUrl: 'https://indie.money/tos/',
  privacyPolicyUrl: 'https://indie.money/user-agreement-2/',
  PublicDSN: 'https://ae2f9e2bc95d4d8f958b1a027d3503db@o451229.ingest.sentry.io/5436807',
  passbaseKey: 'KvC9gKlTp8YN9uqEn0wm5zU40bDgiced09wnmVVzzacG1OWnvKUIULPcxJQLrSDX',
  getidapiurl: 'https://indie.sb.getid.dev',
  getidapikey: 'UUmoA79xDFuBRD',
};
