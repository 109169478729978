import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { PaymentRequestsActionsUpdate } from '@frontend-shared/actions/payment-requests.actions';
import { IPaymentRequestsStateModel } from '@frontend-shared/state/payment-requests.state';
import { IVaultStateModel } from '@frontend-shared/state/vault.state';
import { IPaymentRequest } from '@model-shared/payment-request';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { PAGES_PATH, PAYMENT_DETAILS, PAYOUT_LISTING } from '../../../navigation/navigation.paths';
@Component({
  selector: 'app-payment-list',
  styleUrls: ['./payment-list.component.scss', '../dashboard.component.scss'],
  templateUrl: './payment-list.component.html',
})
export class PaymentRequestListComponent {
  totalNumberOfRecordsInTable = 5;
  constructor(private store: Store, private observer: BreakpointObserver) {
    this.observer
      .observe('(min-width: 767px) and (max-width: 966px)')
      .subscribe((result) => (this.totalNumberOfRecordsInTable = result.matches ? 10 : 5));
  }
  @Select((a) => a.paymentRequest) paymentRequest$: Observable<IPaymentRequestsStateModel>;
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;
  @Select((s) => s.vault) vault$: Observable<IVaultStateModel>;

  page = 1;
  public payoutDetail$: Observable<any>;
  // checkPayout(id: string): Observable<boolean>  {
  //   // this.payoutDetail$ = this.payoutFS
  //   //   .collection$((ref) => {
  //   //     return ref.where('paymentRequests', 'array-contains', id);
  //   //   })
  //   //   .pipe(map((res) => res));
  // }
  @Dispatch()
  payoutDetail(id: string) {
    return new Navigate([`/${PAGES_PATH}/${PAYOUT_LISTING}/${id}`]);
  }
  @Dispatch()
  paymentDetail(id: string) {
    // Display all the data about a payment request in a separate route with the payment request id in the route
    return new Navigate([`/${PAGES_PATH}/${PAYMENT_DETAILS}/${id}`]);
  }
  updatePaymentRequest(paymentRequest: IPaymentRequest) {
    this.store.dispatch(new PaymentRequestsActionsUpdate(paymentRequest));
  }
}
