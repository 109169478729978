import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Emitted, NgxsFirestoreConnect, StreamEmitted } from '@ngxs-labs/firestore-plugin';
import firebase from 'firebase';
import { filter, take } from 'rxjs/operators';

import { ILoginWithEmailPassword, IUser } from '../../model-shared/user';
import {
  AnonSignIn,
  LoginWithEmailPass,
  SignOutUser,
  UpdateProfile,
  UpdateUser,
} from '../actions/auth.actions';
import { FireFunctionsService } from '../services/fire-functions.service';
import { VisualTracker } from '../services/visualtracker.service';
import User = firebase.User;

export class IUserModel {
  userData: IUser;
  pk: any;
}

@State<IUserModel>({
  name: 'userData',
  defaults: {
    userData: null,
    pk: null,
  },
})
@Injectable({ providedIn: 'root' })
export class AuthState implements NgxsOnInit {
  sessionTimeout: any = null;

  @Selector()
  static getAuthUser(state: IUserModel): IUser {
    return state.userData;
  }

  @Selector()
  static getPK(state: IUserModel): any {
    return state.pk;
  }

  constructor(
    injector: Injector,
    private store: Store,
    private cloudFunctions: FireFunctionsService,
    private auth: AngularFireAuth,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private vt: VisualTracker,
    translate: TranslateService,
  ) {
    translate.use('en');
  }

  @Action(StreamEmitted(UpdateUser))
  get(ctx: StateContext<IUserModel>, { payload: user }: Emitted<UpdateUser, User>) {
    this.vt.setUserId(user?.uid);
    ctx.setState({
      userData: {
        displayName: user?.displayName,
        phoneNumber: user?.phoneNumber,
        photoURL: user?.photoURL,
        providerId: user?.providerId,
        uid: user?.uid,
        emailVerified: user?.emailVerified,
      },
      pk: null,
    });
    this.setSessionTimeOut(user);
  }

  private setSessionTimeOut(user: firebase.User) {
    if (user === null) {
      this.sessionTimeout && clearTimeout(this.sessionTimeout);
      this.sessionTimeout = null;
    } else {
      // User is logged in.
      // Fetch the decoded ID token and create a session timeout which signs the user out.
      user
        .getIdTokenResult()
        .then((idTokenResult) => {
          // Make sure all the times are in milliseconds!
          const authTime = idTokenResult.claims.auth_time * 1000;
          // one hour timeout
          const sessionDuration = 1000 * 60 * 60 * 12; // twelve hours
          const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
          this.sessionTimeout = setTimeout(
            () => this.store.dispatch(new SignOutUser(true)),
            millisecondsUntilExpiration,
          );
        })
        .catch(console.error);
    }
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
    this.ngxsFirestoreConnect.connect(UpdateUser, {
      to: () => this.auth.authState.pipe(filter((u) => u !== null && u !== undefined)),
    });
    ctx.dispatch(new UpdateUser());
  }

  @Action(SignOutUser)
  async signOut(ctx: StateContext<IUserModel>, payload: any) {
    await this.auth.signOut();
    if (payload.payload) window.location.reload();
  }

  @Action(LoginWithEmailPass)
  loginEmailPass(ctx: StateContext<ILoginWithEmailPassword>, { payload }: LoginWithEmailPass) {
    this.auth
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then(() => {
        // will be redirected automatically
      })
      .catch(console.error);
  }

  @Action(AnonSignIn)
  async anonLogin() {
    await this.auth.signInAnonymously();
  }

  @Action(UpdateProfile)
  updateProfile(ctx: StateContext<IUserModel>, { payload }: UpdateProfile) {
    this.auth.user
      .pipe(
        filter((a) => !!a),
        take(1),
      )
      .subscribe(async (u) => {
        const { displayName, photoURL, prId } = payload;
        await u.updateProfile({ displayName, photoURL });
        let pk = null;
        if (prId)
          try {
            pk = (
              await Promise.all([
                this.cloudFunctions.getPublicKey()({}).toPromise(),
                this.cloudFunctions.getGracePeriod(prId)({}).toPromise(),
              ])
            )[0];
          } catch (error) {
            console.error('reload on gracePeriod/getPk ' + prId, error);
            window.location.href = '/' + prId;
          }
        // we are in the buyer part

        ctx.setState(
          patch({
            userData: patch({
              ...payload,
            }),
            pk,
          }),
        );
      });
  }
  /*
  @Action(UpdateEmail)
  updateEmail(ctx: StateContext<IUserModel>, { payload: email }: UpdateEmail) {
    try {
      if (email) {
        this.auth.currentUser.then(async (user) => {
          try {
            await user.verifyBeforeUpdateEmail(email);
            //  await user.sendEmailVerification();
          } catch (e) {
            console.error(e);
            window.location.reload();
          }
        });

        const poll = setInterval(() => {
          // poll user every second until it is verified
          this.auth.currentUser.then(async (user) => {
            await user.reload();
            console.error(JSON.stringify(user));

            if (user.emailVerified) {
              ctx.setState(
                patch({
                  userData: patch({
                    emailVerified: true,
                  }),
                }),
              );
              clearInterval(poll);
            }
          });
        }, 2000);
      }
      ctx.setState(
        patch({
          userData: patch({
            email,
            emailVerified: false,
          }),
        }),
      );
    } catch (e) {
      console.warn('email verify - safely ignore ?', e);
    }
  } */
}
