import { Component } from '@angular/core';
import { ITransferOrder } from '@model-shared/transferOrder';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-transfer-list',
  templateUrl: './transfer-list.component.html',
  styleUrls: ['./transfer-list.component.scss', '../dashboard.component.scss'],
})
export class TransferListComponent {
  @Select((a) => a.transferOrder.transferOrders) transOrders$: Observable<ITransferOrder[]>;
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;

  page = 1;
}
