<div class="scrollable-container wrapper">
  <div class="layout">
    <div class="layout-container">
      <div class="content">
        <div class="columns">
          <h1 id="title" class="title text-center" style="margin-top: 5%;">Login</h1>
          <firebase-ui
            (signInFailure)="errorCallback($event)"
            (signInSuccessWithAuthResult)="successCallback()"
          >
          </firebase-ui>
          <section class="another-action" aria-label="Register">
            Don't have an account? <a class="text-link" routerLink="../register">Join indie today !</a>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
