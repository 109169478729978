import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { IFreelancer } from '@model-shared/user';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-updateemail',
  styleUrls: ['./updateEmail.component.scss'],
  templateUrl: './updateEmail.component.html',
})
export class UpdateEmailComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  @Select((s) => s.user.user) user$: Observable<IFreelancer>;
  @Input() headerStringBinding: string;
  user: any;
  headerStr = `Welcome To Indie - Let's start with your email address`;
  disableButton = false;
  incorrect = true;
  editForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  constructor(private store: Store) {}
  ngOnInit() {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data && data.email) this.editForm.controls.email.patchValue(data.email);
    });
    if (
      this.headerStringBinding !== undefined &&
      this.headerStringBinding !== null &&
      this.headerStringBinding.length > 0
    ) {
      this.headerStr = this.headerStringBinding;
    }
  }
  onSubmit(): void {
    this.disableButton = true;
    this.store.dispatch(new UsersActionsUpdate({ email: this.editForm.value.email }));
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
