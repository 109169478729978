import { Pipe, PipeTransform } from '@angular/core';
import { PaymentRequestStatusFirestore } from '@frontend-shared/firestore/payment-request-status.firestore';
import { IPaymentRequestStatus } from '@model-shared/payment-request-status';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Pipe({ name: 'PaymentRequestStatus' })
export class PaymentRequestStatusPipe implements PipeTransform {
  constructor(private paymentRSFS: PaymentRequestStatusFirestore) {}

  transform(lastStatusID: string): Observable<string> {
    return this.paymentRSFS.doc$(lastStatusID).pipe(
      filter((a) => !!a),
      map((res: IPaymentRequestStatus) => res.state),
    );
  }
}
