import { Pipe, PipeTransform } from '@angular/core';
import { Select } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { USD_CURRENCY } from '../../model-shared/typedef';

@Pipe({
  name: 'currencyConvert',
})
export class CurrencyConvertPipe implements PipeTransform {
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;
  @Select((state: any) => state.transferOrder.exchangeQuotes) exchangeQuote$: Observable<{
    [index: string]: number;
  }>;
  transform(value: number, forceCurrency?: string, invert?: boolean): Observable<number> {
    if (forceCurrency)
      return this.exchangeQuote$.pipe(
        map((exchangeQuote) => {
          if (exchangeQuote === undefined || (exchangeQuote === exchangeQuote) === null) {
            return value;
          } else {
            const quoteElement = exchangeQuote[USD_CURRENCY + forceCurrency];
            const exchangeQuoteElement = invert ? 1 / quoteElement : quoteElement;
            return forceCurrency === undefined ||
              forceCurrency === null ||
              false ||
              exchangeQuote === null ||
              forceCurrency === USD_CURRENCY
              ? Number(value.toFixed(2))
              : Number((value / exchangeQuoteElement).toFixed(2));
          }
        }),
      );
    return combineLatest([this.exchangeQuote$, this.currency$]).pipe(
      map((a) => {
        const currency = forceCurrency ? forceCurrency : a[1];
        const exchangeQuote = a[0];
        return currency === undefined ||
          currency === null ||
          exchangeQuote === undefined ||
          exchangeQuote === null ||
          currency === USD_CURRENCY
          ? value
          : invert
          ? 1 / (value * exchangeQuote[USD_CURRENCY + currency])
          : value * exchangeQuote[USD_CURRENCY + currency];
      }),
    );
  }
}
