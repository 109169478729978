import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[addrAutocomplete]',
})
export class AddrAutoCompleteDirective {
  // private _chrome = navigator.userAgent.indexOf('Chrome') > -1;
  constructor(private _el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line complexity
  onKeyDown() {
    if (this._el.nativeElement.value.length > 2) {
      if (this._el.nativeElement.getAttribute('autocomplete') === 'address-line1') {
        setTimeout(() => {
          this._el.nativeElement.setAttribute('autocomplete', 'offoff');
        });
      }
    } else {
      if (this._el.nativeElement.getAttribute('autocomplete') === 'offoff') {
        setTimeout(() => {
          this._el.nativeElement.setAttribute('autocomplete', 'address-line1');
        });
      }
    }
    //   if (this._chrome) {
    // if (this._el.nativeElement.getAttribute('autocomplete') === 'off') {
    //   setTimeout(() => {
    //     this._el.nativeElement.setAttribute('autocomplete', 'offoff');
    //   });
    // }
  }
  // }
}
