import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphaSpecial]',
})
export class AlphaSpecialDirective {
  regex =
    /^[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\u0181\u018A\u018E-\u0190\u0194\u0198\u0199\u01A0\u01A1\u01AF\u01B0\u01B3\u01B4\u01BF\u01CD-\u01D4\u01DD\u01E2\u01E3\u01E6\u01E7\u01F7\u01FA-\u01FF\u0218-\u021D\u0232\u0233\u0253\u0257\u0259\u025B\u0263\u02BC\u0308\u0328\u1E0C\u1E0D\u1E24\u1E25\u1E62\u1E63\u1E6C\u1E6D\u1E80-\u1E85\u1E92\u1E93\u1E9E\u1EB8\u1EB9\u1ECA-\u1ECD\u1EE4\u1EE5\u1EF2\u1EF3\u1EF8\u1EF9]+$/;
  inputElement: HTMLInputElement;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line complexity
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.includes(e.key) ||
      (e.key === 'a' && e.ctrlKey) ||
      (e.key === 'c' && e.ctrlKey) ||
      (e.key === 'v' && e.ctrlKey) ||
      (e.key === 'x' && e.ctrlKey) ||
      (e.key === 'a' && e.metaKey) ||
      (e.key === 'c' && e.metaKey) ||
      (e.key === 'v' && e.metaKey) ||
      (e.key === 'x' && e.metaKey) // Allow: Cmd+X (Mac)
    ) {
      return;
    }
    if (e.key !== ' ' && (!Number.isNaN(Number(e.key)) || !this.regex.test(e.key))) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedInput: string = clipboardData.getData('text/plain');
      if (!this.regex.test(pastedInput)) {
        event.preventDefault();
      }
    }
  }
}
