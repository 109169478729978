import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { EXCHANGE_RATE_PATH } from '../../model-shared/collection-names';
import { IExchangeRate } from '../../model-shared/exchangeRate';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRateFirestore extends NgxsFirestore<IExchangeRate> {
  protected path = EXCHANGE_RATE_PATH;
}
