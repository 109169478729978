import { Component } from '@angular/core';
import { AuthState } from '@frontend-shared/state/auth.state';
import { IUser } from '@model-shared/user';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { navigateToDashboard } from '../../navigation/navigation.action';

@Component({
  selector: 'ngx-verify',
  styleUrls: ['./verify-email.component.scss'],
  templateUrl: './verify-email.component.html',
})
export class VerifyEmailComponent {
  @Select(AuthState.getAuthUser) userAuth$: Observable<IUser>;

  isEmail = false;
  emailNotVerifiedYet = false;
  constructor(private store: Store) {}
  navigateDashboard() {
    this.userAuth$.pipe(take(1)).subscribe((u) => {
      if (!u.emailVerified) {
        this.emailNotVerifiedYet = true;
      } else this.store.dispatch(navigateToDashboard);
    });
  }
  navigateEmail() {
    // this.store.dispatch(new UpdateEmail(null));
  }
}
