<nb-card>
  <nb-card-header>{{ 'PAYMENT_REQUEST.form_title' | translate }} ( min: $ {{(vault$ | async)?.vault?.minPaymentRequestAmount }}
    / max: $ {{(vault$ | async)?.vault?.maxPaymentRequestAmount }}
    / last 7 days: $ {{(vault$ | async)?.vault?.vault_3_7days }}
    / left: $ {{(vault$ | async)?.vault?.vault_3_7days_left }} )</nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <form [formGroup]="paymentRequest" class="login100-form validate-form" ngxsForm="paymentRequestForm.details"
          (ngSubmit)="onSubmit()"> 
            <div class="col-md-12">
              <div class="col-md-4 col-4">
                <label class="label" for="amount">Amount</label>
                <input type="text" formControlName="amount" nbInput fullWidth id="amount" name="amount"
                  placeholder="{{ 'PAYMENT_REQUEST.amount' | translate }}" />
                <span class="caption status-danger ng-star-inserted"
                  *ngIf="paymentRequest.controls.amount.touched && paymentRequest.controls.amount.errors?.required">
                  {{ 'PAYMENT_REQUEST.please_enter_amount' | translate }}
                </span>
              </div>
              <div class="col-md-4 col-4">
                <input type="text" formControlName="phone" nbInput fullWidth id="phone" [appNospace]="true" appPhoneno
                  placeholder="{{ 'PAYMENT_REQUEST.phone' | translate }}"/>
                <span class="caption status-danger ng-star-inserted"
                  *ngIf="paymentRequest.controls.phone.touched && paymentRequest.controls.phone.errors?.required">
                  {{ 'PAYMENT_REQUEST.please_enter_phone' | translate }}
                </span>
                <span class="caption status-danger ng-star-inserted"
                  *ngIf="paymentRequest.controls.phone.touched && paymentRequest.controls.phone.errors?.validPhoneNo">
                  {{ 'PAYMENT_REQUEST.please_enter_valid_phone' | translate }}
                </span>
              </div>
              <div class="col-md-4 col-4">
                <input type="text" formControlName="reason" nbInput fullWidth id="reason"
                  placeholder="{{ 'PAYMENT_REQUEST.reason' | translate }}" />
                <span class="caption status-danger ng-star-inserted"
                  *ngIf="paymentRequest.controls.reason.touched && paymentRequest.controls.reason.errors?.required">
                  {{ 'PAYMENT_REQUEST.please_enter_reason' | translate }}
                </span>

                <span class="caption status-danger ng-star-inserted"
                  *ngIf="(paymentRequest.controls.reason.touched) && paymentRequest.controls.reason.errors?.maxlength">
                  Reason length should be 25 characters
                </span>
              </div>
            </div>
          <div class="col-md-12  col-12" *ngIf="paymentRequest.controls.amount.value || paymentRequest.controls.reason.value">
                  <p>Hi, Please pay
                  {{paymentRequest.controls.amount.value}} USD to {{(user$ | async)?.user?.nickname}} for
                  {{paymentRequest.controls.reason.value}} </p>
                  <button type="submit" [disabled]="!paymentRequest.valid || disabledSubmitButton" nbButton
                    status="danger">{{ 'PAYMENT_REQUEST.send' | translate }}</button>
            </div>
        </form>
      </div>
    </div>
  </nb-card-body>
</nb-card>
