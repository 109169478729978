<div class="row">
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" >
        <indie-new-payment-widget></indie-new-payment-widget>
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <app-payment-list></app-payment-list>
    </div>
</div>
<div class="row">
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <indie-inbox-widget></indie-inbox-widget>
        
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <indie-vault-widget id="amountLabel"></indie-vault-widget>
        
    </div>
</div>
<div class="row">
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" >
        <available-inbox-widget ></available-inbox-widget>
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <indie-cash-out></indie-cash-out>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <ngx-invite-list></ngx-invite-list>
        <app-transfer-request-list></app-transfer-request-list>
        <app-transfer-order *ngIf="isPreproduction"></app-transfer-order>
    </div>
</div>
<a class="typeform-share button" href="https://c27gfmcxpks.typeform.com/to/PpFp0Ecw"
   data-mode="popover"
   style="width:54px;height:54px;position:fixed;box-shadow:0px 2px 12px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08);right:26px;bottom:26px;border-radius:50%;display:flex;align-items:center;justify-content:center;cursor:pointer;background:#3A7685;overflow:hidden;line-height:0;"
   data-hide-headers="true" data-hide-footer="true" target="_blank"> <span class="icon">             <i
  class="fa fa-exclamation-circle fa-3x" aria-hidden="true" style="color:#ffffff"></i>
</span> </a>
<script> (function() {
    var qs, js, q, s, d = document, gi = d.getElementById, ce = d.createElement, gt = d.getElementsByTagName,
      id = "typef_orm_share", b = "https://embed.typeform.com/";
    if (!gi.call(d, id)) {
        js = ce.call(d, "script");
        js.id = id;
        js.src = b + "embed.js";
        q = gt.call(d, "script")[0];
        q.parentNode.insertBefore(js, q);
    }
})(); </script>
