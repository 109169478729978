import { ILoginWithEmailPassword, IUpdateUserProfile } from '../../model-shared/user';
import {
  loginWithEmailAndPass,
  SAnonSignin,
  signOut,
  updateUser,
  updateUserProfile,
} from '../staticnames';

export class UpdateUser {
  static readonly type = updateUser;
}
export class AnonSignIn {
  static readonly type = SAnonSignin;
}
export class SignOutUser {
  static readonly type = signOut;
  constructor(public payload?: boolean) {}
}
export class LoginWithEmailPass {
  static readonly type = loginWithEmailAndPass;
  constructor(public payload: ILoginWithEmailPassword) {}
}
export class UpdateProfile {
  static readonly type = updateUserProfile;
  constructor(public payload: IUpdateUserProfile) {}
}

/*
export class UpdateEmail {
  static readonly type = updateEmail;
  constructor(public payload: string) {}
} */
