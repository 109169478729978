<nb-card accent="info">
    <nb-card-header>You're in!</nb-card-header>

    <nb-card-body>
        <div class="form-group">
            <nb-checkbox [(ngModel)]="checked1">I have read and I accept the <a href="https://indie.money/tos/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></nb-checkbox>
        </div>
        <div class="form-group">
            <nb-checkbox [(ngModel)]="checked2">I am more than 18 years old</nb-checkbox>
        </div>
        <div class="form-group">
            <nb-checkbox [(ngModel)]="checked3">I will provide feedback when I face a problem (bottom left button) </nb-checkbox>
        </div>

        <div class="form-group">
            <button type="submit" [disabled]="!checked1 || !checked3 || !checked2 || disabledSubmitButton" nbButton status="danger" (click)="onSubmit()">I Accept</button>
        </div>

    </nb-card-body>
</nb-card>


<a class="typeform-share button" href="https://c27gfmcxpks.typeform.com/to/PpFp0Ecw"
   data-mode="popover"
   style="width:54px;height:54px;position:fixed;box-shadow:0px 2px 12px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08);right:26px;bottom:26px;border-radius:50%;display:flex;align-items:center;justify-content:center;cursor:pointer;background:#3A7685;overflow:hidden;line-height:0;"
   data-hide-headers="true" data-hide-footer="true" target="_blank"> <span class="icon">             <i
  class="fa fa-exclamation-circle fa-3x" aria-hidden="true" style="color:#ffffff"></i>
</span> </a>
<script> (function() {
    var qs, js, q, s, d = document, gi = d.getElementById, ce = d.createElement, gt = d.getElementsByTagName,
      id = "typef_orm_share", b = "https://embed.typeform.com/";
    if (!gi.call(d, id)) {
        js = ce.call(d, "script");
        js.id = id;
        js.src = b + "embed.js";
        q = gt.call(d, "script")[0];
        q.parentNode.insertBefore(js, q);
    }
})(); </script>
