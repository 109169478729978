import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appCharsOnly]',
})
export class CharsOnlyDirective {
  private regex = new RegExp(/^[A-Za-z]*$/);
  private specialKeys: string[] = ['Backspace', 'Space', 'Tab', 'End', 'Home'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key).replace(/\s/g, '');
    if (next && !this.regex.test(String(next))) {
      event.preventDefault();
    }
  }
}
