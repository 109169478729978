import { IPaymentRequest } from '../../model-shared/payment-request';

export type PaymentRequestsActionsPayloadsUpdate = IPaymentRequest;
export type PaymentRequestsActionsPayloadsInsert = Partial<IPaymentRequest>;
export type PaymentRequestsActionsPayloadsCreate = Partial<IPaymentRequest>;

export class PaymentRequestsActionsGet {
  public static readonly type = '[PaymentRequests] Get';
  constructor(public payload: string) {}
}
export class PaymentRequestsActionsUpdate {
  public static readonly type = '[PaymentRequests] Update';
  constructor(public payload: PaymentRequestsActionsPayloadsUpdate) {}
}

export class PaymentRequestsActionsGetAll {
  public static readonly type = '[PaymentRequests] GetAll';
}

export class PaymentRequestsActionsCreate {
  public static readonly type = '[PaymentRequests] Create';
  constructor(public payload: PaymentRequestsActionsPayloadsCreate) {}
}

export class PaymentRequestsActionsNextPage {
  public static readonly type = '[PaymentRequests] NextPage';
}
