import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { VisualTracker } from '@frontend-shared/services/visualtracker.service';
import { AuthState } from '@frontend-shared/state/auth.state';
import { IUsersStateModel, UsersState } from '@frontend-shared/state/users.state';
import { isLocalHost, isPreproductionFE } from '@frontend-shared/tools/tools';
import { allowedFreelancerCountries } from '@model-shared/allowed-freelancer-countries';
import { IUser } from '@model-shared/user';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../buyer-front/src/environments/environment';
import { LayoutActionsSet, LayoutActionsSetPartial } from '../actions/layout.actions';
import {
  ACCEPT_TERMS_PATH,
  COUNTRY_UPDATE_PATH,
  GETID_UPDATE_PATH,
  INVITE_ONLY_PATH,
  LOADING_PATH,
  MAINTENANCE_PATH,
  PAGES_PATH,
  UPDATE_EMAIL_PATH,
  UPDATE_NICKNAME_PATH,
} from '../navigation/navigation.paths';

@Injectable({
  providedIn: 'root',
})
export class DashBoardUserStateGuard implements CanActivate {
  constructor(private router: Router, private store: Store, private tracker: VisualTracker) {}
  @Select(AuthState.getAuthUser) userAuth$: Observable<IUser>;

  @Select(UsersState.getUser) $user: Observable<IUsersStateModel>;

  canActivate = () =>
    combineLatest([this.userAuth$, this.$user]).pipe(
      map(([userAuth, u]) => {
        this.store.dispatch(new LayoutActionsSet(false));
        const loadingPath = this.router.parseUrl(`${LOADING_PATH}`);
        if (!userAuth) return loadingPath;
        this.store.dispatch(new LayoutActionsSetPartial(true));
        /*  if (!userAuth.emailVerified)
          return this.router.parseUrl(`${PAGES_PATH}/${VERIFY_EMAIL_PATH}`);*/
        if (u?.maintenance) return this.router.parseUrl(`${PAGES_PATH}/${MAINTENANCE_PATH}`);

        if (!u.user) return loadingPath;

        if (!u.user.email) return this.router.parseUrl(`${PAGES_PATH}/${UPDATE_EMAIL_PATH}`);

        if (
          u.user.sponsorCode === undefined ||
          u.user.sponsorCode === null ||
          u.user.sponsorCode === 'INVALID'
        )
          return this.router.parseUrl(`${PAGES_PATH}/${INVITE_ONLY_PATH}`);

        if (!u.details || !u.plan) return loadingPath;

        if (
          !allowedFreelancerCountries.includes(u.user.nationality) ||
          !allowedFreelancerCountries.includes(u.user.domicile)
        )
          return this.router.parseUrl(`${PAGES_PATH}/${COUNTRY_UPDATE_PATH}`);

        if (!u.details.kycAccepted) {
          if (!isPreproductionFE(environment) && !isLocalHost) this.tracker.stop();
          return this.router.parseUrl(`${PAGES_PATH}/${GETID_UPDATE_PATH}`);
        }

        if (!isPreproductionFE(environment) && !isLocalHost) this.tracker.restart();

        if (
          u?.user.termsAccepted === null ||
          u?.user.termsAccepted === undefined ||
          u?.user.termsAccepted === false
        )
          return this.router.parseUrl(`${PAGES_PATH}/${ACCEPT_TERMS_PATH}`);

        if (
          u?.user.nickname === null ||
          u?.user.nickname === undefined ||
          u?.user.nickname.length === 0
        )
          return this.router.parseUrl(`${PAGES_PATH}/${UPDATE_NICKNAME_PATH}`);
        this.store.dispatch(new LayoutActionsSet(true));
        return true;
      }),
    );
}
