/* eslint-disable no-return-assign */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { TranslationFrontEndService } from '@frontend-shared/services/translation.service';
import { isPreproductionFE } from '@frontend-shared/tools/tools';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { SignOutUser } from '../../../../../../frontend-shared/actions/auth.actions';
import { environment } from '../../../../environments/environment';
import { freeLanceFrontCurrencies, freeLanceFrontLanguages } from '../../../langs';
import {
  navigateToDashboard,
  navigateToProfileImage,
  navigateToUpdateEmail2,
  navigateToUpdatePayoutAddress,
} from '../../../navigation/navigation.action';
import { LayoutService } from '../../../service/layout.service';
import { ILayoutModel, LayoutState } from '../../../state/layout.state';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;
  showSelect = false;
  @Select((state) => state.user.user.nickname) nickname$: Observable<string>;
  @Select((state) => state.userData.userData.photoURL) userPicture$: Observable<string>;
  @Select((state) => state.userData.userData.uid) userId$: Observable<string>;
  @Select((state) => state.user.user.currency) currency$: Observable<string>;
  @Select(LayoutState) layout$: Observable<ILayoutModel>;
  currencies = freeLanceFrontCurrencies;
  languages = freeLanceFrontLanguages;
  isPreproduction = isPreproductionFE(environment);
  public currentLang: string;
  userMenuFix = [{ title: 'Profile' }, { title: 'Update email' }, { title: 'Log out' }];
  userMenu = [];
  tag = 'my-context-menu';
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private store: Store,
    private translationService: TranslationFrontEndService,
  ) {
    this.sidebarService.compact();
  }
  currency = 'USD';
  ngOnInit() {
    this.currency$.subscribe((data) => {
      if (data) {
        this.currency = data;
      }
    });

    this.layout$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: ILayoutModel) =>
          (this.userMenu = !res.restricted
            ? this.userMenuFix.filter((obj) => obj.title === 'Log out')
            : this.userMenuFix),
      );
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === this.tag))
      .subscribe((event) => {
        this.onContecxtItemSelection(event.item.title);
      });
    this.currentLang = this.translationService.getTranslationStored()
      ? this.translationService.getTranslationStored()
      : 'en';
  }
  navigateToProfile() {
    this.store.dispatch(navigateToProfileImage);
  }
  signOut() {
    this.store.dispatch(new SignOutUser(true));
  }
  @Dispatch()
  onContecxtItemSelection(title): void | any {
    switch (title) {
      case 'Log out':
        return new SignOutUser(true);
      case 'Profile':
        return navigateToProfileImage;
      case 'Payout Address':
        return navigateToUpdatePayoutAddress;
      case 'Update email':
        return navigateToUpdateEmail2;
      default:
        break;
    }
    return null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  setCurrency(event) {
    this.store.dispatch(
      new UsersActionsUpdate({
        currency: event,
      }),
    );
  }
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }
  changeLang(event) {
    this.translationService.setTranslation(event);
  }
  navigateHome() {
    this.store.dispatch(navigateToDashboard);
    return false;
  }
}
