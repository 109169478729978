<div class="row">
  <div class="col-md-12">
    <nb-card id="updateemail">
      <nb-card-header>{{headerStr}}</nb-card-header>
      <nb-card-body>
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="login100-form validate-form">
          <div class="form-group">
            <input type="email" formControlName="email" nbInput fullWidth id="exampleInputEmail1" (keyup)="incorrect = false;" placeholder="johndoe@mail.com" status="primary" />
            <span class="caption status-danger ng-star-inserted" *ngIf="(editForm.controls.email.touched) && 
			        editForm.controls.email.errors?.required">
              * required
            </span>
            <span class="caption status-danger ng-star-inserted" *ngIf="(editForm.controls.email.touched) && 
			        editForm.controls.email.errors?.email">
              Check your email address
            </span>
          </div>
          <br>
          <button type="submit" [disabled]="!editForm.valid || disableButton || incorrect" nbButton status="danger" (click)="onSubmit();">{{'BUTTONS.next' | translate}}</button> &nbsp;
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>
