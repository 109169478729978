import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { NGXS_PLUGINS } from '@ngxs/store';
import * as Sentry from '@sentry/browser';
import { tap } from 'rxjs/operators';

import { isLocalHost } from './tools';

export const NGXS_LOGGER_PLUGIN_OPTIONS = new InjectionToken('NGXS_LOGGER_PLUGIN_OPTIONS');

@NgModule()
export class SentryNgxsLoggerPluginModule {
  static forRoot(): ModuleWithProviders<SentryNgxsLoggerPluginModule> {
    return {
      ngModule: SentryNgxsLoggerPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useValue(state: any, action: any, next: any) {
            if (!isLocalHost)
              Sentry.addBreadcrumb({
                type: 'start',
                category: 'ngxs',
                message: action?.type,
              });
            return next(state, action).pipe(
              tap((result) => {
                if (!isLocalHost)
                  Sentry.addBreadcrumb({
                    type: 'done',
                    category: 'ngxs',
                    message: `${result}`,
                  });
              }),
            );
          },
          multi: true,
        },
      ],
    };
  }
}
