import { Component } from '@angular/core';
import { IPayoutRequestsStateModel } from '@frontend-shared/state/payout-requests.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-payoutlistaddr',
  templateUrl: './payoutlistaddr.component.html',
  styleUrls: ['./payoutlistaddr.component.scss'],
})
export class PayoutlistaddrComponent {
  @Select((a) => a.payoutRequest.payoutRequest) payout$: Observable<IPayoutRequestsStateModel>;
}
