<nb-card>
  <nb-card-header>{{ 'PAYOUT_REQUEST.list_title' | translate }}</nb-card-header>
  <nb-card-body>
    <p >{{paymentDetail.from}} requested a payment of amount {{paymentDetail.amount}} regarding {{paymentDetail.reason}}</p>
    <div id="timeline">
        <div class="row timeline-movement">
            <div class="col-sm-6  timeline-item" *ngFor="let items of paymentStatuses">
                <div class="row">
                    <div class="col-sm-11">
                        <div class="timeline-panel credits">
                            <ul class="timeline-panel-ul">
                                <li><span class="importo">{{items.state}}</span></li>
                                <li><p><small class="text-muted"><i class="glyphicon glyphicon-time"></i>{{items.timestamp | date: 'medium'}}</small></p> </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
  </nb-card-body>
</nb-card>
