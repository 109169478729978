import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PayoutRequestsFirestore } from '../firestore/payout-requests.firestore';

@Pipe({ name: 'IsPayout' })
export class IsPayoutPipe implements PipeTransform {
  // @SelectSnapshot((s) => s.userData) userInfo: IUserModel;

  constructor(private payoutFS: PayoutRequestsFirestore) {}

  transform(paymentRequestId: string): Observable<boolean> {
    return this.payoutFS
      .collection$((ref: any) => {
        return ref.where(
          'paymentRequests',
          'array-contains',
          paymentRequestId ? paymentRequestId : '',
        );
      })
      .pipe(map((res: any[]) => (res.length > 0 ? true : false)));
  }
}
