import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PayoutRequestsFirestore } from '@frontend-shared/firestore/payout-requests.firestore';
import { IPaymentRequestsStateModel } from '@frontend-shared/state/payment-requests.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-payout-list',
  styleUrls: ['./payout-list.component.scss'],
  templateUrl: './payout-list.component.html',
})
export class PayoutListComponent implements OnInit {
  @Select((a) => a.paymentRequest) paymentRequest$: Observable<IPaymentRequestsStateModel>;
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;

  page = 1;

  public payoutDetail$: Observable<any>;

  constructor(private payoutFS: PayoutRequestsFirestore, private router: ActivatedRoute) {}

  ngOnInit() {
    this.router.params.subscribe((p) => {
      this.payoutDetail$ = this.payoutFS
        .collection$((ref) => {
          return ref.where('paymentRequests', 'array-contains', p['id']);
        })
        .pipe(map((res) => res));
    });
  }
  checkPayout(id: string): Observable<boolean> {
    return this.payoutFS
      .collection$((ref) => {
        return ref.where('paymentRequests', 'array-contains', id);
      })
      .pipe(map((res) => (res ? true : false)));
  }
}
