import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { SCHEDULED_TRANSFER_PATH } from '../../model-shared/collection-names';
import { IScheduledTransfer } from '../../model-shared/transfer';

@Injectable({
  providedIn: 'root',
})
export class ScheduledTransferFirestore extends NgxsFirestore<IScheduledTransfer> {
  protected path = SCHEDULED_TRANSFER_PATH;
}
