import { Navigate } from '@ngxs/router-plugin';

import {
  COUNTRY_UPDATE_PATH,
  DASHBOARD_PATH,
  EDIT_PROFILE_PATH,
  INBOX_PATH,
  LOGIN_PATH,
  PAGES_PATH,
  PAYOUT_ADDRESS_UPDATE_PATH,
  PAYOUT_PATH,
  PENDINGTRANSFER_PATH,
  PROFILE_IMAGE_PATH,
  UPDATE_EMAIL_PATH,
  VAULTTRANSFER_PATH,
} from './navigation.paths';

export const navigateToLogin = new Navigate([`/${LOGIN_PATH}`]);
export const navigateToProfileImage = new Navigate([`/${PAGES_PATH}/${PROFILE_IMAGE_PATH}`]);
export const navigateToDashboard = new Navigate([`/${PAGES_PATH}/${DASHBOARD_PATH}`]);
export const navigateToEditProfile = new Navigate([`/${PAGES_PATH}/${EDIT_PROFILE_PATH}`]);
export const navigateToUpdateEmail2 = new Navigate([`/${PAGES_PATH}/${UPDATE_EMAIL_PATH}`]);
export const navigateToUpdateCountry = new Navigate([`/${PAGES_PATH}/${COUNTRY_UPDATE_PATH}`]);
export const navigateToInbox = new Navigate([`/${PAGES_PATH}/${INBOX_PATH}`]);
export const navigateToVaultTransfer = new Navigate([`/${PAGES_PATH}/${VAULTTRANSFER_PATH}`]);
export const navigateToPendingTransfer = new Navigate([`/${PAGES_PATH}/${PENDINGTRANSFER_PATH}`]);
export const navigateToPayoutTransfer = new Navigate([`/${PAGES_PATH}/${PAYOUT_PATH}`]);
export const navigateToUpdatePayoutAddress = new Navigate([
  `/${PAGES_PATH}/${PAYOUT_ADDRESS_UPDATE_PATH}`,
]);
