export const PAYMENT_REQUEST_PATH = 'payment_request';
export const PAYMENT_REQUEST_ARCHIVE_PATH = 'payment_request_arch';
export const PAYMENT_REQUEST_STATUS_PATH = 'payment_request_status';
export const PAYMENT_REQUEST_STATUS_ARCHIVE_PATH =
  'payment_request_status_arch';

export const NOTIFICATION_PATH = 'notification';
export const NOTIFICATION_ARCHIVE_PATH = 'notification_arch';

export const CHARGE_PATH = 'charge';
export const BUYER_PATH = 'buyer';
export const FREELANCER_PATH = 'freelancer';
export const PREVIOUS_CARDS_PATH = 'previous_cards';
export const CHARGE_STATUS_PATH = 'charge_status';
export const CHARGE_STATUS_ARCHIVE_PATH = 'charge_status_arch';
export const KYC_STATUS_PATH = 'kyc_status';
export const KYC_PATH = 'kyc';
export const FREELANCER_DETAIL_PATH = 'freelancer_details';
export const SETTLEMENT_PATH = 'settlement';
export const CHARGEBACK_PATH = 'chargeback';
export const EXCHANGE_RATE_PATH = 'exchange_rate';
export const PAYOUT_PATH = 'payout';
export const PAYOUT_ARCHIVE_PATH = 'payout_arch';

export const PAYREQ_EXPIRATION_PATH = 'payment_request_exp';
export const TRANSFER_PATH = 'transfer';
export const TRANSFER_ARCHIVE_PATH = 'transfer_arch';
export const SCHEDULED_TRANSFER_PATH = 'scheduled_transfer';
export const SCHEDULED_TRANSFER_ARCHIVE_PATH = 'scheduled_transfer_arch';

export const TRANSFER_REQUEST_PATH = 'transfer_request';
export const TRANSFER_REQUEST_ARCHIVE_PATH = 'transfer_request_arch';
export const TRANSFER_ORDER_PATH = 'transfer_order';
export const TRANSFER_ORDER_ARCHIVE_PATH = 'transfer_order_arch';
export const INDIE_BALANCE_PATH = 'indie_balance';
export const CIRCLE_BALANCE_PATH = 'circle_balance';
export const STATUS_GLOBAL_PATH = 'status';
export const PKE_PATH = 'circle_key';
