<h1>hello</h1>
<!-- <div class="row">
    <div class="col-md-12">
        <nb-card>
            <nb-card-header>Login</nb-card-header>
            <nb-card-body>
                <form [formGroup]="anonSignIn" class="login100-form validate-form" ngxsForm="editforms.updateForm"
                    (ngSubmit)="onSubmit()">
                    <div class="form-group" *ngIf="messageString">{{ messageString }}</div>
                    <div class="form-group">
                        <label class="label" for="pname">Name</label>
                        <div class="form-group ">
                            <input type="text" nbInput id="pname" formControlName="email" placeholder="Email"
                                name="email" />
                        </div>
                        <span class="caption status-danger ng-star-inserted" *ngIf="(updateForm.controls.email.touched) && 
              updateForm.controls.email.errors?.required">
                            Email is required
                        </span>
                    </div>
                    <div class="form-group">
                        <label class="label" for="pnickname">Nick name</label>
                        <div class="form-group">
                            <input nbInput id="pnickname" type="text" name="nickname" formControlName="nickname"
                                placeholder="Nick name" />
                        </div>
                        <span class="caption status-danger ng-star-inserted" *ngIf="(updateForm.controls.nickname.touched) && 
                updateForm.controls.nickname.errors?.required">
                            Nick name is required
                        </span>
                    </div>
                    <div class="form-group">
                        <button [disabled]="!updateForm.valid" type="submit" nbButton status="danger">Update</button>
                    </div>
                </form>
            </nb-card-body>
        </nb-card>
    </div>
</div> -->