import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ILayoutModel, LayoutState } from '../../../state/layout.state';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>
      <nb-sidebar
        class="menu-sidebar"
        tag="menu-sidebar"
        responsive
        state="compacted"
        *ngIf="(layout$ | async)?.restricted && showSidebar"
      >
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>
      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
      <nb-layout-footer fixed *ngIf="(layout$ | async)?.restricted">
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  showSidebar = false;
  @Select(LayoutState) layout$: Observable<ILayoutModel>;
}
