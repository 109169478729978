<div class="row">
  <div class="col-md-12" id="countryform">
    <nb-card *ngIf="!waitingList">
      <nb-card-header>{{ 'UPDATE_COUNTRY.title' | translate }}</nb-card-header>
      <nb-card-body>
        <form [formGroup]="updateCountry" class="login100-form validate-form" ngxsForm="UpdateCountryForm.updateCountry"
          (ngSubmit)="onSubmit()">
          <p *ngIf="!(user$ | async)?.user?.nationality">{{ 'UPDATE_COUNTRY.welcome_message' | translate }}</p>
          <!-- <p *ngIf="(user$ | async)?.user?.nationality">{{ 'UPDATE_COUNTRY.prohibited_message' | translate }}</p> -->
          <div class="form-group" *ngIf="statusMessage">{{ statusMessage }}</div>
          <div class="form-group">
            <label for="country" class="label">ID Nationality</label>
            <nb-select fullWidth id="counry" formControlName="country" [selected]="(user$ | async)?.user?.nationality">
              <nb-option>{{ 'UPDATE_COUNTRY.select_title' | translate }}</nb-option>
              <nb-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</nb-option>
            </nb-select>
            <span class="caption status-danger ng-star-inserted"
              *ngIf="updateCountry.controls.country.touched && updateCountry.controls.country.errors?.required">
              {{ 'UPDATE_COUNTRY.error_message' | translate }}
            </span>
          </div>
          <div class="form-group">
            <label for="domicile" class="label">Country of Residence</label>
            <nb-select fullWidth id="domicile" formControlName="domicile" [selected]="(user$ | async)?.user?.domicile">
              <nb-option>{{ 'UPDATE_COUNTRY.select_title' | translate }}</nb-option>
              <nb-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</nb-option>
            </nb-select>
            <span class="caption status-danger ng-star-inserted"
              *ngIf="updateCountry.controls.domicile.touched && updateCountry.controls.domicile.errors?.required">
              {{ 'UPDATE_COUNTRY.error_message' | translate }}
            </span>
          </div>
          <button type="submit" [disabled]="!updateCountry.valid" nbButton status="danger">{{ 'UPDATE_COUNTRY.submit' | translate }}</button>
        </form>
      </nb-card-body>
    </nb-card>
    <nb-card *ngIf="waitingList">
      <nb-card-header>{{ 'UPDATE_COUNTRY.title' | translate }}</nb-card-header>
      <nb-card-body> (Nationality: {{(user$ | async).user.nationality }} & Domicile: {{(user$ | async).user.domicile }} ) {{'UPDATE_COUNTRY.wait_list' | translate}}</nb-card-body>
    </nb-card>
  </div>
</div>
