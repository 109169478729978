import { Component } from '@angular/core';
import { TransferOrdersState } from '@frontend-shared/state/transfer-order.state';
import { ITransferRequest } from '@model-shared/transferRequest';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-transfer-request-list',
  templateUrl: './transfer-request-list.component.html',
  styleUrls: ['./transfer-request-list.component.scss', '../dashboard.component.scss'],
})
export class TransferRequestListComponent {
  @Select(TransferOrdersState.getTransferRequestDistinctOrderNoSettlement)
  transferRequests$: Observable<Observable<ITransferRequest[]>>;
  @Select((state) => state.user.user.currency) currency$: Observable<string>;
  page = 1;
}
