import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-emailVerify',
  templateUrl: './emailVerify.component.html',
  styleUrls: ['../../@theme/layouts/one-column/one-column.layout.scss'],
})
export class EmailVerifyComponent implements OnInit {
  constructor(private afAuth: AngularFireAuth, public route: ActivatedRoute) {}
  mode: string;
  oobCode: string;
  message = '';
  emailLang: string;
  codeOk = true;
  async ngOnInit() {
    // this.store.dispatch(new LayoutActions.SetPartial(true));

    this.emailLang = this.route.snapshot.queryParamMap.get('lang');
    this.mode = this.route.snapshot.queryParamMap.get('mode');
    this.oobCode = this.route.snapshot.queryParamMap.get('oobCode');

    if (this.mode === 'verifyAndChangeEmail' && this.oobCode) {
      try {
        await this.afAuth.applyActionCode(this.oobCode);
      } catch {
        this.codeOk = false;
      }
    }
  }
}
