<nb-card accent="info">
    <nb-card-body>
        <form [formGroup]="updateForm" class="login100-form validate-form">

            <div class ="form-group">
                <label for="invite">Enter your invitation code</label><br>
                <br>
                <input fullWidth nbInput id="invite" type="text" name="invite"placeholder="invitation code"  formControlName="code" />
                <label class="caption status-danger ng-star-inserted" *ngIf="(user$ | async)?.sponsorCode == 'INVALID'">
                    Invalid Code
                </label> <br>
                <br>
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="disabledSubmitButton" nbButton status="danger" (click)="onSubmit()">Next</button>
                <button type="submit" [disabled]="disabledSubmitButton" class="margin-left-5" nbButton status="primary" (click)="onSubmit()">I Have No Invitation Code</button>
            </div>
        </form>
    </nb-card-body></nb-card>
