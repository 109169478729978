<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-header>Email link checking ...</nb-card-header>
      <nb-card-body *ngIf="codeOk">
        Your email address has been verified successfully. You can close this window and continue  your <a href="/">indie onboarding</a>
      </nb-card-body>

      <nb-card-body *ngIf="!codeOk">
        This verification link is invalid. This can happen if it is malformed, expired, or has already been used
      </nb-card-body>

    </nb-card></div></div>
