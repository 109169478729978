<nb-card>
  <nb-card-header>{{ 'PAYOUT_REQUEST.list_title' | translate }}  
    <button nbButton (click)="payoutDetail$ = null" >Back</button></nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <tr>
            <th>{{ 'PAYOUT_REQUEST.transactionId' | translate }}</th>
            <th>{{ 'PAYOUT_REQUEST.amount' | translate }}</th>
            <th>{{ 'PAYOUT_REQUEST.currency' | translate }}</th>
            <th>{{ 'PAYOUT_REQUEST.date' | translate }}</th>
          </tr>
          <tr *ngFor="let payoutRequest of (payoutDetail$ | async)">
            <td>{{payoutRequest?.transfer?.id}}</td>
            <td>

              {{payoutRequest?.amount | currency:'USD'}}
              <small *ngIf="(currency$|async) !== 'USD'">
                ( {{payoutRequest?.amount | currencyConvert | async | currency:(currency$|async)}} )
              </small>
            </td>
            <td>{{payoutRequest?.currency}}</td>
            <td>{{payoutRequest?.transfer?.createDate | date}}</td>
          </tr>
          <tr *ngIf="(payoutDetail$ | async)?.length == 0">
            <td class="text-center" colspan="4">{{ 'PAYOUT_REQUEST.no_record_found' | translate }}</td>
          </tr>
        </table>
      </div>
    </div>
  </nb-card-body>
</nb-card>
