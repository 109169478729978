import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'paymentStatusIcon' })
export class PaymentStatusIconPipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'PENDING_SEND' || value === 'RETRY' || value === 'PROCESSING' || value === 'SENT')
      return 'fa-hourglass-end';
    else if (value === 'INVALID' || value === 'SEND_FAILED' || value === 'CHARGED_BACK')
      return 'fa-exclamation-triangle';
    else if (value === 'EXPIRED') return 'fa-exclamation';
    else if (value === 'CONFIRMED' || value === 'PAID') return 'fa-check';
    else return '';
  }
}
