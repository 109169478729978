import { Injectable } from '@angular/core';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { ShepherdService } from 'angular-shepherd';

@Injectable({
  providedIn: 'root',
})
export class TourService extends ShepherdService {
  constructor(private scrollToService: ScrollToService) {
    super();
  }

  callback;

  init(p: () => void) {
    this.callback = p;
    this.defaultStepOptions = {
      scrollTo: false,
      cancelIcon: {
        enabled: false,
      },
    };
    this.modal = true;
    this.confirmCancel = false;
    this.addSteps([
      {
        id: 'intro',
        /*   attachTo: {
          element: '#Inbox',
          on: 'bottom',
        },*/
        beforeShowPromise: () =>
          new Promise((resolve) => {
            setTimeout(() => {
              window.scrollTo(0, 0);
              resolve();
            }, 1000);
          }),
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit',
            type: 'cancel',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
        cancelIcon: {
          enabled: false,
        },
        title: 'Welcome',
        text: [
          '<p>Let’s take a short tour and get you started.</p>' +
            '<p>By now, you can request your first payment.</p>' +
            '<p>Let us show you how...</p>',
        ],
      },

      {
        id: 'pr',
        attachTo: {
          element: '#paymentRequest',
        },
        beforeShowPromise: () =>
          new Promise((resolve) => {
            setTimeout(() => {
              this.scrollToService.scrollTo({ offset: -80, target: 'paymentRequest' });
              resolve();
            }, 1000);
          }),
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit',
            type: 'cancel',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
        cancelIcon: {
          enabled: false,
        },
        title: 'Request Payments (1/6)',
        text: [
          '<p>To request payments, enter the amount, your customer phone number and a short description.</p>',
        ],
      },

      {
        id: 'inbox',
        attachTo: {
          element: '#paymentrequestform',
        },
        beforeShowPromise: () =>
          new Promise((resolve) => {
            setTimeout(() => {
              this.scrollToService.scrollTo({ offset: -80, target: 'paymentrequestform' });
              resolve();
            }, 1000);
          }),
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit',
            type: 'cancel',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
        cancelIcon: {
          enabled: false,
        },
        title: 'Payment List (2/6)',
        text: [
          '<p>All your requested payments at a glance.</p>' +
            '<p>When successful, your payment will show a ✓</p>' +
            '<p>Payment requests will expire after 2 hours.</p>',
        ],
      },

      {
        id: 'StakeCash',
        attachTo: {
          element: '#inbox',
        },
        beforeShowPromise: () =>
          new Promise((resolve) => {
            setTimeout(() => {
              this.scrollToService.scrollTo({ offset: -80, target: 'inbox' });
              resolve();
            }, 1000);
          }),
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit',
            type: 'cancel',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
        cancelIcon: {
          enabled: false,
        },
        title: 'Your Inbox (3/6)',
        text: [
          '<p>Payments are deposited here and can be sent to the vault to earn up to 10% APR.</p>' +
            '<p>The more you transfer to our Vault, the more you can charge per week!</p>',
        ],
      },

      {
        id: 'vault',
        attachTo: {
          element: '#vault1',
        },
        beforeShowPromise: () =>
          new Promise((resolve) => {
            setTimeout(() => {
              this.scrollToService.scrollTo({ offset: -80, target: 'vault1' });
              resolve();
            }, 1000);
          }),
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit',
            type: 'cancel',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
        cancelIcon: {
          enabled: false,
        },
        title: 'Our Vault (4/6)',
        text: [
          '<p>In our vault you make up to 10% interest, with automatic transfer back to your inbox after 90 days.</p>' +
            '<p>Our Vault also includes your guarantee funds (5% of each payment) and your Referral Bonus.</p>',
        ],
      },

      {
        id: 'available',
        attachTo: {
          element: '#available',
        },
        beforeShowPromise: () =>
          new Promise((resolve) => {
            setTimeout(() => {
              this.scrollToService.scrollTo({ offset: -80, target: 'available' });
              resolve();
            }, 1000);
          }),
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit',
            type: 'cancel',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
        cancelIcon: {
          enabled: false,
        },
        title: 'Total Earnings (5/6)',
        text: [
          '<p>All your earnings are displayed here with their planned availabilities.</p>' +
            '<p>Cash out to your blockchain wallet, it will be processed in 5 days.</p>',
        ],
      },

      {
        id: 'cashedout',
        attachTo: {
          element: '#cashedout',
        },
        beforeShowPromise: () =>
          new Promise((resolve) => {
            setTimeout(() => {
              this.scrollToService.scrollTo({ offset: -80, target: 'cashedout' });
              resolve();
            }, 1000);
          }),
        buttons: [
          {
            classes: 'shepherd-button-secondary',
            text: 'Exit',
            type: 'cancel',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Next',
            type: 'next',
          },
        ],
        cancelIcon: {
          enabled: false,
        },
        title: 'Blockchain Wallet (6/6)',
        text: [
          '<p>All transfers to your blockchain wallet, pending and carried out, are here for you to check.</p>',
        ],
      },

      {
        id: 'finished',
        attachTo: {
          element: '#paymentRequest',
        },
        beforeShowPromise: () =>
          new Promise((resolve) => {
            setTimeout(() => {
              this.scrollToService.scrollTo({ offset: -80, target: 'paymentRequest' });
              resolve();
            }, 1000);
          }),
        buttons: [
          {
            classes: 'shepherd-button-primary',
            text: 'Back',
            type: 'back',
          },
          {
            classes: 'shepherd-button-primary',
            text: 'Finish',
            type: 'next',
          },
        ],
        cancelIcon: {
          enabled: false,
        },
        title: 'You’re ready to start!',
        text: [
          '<p>You are now ready to receive payments !</p>' +
            "<p>why don't you try the customer side of indie and send a request to your phone ?</p>" +
            '<p>You can also invite your friends and you will both receive 15 USDC (conditions apply)</p>',
        ],
      },
    ]);
  }
  onTourFinish(completeOrCancel: string) {
    //if (completeOrCancel === 'complete') {
    this.callback();
    //}
    super.onTourFinish(completeOrCancel);
  }
}
