import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { LayoutActionsSet, LayoutActionsSetPartial } from '../actions/layout.actions';

export interface ILayoutModel {
  restricted: boolean;
  partialRestrict: boolean;
}

@State<ILayoutModel>({
  name: 'layout',
  defaults: {
    restricted: false,
    partialRestrict: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class LayoutState {
  @Action(LayoutActionsSet)
  setLayout(ctx: StateContext<ILayoutModel>, { payload }: LayoutActionsSet) {
    ctx.patchState({ restricted: payload, partialRestrict: false });
  }
  @Action(LayoutActionsSetPartial)
  setLayoutPartial(ctx: StateContext<ILayoutModel>, { payload }: LayoutActionsSet) {
    ctx.patchState({ partialRestrict: payload });
  }
}
