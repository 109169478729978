import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TransferOrderActionsSubmit } from '@frontend-shared/actions/transferOrders.actions';
import { AuthState, IUserModel } from '@frontend-shared/state/auth.state';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { IVaultStateModel } from '@frontend-shared/state/vault.state';
import { minMaxValidator } from '@frontend-shared/validators/minMaxValidator';
import {
  assertValidCurrency,
  assertValidFreelancerID,
  assertValidPaymentRequestID,
  USD_CURRENCY,
} from '@model-shared/typedef';
import { Select, Store } from '@ngxs/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { navigateToUpdatePayoutAddress } from '../../../../navigation/navigation.action';
@Component({
  selector: 'indie-inbox-widget',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {
  disabledButton = false;
  buttonVal = '';
  flipped = false;
  transferDateStake = dayjs().add(90, 'd').toDate();
  transferOrder = new FormGroup({
    amount: new FormControl('', [Validators.required]),
  });
  transferDateCashOut = dayjs().add(5, 'd').toDate();
  myBtn = false;
  @SelectSnapshot((s) => s.userData) userInfo: IUserModel;
  @Select((s) => s.vault) vault$: Observable<IVaultStateModel>;
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;

  constructor(private store: Store) {
    this.vault$.pipe(filter((a) => a.vault !== null)).subscribe((data) => {
      this.transferOrder.controls['amount'].setValidators([
        minMaxValidator(data.vault.minimumToTransferOut, data.vault.vault_1),
      ]);
    });
  }
  ngOnInit() {
    this.buttonVal = '90 DAYS DEPOSIT';
  }
  submitVal(val: boolean, type: string, btnString: string) {
    this.flipped = !this.flipped;
    if (val) {
      this.disabledButton = true;
      this.buttonVal = 'PROCESSING';
      const { uid } = this.store.selectSnapshot(AuthState.getAuthUser);
      assertValidFreelancerID(uid);
      const id = '';
      assertValidPaymentRequestID(id);
      const currency = USD_CURRENCY;
      assertValidCurrency(currency);

      this.store.dispatch(
        new TransferOrderActionsSubmit({
          amount: this.transferOrder.value.amount,
          currency,
          freelanceID: uid,
          id,
          timestamp: 0,
          transferRequestID: [],
          type,
        }),
      );
      setTimeout(() => {
        this.transferOrder.setValue({
          amount: '',
        });
        this.buttonVal = btnString;
        this.disabledButton = false;
      }, 5000);
    } else {
      this.transferOrder.setValue({
        amount: '',
      });
      this.disabledButton = false;
      this.buttonVal = btnString;
    }
  }
  navigateToPayout() {
    this.store.dispatch(navigateToUpdatePayoutAddress);
  }
  // cashOutModal(myData: boolean) {
  //   this.flippedCashOut = !this.flippedCashOut;
  //   if (myData === true) {
  //     this.buttonVal = 'PROCESSING';
  //     this.disabledButton = true;
  //     const { uid } = this.store.selectSnapshot(AuthState.getAuthUser);
  //     this.store.dispatch(
  //       new TransferOrderActions.Submit({
  //         amount: this.transferOrder.value.amount,
  //         currency: 'USD',
  //         freelanceID: uid,
  //         id: '',
  //         timestamp: 0,
  //         transferRequestID: [],
  //         type: 'TRANSFER_FROM_L1_TO_OUT',
  //       }),
  //     );
  //     setTimeout(() => {
  //       this.transferOrder.setValue({
  //         amount: '',
  //       });
  //       this.buttonVal = 'CASH OUT';
  //       this.disabledButton = false;
  //     }, 5000);
  //   } else {
  //     this.transferOrder.setValue({
  //       amount: '',
  //     });
  //     this.disabledButton = false;
  //     this.buttonVal = 'CASH OUT';
  //   }
  // }
}
