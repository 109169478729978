<nb-card id="transferorderform">
  <nb-card-header>
    <h5 class="float-left text-left">
      {{ 'TRANSFER_ORDER.form_title' | translate }}
    </h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="transferOrder" class="login100-form validate-form" ngxsForm="transferOrderForm.details">
      <div class="form-group">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <label class="label" for="transferAmount">{{ 'TRANSFER_ORDER.amount' | translate }}</label>
            <div class="form-group">
              <input fullWidth nbInput id="transferAmount" type="text" name="transferAmount" formControlName="amount" id="amountTransferCashback"
                appDigitOnly placeholder="{{ 'TRANSFER_ORDER.amount' | translate }}" />
            </div>
            <span class="caption status-danger ng-star-inserted"
              *ngIf="(transferOrder.controls.amount.touched) && transferOrder.controls.amount.errors?.required" id="requiredError">
              {{ 'PAYMENT_REQUEST.please_enter_amount' | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <span class="caption status-danger ng-star-inserted" *ngIf="(transferOrder.controls.amount.touched | payoutaddress)">
              {{ 'TRANSFER_ORDER.payoutAddrMessage' | translate }}.<a class="link" (click)="navigateToPayout()">{{ 'TRANSFER_ORDER.movetoAddr' | translate }}</a>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <button type="submit" [disabled]="(!transferOrder.valid) || (checkPayout | payoutaddress) || disabledSubmitButton" nbButton
              status="danger" (click)="submit('EMULATE_CHARGEBACK');" id="submitCashback">{{ 'TRANSFER_ORDER.chargeBack' | translate }}</button>
          </div>
        </div>

      </div>
    </form>
  </nb-card-body>
</nb-card>