import {
  ChargeIDType,
  CurrencyType,
  FreelanceIDType,
  TransferIDType,
  TransferRequestIDType,
} from './typedef';

export enum AccountEnum {
  // belongs to circle
  CIRCLE,
  CIRCLE_TX_FEE,
  CIRCLE_TRANSFER_FEE,
  CIRCLE_PENDING,
  CIRCLE_CHARGEBACK_FEE,
  CIRCLE_CHARGEBACK,
  // belongs to us
  TX_FEE_PENDING,
  TX_FEE,
  CHARGEBACK,
  SPONSORSHIP,
  VAULT_L3_INTEREST_PAID,
  CASH_OUT_FEE,
  // belongs to user
  VAULT_MAIN,
  UNUSED2,
  UNUSED1,
  VAULT_L1,
  VAULT_L3,
  AWAITING_PAYOUT,
  PENDING_PAYOUT,
  PAID_OUT,
  CHARGEBACK_FEE,
}

export enum TransferDescriptionEnum {
  END_OF_TERM_VAULT3_TRANSFER_BACK,
  READY_TO_TRANSFER_OUT,
  CC_TX_PENDING,
  CC_TX,
  CC_TX_FEE,
  DIFFERENTIAL_COMMISSION,
  MAIN_DEPOSIT,
  GUARANTEE_DEPOSIT,
  SPONSORSHIP_SPONSOR,
  SPONSORSHIP_SPONSEE,
  REFERRAL,
  SPONSORSHIP_CANCELLED,
  TRANSFER_OUT_ALL,
  TRANSFER_OUT_PARTIAL,
  CHARGEBACK_EMPTYING,
  CHARGEBACK_TRANSFER_BACK,
  CHARGEBACK_FEE,
  INTEREST,
  SENT_ON_BLOCKCHAIN,
  CASHOUT_FEE,
}

export enum CurrencyEnum {
  USD,
}

export interface ITransfer {
  id: TransferIDType;
  timestamp: number;
  effectiveDateUTC: string;
  from: string; // from account
  to: string; // account
  amount: number;
  freelanceID: FreelanceIDType;
  chargeID: ChargeIDType;
  currency: CurrencyType;
  description: string;
  transferRequestID: TransferRequestIDType;
}

export interface IScheduledTransfer extends ITransfer {
  transferID: TransferIDType;
}
