
  <div class="payment-list-container">

    <nb-card id="paymentrequestform">
      <nb-card-header>
        <div class="row">
          <div class="col-4 text-left">
            <h5 class="float-left text-left">
              {{ 'PAYMENT_REQUEST.list_title' | translate }} <a href="https://indie.money/faq/payment/" target="_blank">
              <i class='fa fa-question-circle' aria-hidden="true"
              ></i></a>
            </h5>
          </div>
          <div class="col-8 text-right">
            <h5 *ngIf="(currency$|async) === 'USD'" class="align-middle float-right price">
              {{(vault$ | async)?.vault?.vault_3_1day | currency:'USD'}} last
              24h
              <!-- <i [nbPopover]="popoverRef" nbPopoverTrigger="hover" nbPopoverPlacement="right"
                       class="fa fa-info-circle" aria-hidden="true"></i> -->
            </h5>
            <h5 *ngIf="(currency$|async) !== 'USD'" class="align-middle float-right price" id="paymentListCurrency">
              ({{(vault$ | async)?.vault?.vault_3_1day | currencyConvert | async | currency:(currency$|async)}})
              {{(vault$ | async)?.vault?.vault_3_1day | currency:'USD'}} last 24h
            </h5>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-md-12">
            <table class="table">
              <tr>
                <!-- <th>Date/Time</th>
                  <th>{{ 'PAYMENT_REQUEST.phone' | translate }}</th>
                  <th>{{ 'PAYMENT_REQUEST.amount' | translate }}</th>
                  <th>{{ 'PAYMENT_REQUEST.reason' | translate }}</th>
                  <th class="text-center">{{ 'PAYMENT_REQUEST.status' | translate }}</th> -->
                <!--  <th>{{ 'PAYMENT_REQUEST.action' | translate }}</th> -->
              </tr>
              <tr *ngFor="let paymentRequest of
                  (paymentRequest$ | async)?.paymentRequest?.data
                  | paginate: { itemsPerPage: totalNumberOfRecordsInTable, currentPage: page}; let i = index">
                <td  class="new-request-time-column" id="date_{{i}}">{{paymentRequest.timestamp |  dateHumanize}}</td>
  
                <td id="phone_{{i}}"><a href="tel:{{paymentRequest.phone}}">{{paymentRequest.phone}}</a></td>
                <td id="amount_{{i}}">{{paymentRequest.amountDisplay | currency:paymentRequest.currencyDisplay}}
  
                <td id="reason_{{i}}">{{paymentRequest.reason}}</td>
                <td class ="text-center" *ngIf="((paymentRequest.lastStatusID | PaymentRequestStatus ) | async) == 'EXPIRED'" (click)="updatePaymentRequest(paymentRequest);">
                  <button nbButton style="padding: 0.3rem 0.6rem;" nbTooltip="{{(paymentRequest.lastStatusID | PaymentRequestStatus ) | async}}" nbTooltipPlacement="bottom"><i class='fa fa-exclamation' aria-hidden="true" style="font-size: 13px;" id="status_{{i}}"></i></button>
                </td>
                <td class="text-center" *ngIf="((paymentRequest.lastStatusID | PaymentRequestStatus ) | async) != 'EXPIRED'">
                  <i class='fa'
                    nbTooltip="{{(paymentRequest.lastStatusID | PaymentRequestStatus ) | async}}"
                    nbTooltipPlacement="bottom"
                    [ngClass]="((paymentRequest.lastStatusID | PaymentRequestStatus ) | async) | paymentStatusIcon"
                    aria-hidden="true" style="font-size: 13px;">
                  </i>
                </td>
              </tr>
              <tr *ngIf="(paymentRequest$ | async)?.paymentRequest?.data?.length == 0">
                <td class="text-center" colspan="5">{{ 'PAYMENT_REQUEST.no_record_found' | translate }}</td>
              </tr>
            </table>
            <br>
  
          </div>
        </div>
      </nb-card-body>
      <div class="pagination">
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
      </div>
    </nb-card>
  
    <ng-template #popoverRef>
      <div class="row p-1">
        <div class="col-8 text-left">
          <span class="">Payments (excluding fees) :</span>
        </div>
        <div class="col-4 text-right">
          <span></span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-6 text-left">
          <span class="">- Last 24h</span>
        </div>
        <div class="col-6 text-right">
          <span *ngIf="(currency$|async) === 'USD'"
            class="col-6 text-right">{{(vault$ | async)?.vault?.vault_3_1day | currency:'USD'}}</span>
          <span *ngIf="(currency$|async) !== 'USD'" class="col-6 text-right">
            ({{(vault$ | async)?.vault?.vault_3_1day | currencyConvert | async | currency:(currency$|async)}})
            {{(vault$ | async)?.vault?.vault_3_1day | currency:'USD'}}
          </span>
          &nbsp;
          <i class="fa fa-arrow-down font-weight-bold"></i>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-5 text-left">
          <span class="">- This month</span>
        </div>
        <div class="col-7 text-right">
          <span *ngIf="(currency$|async) === 'USD'"
            class="col-7 text-right">{{(vault$ | async)?.vault?.vault_3_30days | currency:'USD'}}</span>
          <span *ngIf="(currency$|async) !== 'USD'" class="col-7 text-right">
            ({{(vault$ | async)?.vault?.vault_3_30days | currencyConvert | async | currency:(currency$|async)}}
            )
            {{(vault$ | async)?.vault?.vault_3_30days | currency:'USD'}}
          </span>
          &nbsp;
          <i class="fa fa-arrow-down font-weight-bold"></i>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-6 text-left">
          <span class="">- Previous months</span>
        </div>
        <div class="col-6 text-right">
          <span *ngIf="(currency$|async) === 'USD'"
            class="col-6 text-right">{{(vault$ | async)?.vault?.vault_3_moreThan30days | currency:'USD'}}</span>
          <span *ngIf="(currency$|async) !== 'USD'" class="col-6 text-right">
            ({{(vault$ | async)?.vault?.vault_3_moreThan30days | currencyConvert | async | currency:(currency$|async)}}
            )
            {{(vault$ | async)?.vault?.vault_3_moreThan30days | currency:'USD'}}
          </span>
          &nbsp;
          <i class="fa fa-arrow-down font-weight-bold"></i>
        </div>
      </div>
    </ng-template>
  </div>
