import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { PAYMENT_REQUEST_PATH } from '../../model-shared/collection-names';
import { IPaymentRequest } from '../../model-shared/payment-request';

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestsFirestore extends NgxsFirestore<IPaymentRequest> {
  protected path = PAYMENT_REQUEST_PATH;
}
