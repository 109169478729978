import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'MENU.DASHBOARD',
    icon: 'lock-outline',
    link: '/pages/dashboard',
    pathMatch: 'full',
  },
  {
    title: 'MENU.INBOX',
    icon: 'inbox-outline',
    link: '/pages/inbox',
    pathMatch: 'full',
  },
  {
    title: 'MENU.VAULT',
    icon: 'lock-outline',
    link: '/pages/vault-transfer',
    pathMatch: 'full',
  },
  {
    title: 'MENU.PENDINGTRANSFER',
    icon: 'clock-outline',
    link: '/pages/pending-transfer',
    pathMatch: 'full',
  },
  {
    title: 'MENU.ONBLOCKCHAIN',
    icon: 'globe-outline',
    link: '/pages/payout-transfer',
    pathMatch: 'full',
  },
  {
    title: 'MENU.PROFILE',
    icon: 'edit-2-outline',
    link: '/pages/profile-image',
    pathMatch: 'full',
  },
  {
    title: 'MENU.PAYOUTADDR',
    icon: 'swap-outline',
    link: '/pages/update-payout-address',
    pathMatch: 'full',
  },
  {
    title: 'MENU.DETAILS',
    icon: 'person-outline',
    link: '/pages/account',
    pathMatch: 'full',
  },
  {
    title: 'MENU.EMAIL',
    icon: 'email-outline',
    pathMatch: 'full',
    link: '/pages/update-email',
  },
  {
    title: 'MENU.CODES',
    icon: 'code-outline',
    pathMatch: 'full',
    link: '/pages/invite-codes',
  },
  {
    title: 'MENU.LOGOUT',
    icon: 'log-out-outline',
    link: '/pages/logout',
  },
];
