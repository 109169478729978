import { AbstractControl, ValidatorFn } from '@angular/forms';

export const nickNameValidator =
  (firstName: string, lastName: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const proposed = control.value.toLowerCase();
    const proposedLengthOk = proposed.length > (firstName.length + lastName.length) / 3;

    const proposedArray: string[] = proposed.match(/("[^"]+"|[^\s"]+)/g);

    if (!proposedArray) return { wrongInput: true };

    const proposedInFirstName = proposedArray.some((p) => firstName.toLowerCase().includes(p));
    const proposedInLastName = proposedArray.some((p) => lastName.toLowerCase().includes(p));

    return proposedLengthOk && (proposedInFirstName || proposedInLastName)
      ? null
      : { wrongInput: true };
  };
