<nb-card accent="info">
    <nb-card-header>{{'FREELANCER.header_details' | translate}}</nb-card-header>
    <nb-card-body>
      <div class ="row">
        <div class="col-sm-6 timeline-item">
          <div class="heading">Nick Name</div>
          <div>{{(userData$ | async)?.user?.nickname}}</div>
        </div>
        <div class="col-sm-6 timeline-item">
          <div class="heading">{{'FREELANCER.country_domicile' | translate}}</div>
          <div>{{(userData$ | async)?.user?.nationality}}/{{(userData$ | async)?.user?.domicile}}</div>
        </div>
      </div>
    </nb-card-body>
</nb-card>
