import { Injectable, Injector } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireAuth } from '@angular/fire/auth';
import { Selector, State, StateContext } from '@ngxs/store';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';

import { IFireOperation } from '../interface/fire.interface';

export class IFireStoreModel {
  fileStore: IFireOperation;
}
@State<IFireStoreModel>({
  name: 'firebaseFunctions',
  defaults: {
    fileStore: null,
  },
})
@Injectable({ providedIn: 'root' })
export class FireState {
  static analytics: AngularFireAnalytics;
  static auth: AngularFireAuth;
  // static storage: AngularFireStorage;
  constructor(injector: Injector) {
    FireState.analytics = injector.get<AngularFireAnalytics>(AngularFireAnalytics);
    FireState.auth = injector.get<AngularFireAuth>(AngularFireAuth);
    // FireState.storage = injector.get<AngularFireStorage>(AngularFireStorage);
  }
  @Selector()
  static getFireStoreStorage(state: IFireStoreModel) {
    return state.fileStore;
  }
  @Receiver()
  static logEvent(ctx: StateContext<IFireStoreModel>, { payload }: EmitterAction<string>) {
    void FireState.analytics.logEvent(payload);
  }
  @Receiver()
  static setUserId(ctx: StateContext<IFireStoreModel>, { payload }: EmitterAction<string>) {
    void FireState.analytics.setUserId(payload);
  }
  @Receiver()
  static resetState(ctx: StateContext<IFireStoreModel>) {
    ctx.setState({
      ...ctx.getState(),
      fileStore: null,
    });
  }
  /* @Receiver()
   eslint-disable-next-line @typescript-eslint/no-unused-vars
  static saveFile(ctx: StateContext<IFireStoreModel>, { payload }: EmitterAction<IFileUpload>) {
    /*   const data = {} as IFireOperation;
       let uploadOK = false;
       data.actionString = payload.action;
       FireState.storage
         .upload(payload.filePath, payload.file)
         .then((rst) => {
           rst.ref
             .getDownloadURL()
             .then((url) => {
               void FireState.analytics.logEvent(`Photo of Url: ${url} uploaded Sucessfully`);
               uploadOK = true;
               data.value = url;
               data.operationDidPerform = uploadOK;
               ctx.setState({
                 ...ctx.getState(),
                 fileStore: data,
               });
             })
             .catch((error) => {
               console.error(error);
               data.operationDidPerform = uploadOK;
               ctx.setState({
                 ...ctx.getState(),
                 fileStore: data,
               });
             });
         })
         .catch(console.error);

  }  */
}
