import { Component } from '@angular/core';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Component({
  selector: 'ngx-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss'],
})
export class AcceptTermsComponent {
  disabledSubmitButton = false;
  checked1 = false;
  checked2 = false;
  checked3 = false;

  constructor(private store: Store) {}
  @Dispatch()
  onSubmit() {
    this.disabledSubmitButton = true;
    setTimeout(() => {
      this.disabledSubmitButton = false;
    }, 2000);
    return [new UsersActionsUpdate({ termsAccepted: true })];
  }
}
