<div class="row">
  <div class="col-md-12" id="countryform">
    <nb-card>
      <nb-card-header>{{ 'PAYOUT_ADDRESS.title' | translate }}</nb-card-header>
      <nb-card-body>
        <form [formGroup]="updateAddress" class="login100-form validate-form" ngxsForm="UpdatePayoutAddressForm.updatePayoutAddress"
          (ngSubmit)="onSubmit()">          
          <div class="form-group">
            <label class="label" for="payoutAddress">{{ 'PAYOUT_ADDRESS.address' | translate }}</label>
            <div class="form-group">
              <input fullWidth nbInput id="payoutAddress" type="text" name="payoutAddress" formControlName="payoutAddress" (keyup)="incorrect = false"
                placeholder="{{ 'PAYOUT_ADDRESS.address' | translate }}" />
            </div>
            <span class="caption status-danger ng-star-inserted" *ngIf="(updateAddress.controls.payoutAddress.touched) &&
            updateAddress.controls.payoutAddress.errors?.required">
            {{ 'PAYOUT_ADDRESS.address_is_required' | translate }}
            </span>            
            <span class="caption status-danger ng-star-inserted" 
            *ngIf="(updateAddress.controls.payoutAddress.touched) && updateAddress.controls.payoutAddress.invalid">
            Address should be correct
            </span>
          </div>
          <button type="submit" [disabled]="!updateAddress.valid || incorrect" nbButton status="danger">{{'BUTTONS.update' | translate}}</button>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>


