import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrontEndSharedModule } from '@frontend-shared/frontshared.module';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbPopoverModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbTooltipModule,
  NbUserModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { ThemeModule } from '../../@theme/theme.module';
import { CashOutComponent } from '../cash-out/cash-out.component';
import { IndieModule } from './components/indie.module';
import { DashboardComponent } from './dashboard.component';
import { FreelancerDetailsComponent } from './freelancer-details/freelancer-details.component';
import { InviteListComponent } from './invite-list/invite-list.component';
import { PaymentRequestCreateFormComponent } from './payment-form/payment-form.component';
import { PaymentRequestListComponent } from './payment-list/payment-list.component';
import { TransferListComponent } from './transfer-list/transfer-list.component';
import { TransferOrderComponent } from './transfer-order/transfer-order.component';
import { TransferRequestListComponent } from './transfer-request-list/transfer-request-list.component';
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ThemeModule,
    NbCardModule,
    NbUserModule,
    NbInputModule,
    NbButtonModule,
    NbTabsetModule,
    NbActionsModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbSpinnerModule,
    NbIconModule,
    NbButtonModule,
    NgxPaginationModule,
    NbCheckboxModule,
    NgxsFormPluginModule,
    CommonModule,
    TranslateModule,
    FrontEndSharedModule,
    IndieModule,
    NbPopoverModule,
    NbTooltipModule,
    ShareButtonsModule,
    ShareIconsModule,
  ],
  declarations: [
    DashboardComponent,
    PaymentRequestListComponent,
    PaymentRequestCreateFormComponent,
    TransferOrderComponent,
    TransferListComponent,
    TransferRequestListComponent,
    FreelancerDetailsComponent,
    InviteListComponent,
    CashOutComponent,
  ],
  exports: [InviteListComponent],
})
export class DashboardModule {}
