<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-header>Identity Check</nb-card-header>
      <nb-card-body *ngIf="!(ongoincCheck$ | async)">
        <div *ngIf="(noconcerns$ | async)">
          <p>Since you will receive credit card payments, we need to check your identity and place of residence.</p>
          <p>Your identity information is kept confidential and never leaves our secure storage.</p>

        </div>

        <div *ngIf="!(noconcerns$ | async)">
          <p>Your verification failed.</p>
          <p>We sent an email with details.</p>
          <br />
        </div>


<br>
        <p>
          You will need to provide your passport or identity card and a scan or a pdf of your proof of residence ( utility
          bill, bank statement, public authority letter ).</p>
        <!--  <nb-checkbox [(ngModel)]="switchToPhone"
                       [ngModelOptions]="{standalone: true}">I don't have a good camera on my computer, let's switch to my phone</nb-checkbox> -->
        <br />

        <p *ngIf="!isValidBrowser">Your browser is probably <strong>NOT COMPATIBLE</strong> with this verification step, please
          use only Google Chrome or Safari.</p>
        <br />
        <div id="getid-component"></div>
        <br />
      </nb-card-body>
      <nb-card-body *ngIf="(ongoincCheck$ | async)">
        <br />
        <p>Please wait while we verify your documents.</p>
        <p>Hold on, it takes just a few minutes.</p>
        <br />
      </nb-card-body>
    </nb-card>

  </div>
</div>

<a class="typeform-share button" href="https://c27gfmcxpks.typeform.com/to/PpFp0Ecw"
   data-mode="popover"
   style="width:54px;height:54px;position:fixed;box-shadow:0px 2px 12px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08);right:26px;bottom:26px;border-radius:50%;display:flex;align-items:center;justify-content:center;cursor:pointer;background:#3A7685;overflow:hidden;line-height:0;"
   data-hide-headers="true" data-hide-footer="true" target="_blank"> <span class="icon">             <i
  class="fa fa-exclamation-circle fa-3x" aria-hidden="true" style="color:#ffffff"></i>
</span> </a>
<script> (function() {
  var qs, js, q, s, d = document, gi = d.getElementById, ce = d.createElement, gt = d.getElementsByTagName,
    id = "typef_orm_share", b = "https://embed.typeform.com/";
  if (!gi.call(d, id)) {
    js = ce.call(d, "script");
    js.id = id;
    js.src = b + "embed.js";
    q = gt.call(d, "script")[0];
    q.parentNode.insertBefore(js, q);
  }
})(); </script>
