export enum PlanEnum {
  BASIC,
  TEST,
}

export const pricingPlans: IPlan[] = [
  {
    id: 'BASIC',
    creditCardTxVariable: 0.029,
    creditCardTxFixed: 0.3,
    chargeBackFees: 20,

    howMuchToKeepInVaultRatio: [0.95, 0.95, 0.95, 0.95], //95% on vault 1 => 5% on vault 3
    howMuchForSponsor: 15,
    howMuchForReferral: 3,
    waitForTransferOut: 5,
    vaultL3Bonus: [1, 1.5, 2, 2.5],
    vaultL3StakeDuration: 90,
    interval: 'day',
    howMuchInactivity: 30,
    minimumToTransferOut: 20,
    minimumPaymentRequest: 10,
    maximum7DaysPaymentRequest: [300, 600, 1200, 1200],
    maximumPaymentRequestVaultMinimum: [0, 500, 1000, 5000],
    cashOutFee: 2,
  },
  {
    id: 'TEST',
    creditCardTxVariable: 0.029,
    creditCardTxFixed: 0.3,
    chargeBackFees: 30,

    howMuchToKeepInVaultRatio: [0.95, 0.95, 0.95, 0.95], //95% on vault 1 => 5% on vault 3
    howMuchForSponsor: 15,
    howMuchForReferral: 3,
    waitForTransferOut: 5,
    vaultL3Bonus: [1, 1.5, 2, 2.5],
    vaultL3StakeDuration: 90,
    interval: 'second',
    howMuchInactivity: 3000,
    minimumToTransferOut: 20,
    minimumPaymentRequest: 10,
    maximum7DaysPaymentRequest: [10000, 15000, 100000, 100000],
    maximumPaymentRequestVaultMinimum: [0, 500, 1000, 5000],
    cashOutFee: 2,
  },
];

export interface IPlan {
  waitForTransferOut: number;

  vaultL3Bonus: number[];
  vaultL3StakeDuration: number;
  interval: any;
  id: string;
  creditCardTxFixed: number;
  creditCardTxVariable: number;
  chargeBackFees: number;
  howMuchToKeepInVaultRatio: number[];
  howMuchForSponsor: number;
  howMuchForReferral: number;

  howMuchInactivity: number;
  minimumToTransferOut: number;
  minimumPaymentRequest: number;
  maximum7DaysPaymentRequest: number[];
  maximumPaymentRequestVaultMinimum: number[];
  cashOutFee: number;
}

// pricingPlans[PlanEnum.TEST]
