import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Emitted, NgxsFirestoreConnect, StreamEmitted } from '@ngxs-labs/firestore-plugin';
import { Observable } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';

import { IPayout } from '../../model-shared/payout';
import {
  PayoutRequestsActionsGet,
  PayoutRequestsActionsGetAll,
} from '../actions/payout-requests.actions';
import { PayoutRequestsFirestore } from '../firestore/payout-requests.firestore';
import { IUserModel } from './auth.state';

export interface IPayoutRequestsStateModel {
  payoutRequest: {
    data: IPayout[];
    total: number;
  };
}
@State<IPayoutRequestsStateModel>({
  name: 'payoutRequest',
  defaults: {
    payoutRequest: { data: [], total: 0 },
  },
})
@Injectable({ providedIn: 'root' })
export class PayoutRequestsState {
  @Select((a: any) => a.userData) authentifiedUser$: Observable<IUserModel>;
  @Selector() static payoutRequest(state: IPayoutRequestsStateModel) {
    return state.payoutRequest;
  }
  ngxsOnInit(ctx: StateContext<IPayoutRequestsStateModel>) {
    this.ngxsFirestoreConnect.connect(PayoutRequestsActionsGetAll, {
      to: () =>
        this.authentifiedUser$.pipe(
          filter((user) => user?.userData?.uid !== undefined),
          map((user) => user.userData.uid),
          flatMap((uid) => this.payoutFS.collection$((ref) => ref.where('freelanceID', '==', uid))),
          map((res: IPayout[]) => ({ data: res, total: res.length })),
        ),
    });
    ctx.dispatch(new PayoutRequestsActionsGetAll());
  }
  constructor(
    private payoutFS: PayoutRequestsFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
  ) {}
  @Action(StreamEmitted(PayoutRequestsActionsGetAll))
  getAllEmitted(
    ctx: StateContext<IPayoutRequestsStateModel>,
    { payload }: Emitted<PayoutRequestsActionsGet, { data: IPayout[]; total: number }>,
  ) {
    ctx.setState(patch({ payoutRequest: payload }));
  }
}
