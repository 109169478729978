import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrontEndSharedModule } from '@frontend-shared/frontshared.module';
import {
  NbAccordionModule,
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbRadioModule,
  NbSelectModule,
  NbTabsetModule,
  NbUserModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { ThemeModule } from '../@theme/theme.module';
import { AcceptTermsComponent } from './accept-terms/accept-terms.component';
import { CountryComponent } from './country/country.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { EditprofileComponent } from './editProfile/editprofile.component';
import { GetIdVerifyComponent } from './getid-verify/get-id-verify.component';
import { InviteOnlyComponent } from './invite-only/invite-only.component';
import { LoadingWaitComponent } from './loading-wait/loading-wait.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { NicknameComponent } from './nickname/nickname.component';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PayoutAddressComponent } from './payout-address/payout-address.component';
import { PayoutListComponent } from './payout-list/payout-list.component';
import { PayoutlistaddrComponent } from './payoutlistaddr/payoutlistaddr.component';
import { ProfileImageComponent } from './profileImage/profileImage.component';
import { UpdateNickNameComponent } from './update-nick-name/update-nick-name.component';
import { UpdateEmailComponent } from './updateEmail/updateEmail.component';
import { VerifyEmailComponent } from './verifyEmail/verify-email.component';
@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    DashboardModule,
    NbActionsModule,
    NbButtonModule,
    NbCardModule,
    NbTabsetModule,
    NbUserModule,
    NbRadioModule,
    NbSelectModule,
    NbAccordionModule,
    NbListModule,
    NbIconModule,
    NbFormFieldModule,
    NbInputModule,
    NbCheckboxModule,
    NbMenuModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    TranslateModule,
    NbDialogModule.forChild(),
    NbLayoutModule,
    FrontEndSharedModule,
  ],
  declarations: [
    PagesComponent,
    EditprofileComponent,
    ProfileImageComponent,
    UpdateEmailComponent,
    VerifyEmailComponent,
    CountryComponent,
    GetIdVerifyComponent,
    PayoutAddressComponent,
    PayoutListComponent,
    PaymentDetailsComponent,
    LoadingWaitComponent,
    UpdateNickNameComponent,
    AcceptTermsComponent,
    InviteOnlyComponent,
    MaintenanceComponent,
    PayoutlistaddrComponent,
    NicknameComponent,
  ],
})
export class PagesModule {}
