import { Component, OnInit } from '@angular/core';
import { LoginWithEmailPass } from '@frontend-shared/actions/auth.actions';
import { ILoginWithEmailPassword } from '@model-shared/user';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ngx-login-with-email-pass',
  templateUrl: './login-with-email-pass.component.html',
  styleUrls: ['./login-with-email-pass.component.scss'],
})
export class LoginWithEmailPassComponent implements OnInit {
  constructor(private store: Store) {}

  messageString: string;

  @Select((state) => state.router.state.root.queryParams)
  router: Observable<ILoginWithEmailPassword>;

  ngOnInit(): void {
    this.router.pipe(take(1)).subscribe((p) => this.store.dispatch(new LoginWithEmailPass(p)));
  }
}
