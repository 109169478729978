<div class="row">
  <div class="col-12">
    <nb-flip-card [showToggleButton]="false" [flipped]="flipped" id="depositForm">
      <nb-card-front>
        <nb-card id="inbox">
          <nb-card-header>
            <div class="row">
              <div class="col-2 text-left">
                <h5 class="float-left text-left">
                  Inbox
                </h5>
              </div>
              <div class="col-10 text-right">
                <h5 *ngIf="(currency$|async) === 'USD'" class="align-middle float-right price">
                    <img class="usdc-header-icon" alt="USDC icon" src="/assets/images/usdc.png">
                    {{(vault$ | async)?.vault?.vault_1 }}</h5>
                <h5 *ngIf="(currency$|async) !== 'USD'"
                    class="align-middle float-right price">
                  <span id="inboxCurrencyChange">({{(vault$ | async)?.vault?.vault_1 | currencyConvert | async | currency:(currency$|async)}})&nbsp;</span>
                  <img class="usdc-header-icon" alt="USDC icon" src="/assets/images/usdc.png">
                   {{(vault$ | async)?.vault?.vault_1 }}
                </h5>
              </div>
            </div>
            <div>
              <span></span>
            </div>
          </nb-card-header>
          <nb-card-body>
            <br>

            <div class="row card-content-description">
              <div style="padding-right: 0px;text-align: left;" class="col-lg-6 col-md-12 col-sm-12 card-body-first-column">
                <div style="font-weight: 600;">
                  <b>Your next deposit will offer</b>
                  <br><br>
                </div>
                <table style="width: 97%;">
                  <tr>
                    <th>In Vault</th>
                    <th class="table-middle-column">APR</th>
                    <th class="table-last-column">7 days limit</th>
                  </tr>
                  <tr>
                    <td>Under <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">500</td>
                    <td class="table-middle-column">4%</td>
                    <td class="table-last-column"><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">300</td>
                  </tr>
                  <tr>
                    <td>Over <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">500</td>
                    <td class="table-middle-column">6%</td>
                    <td class="table-last-column"><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">600</td>
                  </tr>
                  <tr>
                    <td>Over <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">1000</td>
                    <td class="table-middle-column">8%</td>
                    <td class="table-last-column" ><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">1200</td>
                  </tr>
                  <tr>
                    <td>Over <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">5000</td>
                    <td class="table-middle-column">10%</td>
                    <td class="table-last-column" ><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">1200</td>
                  </tr>
                </table>

                <!--
                <div *ngIf="(vault$ | async)?.vault?.nextBonus" class="row p-1">
                  <span *ngIf="(currency$|async) === 'USD'"
                        class="align-middle float-right price">Stake {{(vault$ | async)?.vault?.nextVaultAmount | currency:'USD'}}

                    and increase your bonus
                  </span>
                  <span *ngIf="(currency$|async) !== 'USD'"
                        class="align-middle float-right price">Stake
                                        ({{(vault$ | async)?.vault?.nextVaultAmount | currencyConvert | async | currency:(currency$|async)}}
                    )
                    {{(vault$ | async)?.vault?.nextVaultAmount | currency:'USD'}}
                    and increase your bonus
                  to {{(vault$ | async)?.vault?.nextBonus * 4}}% APR       </span>
                  <span>from {{(vault$ | async)?.vault?.currentBonus * 4}}%  to {{(vault$ | async)?.vault?.nextBonus * 4}}% APR </span>
                </div> -->
      

              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div [formGroup]="transferOrder">
                  <div class="row">
                    <div class="col">
                      <div class="form-group ">
                        <nb-form-field>
                          <input fullWidth type="text" decimals="2" nbInput id="pname" formControlName="amount" id="amountDeposit"
                                 placeholder="Amount to Deposit (min. 20)" name="amount" />
                          <nb-icon *ngIf="transferOrder.controls.amount.value.length != 0" nbSuffix
                                   [icon]="transferOrder.controls.amount.errors ? 'close-outline' : 'checkmark-outline'"
                                   pack="eva"
                                   [attr.aria-label]="transferOrder.controls.amount.errors ? 'Incorrect' : 'Correct'">
                          </nb-icon>
                        </nb-form-field>
                      </div>
                    </div>
                  </div>
            
                    <button nbButton size="medium" [status]="'primary'" id="depositSubmit"
                     [disabled]="!transferOrder.valid || disabledButton" fullWidth
                            class="btn-stake-to-vault" (click)="flipped = !flipped">
                      {{buttonVal}}
                      &nbsp;
                      <i class="fas fa-arrow-right"></i>
                    </button>
               
                </div>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </nb-card-front>
      <nb-card-back>
        <nb-card>
          <nb-card-header>
            <div class="row card-content-description">
              <div class="col-6 text-left">
                <h5 class="float-left text-left">
                  Vault Terms
                </h5>
              </div>
            </div>
          </nb-card-header>
          <nb-card-body>

            <div class="row">
              <div class="col-12">
                <div class="p-0 col-6 float-left text-left mb-2">Amount to deposit</div>
                <div class="p-0 col-5 float-right text-right">
                  {{transferOrder.value.amount}} USD
                </div>
              </div>
            </div>
            <div><br></div>

            <div class="row">
              <div class="col-12">
                <div class="p-0 col-6 float-left text-left mb-2">Back to Inbox on</div>
                <div class="p-0 col-5 float-right text-right">
                  {{transferDateStake | date}}
                </div>
              </div>
            </div>
            <div><br></div>

            <div class="row">
              <div class="col-12">
                <div class="p-0 col-6 float-left text-left mb-2">90 days interest upfront</div>
                <div class="p-0 col-5 float-right text-right">
                  {{transferOrder.value.amount * ((vault$ | async)?.vault?.currentBonus) / 100 | number:'1.2-2'}} USD
                  ({{(vault$ | async)?.vault?.currentBonus * 4}}% APR)
                </div>
              </div>
            </div>


            <!-- <div class="row">
              <div class="col-12">
                <div class="p-0 col-6 float-left text-left mb-2">Interest APR after staking</div>
                <div class="p-0 col-5 float-right text-right">
                  {{(vault$ | async)?.vault?.currentBonus * 4}}% APR
                </div>
              </div>
            </div> -->

          </nb-card-body>
          <nb-card-footer>
            <div style="float: right;">
              <button nbButton size="medium" status="danger" style="margin-right: 10px"
                      (click)="submitVal(false, 'TRANSFER_FROM_L1_TO_L3' , 'STAKE');">Cancel
              </button>
              <button nbButton size="medium"  id="depositConfirm" status="primary" (click)="submitVal(true, 'TRANSFER_FROM_L1_TO_L3' , 'STAKE');">Confirm
              </button>
            </div>
          </nb-card-footer>
        </nb-card>
      </nb-card-back>
    </nb-flip-card>
  </div>
</div>
