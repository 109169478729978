import {
  FreelanceIDType,
  PaymentRequestIDType,
  PaymentRequestStatusIDType,
} from './typedef';

export enum PaymentRequestState {
  INVALID,
  PENDING_SEND,
  SEND_FAILED,
  EXPIRED,
  SENT,
  RETRY,
  CONFIRMED,
  PAID,
  CHARGED_BACK,
  PROCESSING,
}
export interface IPaymentRequestStatus {
  id?: PaymentRequestStatusIDType;
  paymentRequestID: PaymentRequestIDType;
  timestamp: number;
  state: string; // PaymentRequestState
  phone: string;
  freelanceID: FreelanceIDType; // ID of the freelance requesting the payment
  txFee?: number;
  amount?: number;
  apiLog?: string;
}
