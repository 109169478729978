<nb-card id="transOrderList">
  <nb-card-header>{{ 'TRANSFER_ORDER.list_title' | translate }} (Total: {{(transOrders$ | async).length}})</nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <tr>
            <th>ID</th>
            <th>{{ 'TRANSFER_ORDER.amount' | translate }}</th>
            <th>{{ 'TRANSFER_ORDER.type' | translate }}</th>
            <th>{{ 'TRANSFER_ORDER.currency' | translate }}</th>
            <th>Date/Time</th>
          </tr>
          <tr *ngFor="let transOrder of
                (transOrders$ | async) 
                | paginate: { itemsPerPage: 10, currentPage: page }; let i = index">
            <td>{{transOrder.id}}</td>
            <td>

              {{transOrder.amount | currency:'USD'}}
              <small *ngIf="(currency$|async) !== 'USD'">
                ( {{transOrder.amount | currencyConvert | async | currency:(currency$|async)}} )
              </small>
            </td>
            <td>{{transOrder.type}}</td>
            <td>{{transOrder.currency}}</td>
            <td>{{transOrder.timestamp |  date:'medium'}}</td>
          </tr>
          <tr *ngIf="(transOrders$ | async)?.data?.length == 0">
            <td class="text-center" colspan="5">{{ 'PAYMENT_REQUEST.no_record_found' | translate }}</td>
          </tr>
        </table>
        <div class="pull-right">
          <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
