import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FireFunctionsService } from '@frontend-shared/services/fire-functions.service';
import { AuthState } from '@frontend-shared/state/auth.state';
import { IVaultStateModel } from '@frontend-shared/state/vault.state';
import { minValidator } from '@frontend-shared/validators/minMaxValidator';
import { ITransferOrder } from '@model-shared/transferOrder';
import { assertValidCurrency, assertValidFreelancerID, USD_CURRENCY } from '@model-shared/typedef';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { navigateToUpdatePayoutAddress } from '../../../navigation/navigation.action';

@Component({
  selector: 'app-transfer-order',
  templateUrl: './transfer-order.component.html',
  styleUrls: ['./transfer-order.component.scss'],
})
export class TransferOrderComponent implements OnInit {
  disabledSubmitButton = false;
  checkPayout = true;
  @Select((state) => state.userData.userData.uid) userId$: Observable<string>;
  @Select((a) => a.user.user.payoutAddress) payOutAddr$: Observable<string>;
  @Select((s) => s.vault) vault$: Observable<IVaultStateModel>;
  transferOrder = new FormGroup({
    amount: new FormControl('', [Validators.required]),
  });
  constructor(private store: Store, private fireFuncs: FireFunctionsService) {}
  ngOnInit() {
    this.vault$.pipe(filter((a) => a?.vault !== null)).subscribe((data) => {
      this.transferOrder.controls['amount'].setValidators([
        minValidator(data.vault.minimumToTransferOut),
        Validators.required,
      ]);
    });
  }
  async submit(type: string) {
    this.disabledSubmitButton = true;
    setTimeout(() => {
      this.disabledSubmitButton = false;
    }, 5000);
    const userData = this.store.selectSnapshot(AuthState.getAuthUser);
    const transOrd = {} as ITransferOrder;
    const freelanceID = userData.uid;
    assertValidFreelancerID(freelanceID);
    transOrd.freelanceID = freelanceID;
    transOrd.type = type;
    transOrd.amount = this.transferOrder.value.amount;
    const usd = USD_CURRENCY;
    assertValidCurrency(usd);
    transOrd.currency = usd;
    await this.fireFuncs
      .submitTransferOrder()(transOrd)
      .toPromise()
      .catch((error) => {
        console.error(error);
        window.location.reload();
      });
    this.transferOrder.reset();
  }
  navigateToPayout() {
    this.store.dispatch(navigateToUpdatePayoutAddress);
  }
}
