<nb-card>
  <nb-card-header>
    Invite your friends with your personal code <strong>{{(codes$ | async).inviteCode[0]}}</strong> and <a href="https://indie.money/indie-affiliate-program/" target="_blank"> you will both get 15 USDC <i class='fa fa-question-circle' aria-hidden="true"
  ></i></a>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12" #contain >
       <share-buttons [theme]="'material-dark'"
                       [include]="['facebook','twitter','linkedin','reddit','telegram','whatsapp','line','sms','email','copy']"
                       [show]="12"
                       [size]="1"
                       url="{{webaddress}}invite/{{(codes$ | async).inviteCode[0]}}"
                       title="Join indie and get your $15 bonus! Open with Chrome or Safari now."
                       description="indie is the payment platform to get paid with VISA/MasterCard, without a bank account."
                       image="https://my.indie.money/assets/images/indie-small-i-transparent-300x150.png"
                       tags="freelance payment usdc blockchain visa mastercard"
                       [autoSetMeta]="false"
        ></share-buttons>
        <br>


      </div>
    </div>
  </nb-card-body>
</nb-card>
