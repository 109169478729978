import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { isLocalHost } from '../tools/tools';
@Injectable({
  providedIn: 'root',
})
export class FireFunctionsService {
  private prefix = '';
  constructor(private functions: AngularFireFunctions) {
    if (isLocalHost) {
      this.prefix = 'api/';
      void this.functions.useFunctionsEmulator('http://localhost:5001');
    }
  }
  getPublicKey = () => this.functions.httpsCallable(this.prefix + 'getPK');
  submitICharge = () => this.functions.httpsCallable(this.prefix + 'chargeCard');
  submitTransferOrder = () => this.functions.httpsCallable(this.prefix + 'transferOrder');
  submitPaymentRequest = () => this.functions.httpsCallable(this.prefix + 'paymentRequest');
  getGracePeriod = (paymentRequestID: string) =>
    this.functions.httpsCallable(this.prefix + `gracePeriod/${paymentRequestID}`);
  getAddressAutocomplete = (input: string, countryCode: string, userLang: string) =>
    this.functions.httpsCallable(this.prefix + 'addressAutoComplete')({
      input,
      countryCode,
      userLang,
    });
  getPlaceDetails = (placeId: string, userLang: string) =>
    this.functions.httpsCallable(this.prefix + 'getPlaceDetails')({
      placeId,
      userLang,
    });
}
