import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Store } from '@ngxs/store';
import { FirebaseuiAngularLibraryService, FirebaseUISignInFailure } from 'firebaseui-angular';

@Component({
  selector: 'ngx-login',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  constructor(
    private store: Store,

    private afAuth: AngularFireAuth,
    private firebaseuiAngularLibraryService: FirebaseuiAngularLibraryService,
  ) {
    firebaseuiAngularLibraryService.firebaseUiInstance.disableAutoSignIn();
  }

  errorCallback(errorData: FirebaseUISignInFailure) {
    console.log(errorData);
  }

  successCallback() {
    setTimeout(() => window.location.reload(), 1000);
  }
}
