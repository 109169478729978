export const SReqPaymentLoadFireData = '[reqPayment] Load Data';
export const SSubmitPaymentResponse = '[ccState] Submit Data';
export const SResetPaymentResponse = '[ccState] Reset State';
export const SLoadBuyers = '[buyer] buyers init';
export const SFilteredCards = '[buyer] Filter Cards';
export const SEmptyCards = '[buyer] Empty Cards';

// this needs to move to model-shared collection.ts

//////////// firestore collection Names ///////////////

export const SPaymentRequestCollection = 'paymentRequest';
export const SPaymentRequestUserFetch = 'payment_request';
export const SBuyersCollection = 'buyers';
export const SPreviousCardsCollection = 'previous_cards';

/////////////// AUTH STATE ///////////////

export const updateUser = '[Auth] Update User';
export const signOut = '[Auth] SignOut Call';
export const loginWithEmailAndPass = '[Auth] Login with email and pass';
export const updateUserProfile = '[Auth] Update Profile';
export const updateUserProfilePicture = '[Auth] Update Profile Picture';
export const updateEmail = '[Auth] Update Email';
export const SAnonSignin = '[Auth] AnonSignIn';

/////////////// FIREBASE STATE ///////////////

export const analyticsLogEvent = '[Fire] Log Analytics Event';
export const setUserID = '[Fire] Set UserID';

////////////// fireStoreActions ///////////////
export const photoUpload = 'photoUpload';
export const emailChange = 'emailChange';
export const verifyEmail = 'verifyEmail';
