import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentRequestsActionsCreate } from '@frontend-shared/actions/payment-requests.actions';
import { CurrencyexchangeService } from '@frontend-shared/services/currencyexchange.service';
import { AuthState } from '@frontend-shared/state/auth.state';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { IVaultStateModel } from '@frontend-shared/state/vault.state';
import { isPreproductionFE } from '@frontend-shared/tools/tools';
import { minMaxValidator } from '@frontend-shared/validators/minMaxValidator';
import { IPaymentRequest } from '@model-shared/payment-request';
import { getPaymentURL } from '@model-shared/paymentMessage';
import {
  assertValidFreelancerID,
  assertValidPaymentRequestID,
  CurrencyType,
  FreelanceIDType,
  USD_CURRENCY,
} from '@model-shared/typedef';
import { Select, Store } from '@ngxs/store';
import dayjs from 'dayjs';
import { AsYouType, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { freeLanceFrontCurrencies, freeLanceFrontLanguages } from '../../../../langs';

const validatePhoneNumber = (c: AbstractControl): { [key: string]: any } => {
  if (c.value)
    try {
      // deepcode ignore WrongNumberOfArgs: working
      if (!parsePhoneNumber(c.value).isValid()) return { validPhoneNo: true };
    } catch {
      return { validPhoneNo: true };
    }
  return null;
};

const localStorageId = 'currency';

const contactsAPITest = 'contacts' in navigator && 'ContactsManager' in window;

interface ICurrencyStored {
  currency: CurrencyType;
  freelanceID: FreelanceIDType;
}

@Component({
  selector: 'indie-new-payment-widget',
  templateUrl: './new-payment.component.html',
  styleUrls: ['./new-payment.component.scss'],
})
export class NewPaymentComponent implements OnInit {
  transferDateStake = dayjs().add(90, 'd').toDate();
  disabledSubmitButton = false;
  flipped = false;
  submitButtonVal = 'SEND NOW';
  currencies = freeLanceFrontCurrencies;
  languages = freeLanceFrontLanguages;
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;
  @Select((s) => s.vault) vault$: Observable<IVaultStateModel>;
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;
  @Select((state) => state.user.user.nickname) nickname$: Observable<string>;
  @Select((state) => state.updatePayment.paymentRequest)
  updatePayment$: Observable<IPaymentRequest>;
  today = new Date();
  contactsSupported = contactsAPITest;

  paymentRequest = new FormGroup({
    phone: new FormControl('', Validators.compose([Validators.required, validatePhoneNumber])),
    amount: new FormControl('', [Validators.required]),
    reason: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    freelanceID: new FormControl(''),
    currency: new FormControl(USD_CURRENCY),
    lang: new FormControl('en'),
    from: new FormControl(''),
    reference: new FormControl(''),
    amountDisplay: new FormControl(''),
    currencyDisplay: new FormControl(USD_CURRENCY),
  });
  screenWidth: any;
  fieldSizeVariable = 'medium';

  constructor(private store: Store, public currencyExchange: CurrencyexchangeService) {}

  public reviewStringTo = () => `To: ${this.paymentRequest.value.phone}`;

  public focusPhone = () => {
    if (this.paymentRequest.value.phone === '') this.paymentRequest.controls.phone.setValue('+');
  };

  public focusoutPhone = () => {
    if (this.paymentRequest.value.phone === '+') this.paymentRequest.controls.phone.setValue('');
  };

  public reviewStringReason = () =>
    this.user$.pipe(
      map((u) => {
        const currencyDisplay: string = this.paymentRequest.value.currencyDisplay;
        const amount: number = Number.parseInt(this.paymentRequest.value.amount);
        const freelanceID = this.store.selectSnapshot((a) => a.user.user.id);
        assertValidFreelancerID(freelanceID);
        const id = '';
        assertValidPaymentRequestID(id);
        return getPaymentURL(
          {
            currencyDisplay: this.paymentRequest.value.currencyDisplay,
            currency: this.paymentRequest.value.currency,
            freelanceID,
            from: '',
            phone: '',
            reference: '',
            lang: this.paymentRequest.value.lang,
            amount: this.currencyExchange.getCurrency(amount, currencyDisplay),
            amountDisplay: this.paymentRequest.value.amount,
            id,
            reason: this.paymentRequest.value.reason,
          },
          isPreproductionFE(environment),
          u.user.nickname,
        );
      }),
    );

  getFromStorage = (): ICurrencyStored => JSON.parse(localStorage.getItem(localStorageId));

  saveToStorage = () =>
    localStorage.setItem(
      localStorageId,
      JSON.stringify({
        currency: this.paymentRequest.value.currencyDisplay,
        freelanceID: this.paymentRequest.value.freelanceID,
      }),
    );

  ngOnInit() {
    this.listenToPaymentUpdates();
    this.listenToVaultUpdatesToSetValidators();
  }

  private listenToVaultUpdatesToSetValidators() {
    this.vault$.pipe(filter((a) => a?.vault !== null)).subscribe((data) => {
      this.paymentRequest.controls['amount'].setValidators([
        minMaxValidator(
          this.currencyExchange.getCurrencyFromUSD(
            data.vault.minPaymentRequestAmount,
            this.paymentRequest.value.currencyDisplay,
          ),
          this.currencyExchange.getCurrencyFromUSD(
            data.vault.vault_3_7days_left,
            this.paymentRequest.value.currencyDisplay,
          ),
        ),
        Validators.required,
      ]);
    });
  }

  private listenToPaymentUpdates() {
    this.updatePayment$.subscribe((payReq) => {
      const storedCurrency = this.getFromStorage();
      const currency =
        storedCurrency &&
        storedCurrency.freelanceID === this.store.selectSnapshot((a) => a.user.user.id)
          ? storedCurrency.currency
          : USD_CURRENCY;
      if (!payReq) {
        this.reset(currency);
      } else this.fillForm(payReq, currency);
    });
  }

  currencyChanged() {
    const value = this.paymentRequest.get('currencyDisplay').value;
    const data = this.store.selectSnapshot((s) => s.vault.vault);
    if (data)
      this.paymentRequest.controls['amount'].setValidators([
        minMaxValidator(
          this.currencyExchange.getCurrencyFromUSD(
            Number.parseInt(data.minPaymentRequestAmount),
            value,
          ),
          this.currencyExchange.getCurrencyFromUSD(Number.parseInt(data.vault_3_7days_left), value),
        ),
        Validators.required,
      ]);

    const myVal = this.paymentRequest.controls.amount.value;
    this.paymentRequest.controls.amount.setValue(myVal);
  }

  transformPhone = (event) =>
    this.paymentRequest.patchValue({
      phone: new AsYouType().input(event.target.value),
    });

  onSubmit(res: boolean) {
    this.flipped = !this.flipped;
    if (res === true) {
      this.paymentRequest.controls.amountDisplay.setValue(this.paymentRequest.value.amount);
      if (this.paymentRequest.value.currencyDisplay !== USD_CURRENCY) {
        this.paymentRequest.controls.amount.setValue(
          this.currencyExchange.getCurrency(
            Number.parseInt(this.paymentRequest.value.amount),
            this.paymentRequest.value.currencyDisplay,
          ),
        );
      }
      this.disabledSubmitButton = true;
      this.submitButtonVal = 'PROCESSING';
      const userData = JSON.parse(JSON.stringify(this.store.selectSnapshot(AuthState.getAuthUser)));
      this.user$.pipe(take(1)).subscribe((u) => {
        this.paymentRequest.patchValue({
          freelanceID: userData.uid,
          from: u.user.nickname,
          reference: '',
        });
        this.store.dispatch(new PaymentRequestsActionsCreate({ ...this.paymentRequest.value }));
        setTimeout(() => {
          this.submitButtonVal = 'SEND';
          this.disabledSubmitButton = false;
        }, 5000);
        this.saveToStorage();
        this.reset(this.paymentRequest.value.currencyDisplay);
      });
    } else {
      this.reset();
      this.submitButtonVal = 'SEND';
      this.disabledSubmitButton = false;
    }
  }

  fillForm(paymentRequest: IPaymentRequest, currency: string) {
    this.paymentRequest.setValue({
      phone: paymentRequest.phone,
      amount: paymentRequest.amountDisplay ? paymentRequest.amountDisplay : paymentRequest.amount,
      reason: paymentRequest.reason,
      freelanceID: paymentRequest.freelanceID,
      currency: paymentRequest.currency,
      from: paymentRequest.from,
      lang: paymentRequest.lang ? paymentRequest.lang : 'en',
      reference: paymentRequest.reference,
      currencyDisplay: paymentRequest.currencyDisplay
        ? paymentRequest.currencyDisplay
        : USD_CURRENCY,
      amountDisplay: paymentRequest.amountDisplay ? paymentRequest.amountDisplay : '',
    });
    this.currencyChanged();
    if (this.paymentRequest.invalid) this.reset(currency);
  }

  reset = (currencySelected?: string) => {
    this.paymentRequest.setValue({
      phone: '',
      amount: '',
      reason: '',
      freelanceID: '',
      currency: USD_CURRENCY,
      from: '',
      lang: 'en',
      reference: '',
      currencyDisplay: currencySelected !== undefined ? currencySelected : USD_CURRENCY,
      amountDisplay: '',
    });
    this.currencyChanged();
  };

  displayContacts = async () => {
    try {
      const contacts: any[] = await (navigator as any).contacts.select(['tel']);
      if (contacts && contacts.length > 0) {
        for (const tel of contacts[0].tel) {
          const phone = tel.replace(/\s/g, '');
          if (isValidPhoneNumber(phone))
            this.paymentRequest.patchValue({
              phone,
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
}
