import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
/**
 *  For the add payment Request
 */
@State({
  name: 'paymentRequestForm',
  defaults: {
    details: {
      model: {},
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable({ providedIn: 'root' })
export class PaymentRequestFormState {}
