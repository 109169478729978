export interface ICountryType {
  name_official: string;
  code: string;
  latitude: string;
  name: string;
  zoom: number;
  id: any;
  code3l: string;
  enabled: boolean;
  longitude: string;
}

export const countries: ICountryType[] = [
  {
    id: 1,
    enabled: true,
    code3l: 'AFG',
    code: 'AF',
    name: 'Afghanistan',
    name_official: 'Islamic Republic of Afghanistan',
    latitude: '33.98299275',
    longitude: '66.39159363',
    zoom: 6,
  },
  {
    id: 199,
    enabled: true,
    code3l: 'ALA',
    code: 'AX',
    name: '\u00c5land Islands',
    name_official: '\u00c5land Islands',
    latitude: '60.25403213',
    longitude: '20.35918350',
    zoom: 9,
  },
  {
    id: 2,
    enabled: true,
    code3l: 'ALB',
    code: 'AL',
    name: 'Albania',
    name_official: 'Republic of Albania',
    latitude: '41.00017358',
    longitude: '19.87170014',
    zoom: 7,
  },
  {
    id: 3,
    enabled: true,
    code3l: 'DZA',
    code: 'DZ',
    name: 'Algeria',
    name_official: "People's Democratic Republic of Algeria",
    latitude: '27.89861690',
    longitude: '3.19771194',
    zoom: 5,
  },
  {
    id: 197,
    enabled: true,
    code3l: 'ASM',
    code: 'AS',
    name: 'American Samoa',
    name_official: 'The United States Territory of American Samoa',
    latitude: '-14.30634641',
    longitude: '-170.69501750',
    zoom: 11,
  },
  {
    id: 4,
    enabled: true,
    code3l: 'AND',
    code: 'AD',
    name: 'Andorra',
    name_official: 'Principality of Andorra',
    latitude: '42.54057088',
    longitude: '1.55201340',
    zoom: 11,
  },
  {
    id: 5,
    enabled: true,
    code3l: 'AGO',
    code: 'AO',
    name: 'Angola',
    name_official: 'Republic of Angola',
    latitude: '-12.16469683',
    longitude: '16.70933622',
    zoom: 6,
  },
  {
    id: 195,
    enabled: true,
    code3l: 'AIA',
    code: 'AI',
    name: 'Anguilla',
    name_official: 'Anguilla',
    latitude: '18.22053521',
    longitude: '-63.06861300',
    zoom: 12,
  },
  {
    id: 196,
    enabled: true,
    code3l: 'ATA',
    code: 'AQ',
    name: 'Antarctica',
    name_official: 'Antarctica',
    latitude: '-45.13806295',
    longitude: '10.48095703',
    zoom: 2,
  },
  {
    id: 6,
    enabled: true,
    code3l: 'ATG',
    code: 'AG',
    name: 'Antigua and Barbuda',
    name_official: 'Antigua and Barbuda',
    latitude: '17.48060423',
    longitude: '-61.42014426',
    zoom: 9,
  },
  {
    id: 7,
    enabled: true,
    code3l: 'ARG',
    code: 'AR',
    name: 'Argentina',
    name_official: 'Argentine Republic',
    latitude: '-38.01529308',
    longitude: '-64.97897469',
    zoom: 4,
  },
  {
    id: 8,
    enabled: true,
    code3l: 'ARM',
    code: 'AM',
    name: 'Armenia',
    name_official: 'Republic of Armenia',
    latitude: '40.13475528',
    longitude: '45.01072318',
    zoom: 7,
  },
  {
    id: 198,
    enabled: true,
    code3l: 'ABW',
    code: 'AW',
    name: 'Aruba',
    name_official: 'Aruba of the Kingdom of the Netherlands',
    latitude: '12.52109661',
    longitude: '-69.96833800',
    zoom: 12,
  },
  {
    id: 9,
    enabled: true,
    code3l: 'AUS',
    code: 'AU',
    name: 'Australia',
    name_official: 'Australia',
    latitude: '-26.29594646',
    longitude: '133.55540944',
    zoom: 4,
  },
  {
    id: 10,
    enabled: true,
    code3l: 'AUT',
    code: 'AT',
    name: 'Austria',
    name_official: 'Republic of Austria',
    latitude: '47.63125476',
    longitude: '13.18776731',
    zoom: 7,
  },
  {
    id: 11,
    enabled: true,
    code3l: 'AZE',
    code: 'AZ',
    name: 'Azerbaijan',
    name_official: 'Republic of Azerbaijan',
    latitude: '40.35321757',
    longitude: '47.46706372',
    zoom: 7,
  },
  {
    id: 12,
    enabled: true,
    code3l: 'BHS',
    code: 'BS',
    name: 'Bahamas',
    name_official: 'Commonwealth of the Bahamas',
    latitude: '24.45991732',
    longitude: '-77.68192453',
    zoom: 7,
  },
  {
    id: 13,
    enabled: true,
    code3l: 'BHR',
    code: 'BH',
    name: 'Bahrain',
    name_official: 'Kingdom of Bahrain',
    latitude: '25.90740996',
    longitude: '50.65932354',
    zoom: 9,
  },
  {
    id: 14,
    enabled: true,
    code3l: 'BGD',
    code: 'BD',
    name: 'Bangladesh',
    name_official: "People's Republic of Bangladesh",
    latitude: '24.08273251',
    longitude: '90.49915527',
    zoom: 7,
  },
  {
    id: 15,
    enabled: true,
    code3l: 'BRB',
    code: 'BB',
    name: 'Barbados',
    name_official: 'Barbados',
    latitude: '13.19383077',
    longitude: '-59.54319600',
    zoom: 11,
  },
  {
    id: 16,
    enabled: true,
    code3l: 'BLR',
    code: 'BY',
    name: 'Belarus',
    name_official: 'Republic of Belarus',
    latitude: '53.58628747',
    longitude: '27.95338900',
    zoom: 6,
  },
  {
    id: 17,
    enabled: true,
    code3l: 'BEL',
    code: 'BE',
    name: 'Belgium',
    name_official: 'Kingdom of Belgium',
    latitude: '50.49593874',
    longitude: '4.46993600',
    zoom: 8,
  },
  {
    id: 18,
    enabled: true,
    code3l: 'BLZ',
    code: 'BZ',
    name: 'Belize',
    name_official: 'Belize',
    latitude: '17.21153631',
    longitude: '-88.01424956',
    zoom: 8,
  },
  {
    id: 19,
    enabled: true,
    code3l: 'BEN',
    code: 'BJ',
    name: 'Benin',
    name_official: 'Republic of Benin',
    latitude: '9.37180859',
    longitude: '2.29386134',
    zoom: 7,
  },
  {
    id: 201,
    enabled: true,
    code3l: 'BMU',
    code: 'BM',
    name: 'Bermuda',
    name_official: 'Bermudas',
    latitude: '32.31995785',
    longitude: '-64.76182765',
    zoom: 12,
  },
  {
    id: 20,
    enabled: true,
    code3l: 'BTN',
    code: 'BT',
    name: 'Bhutan',
    name_official: 'Kingdom of Bhutan',
    latitude: '27.50752756',
    longitude: '90.43360300',
    zoom: 8,
  },
  {
    id: 182,
    enabled: true,
    code3l: 'BOL',
    code: 'BO',
    name: 'Bolivia (Plurinational State of)',
    name_official: 'Plurinational State of Bolivia',
    latitude: '-16.74518128',
    longitude: '-65.19265691',
    zoom: 6,
  },
  {
    id: 202,
    enabled: true,
    code3l: 'BES',
    code: 'BQ',
    name: 'Bonaire, Saint Eustatius And Saba',
    name_official: 'Bonaire, Saint Eustatius and Saba',
    latitude: '12.17229702',
    longitude: '-68.28831170',
    zoom: 11,
  },
  {
    id: 21,
    enabled: true,
    code3l: 'BIH',
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    name_official: 'Bosnia and Herzegovina',
    latitude: '44.00040856',
    longitude: '17.81640910',
    zoom: 7,
  },
  {
    id: 22,
    enabled: true,
    code3l: 'BWA',
    code: 'BW',
    name: 'Botswana',
    name_official: 'Republic of Botswana',
    latitude: '-22.18279485',
    longitude: '24.22344422',
    zoom: 6,
  },
  {
    id: 203,
    enabled: true,
    code3l: 'BVT',
    code: 'BV',
    name: 'Bouvet Island',
    name_official: 'Bouvet Island',
    latitude: '-54.42316906',
    longitude: '3.41319600',
    zoom: 12,
  },
  {
    id: 23,
    enabled: true,
    code3l: 'BRA',
    code: 'BR',
    name: 'Brazil',
    name_official: 'Federative Republic of Brazil',
    latitude: '-11.80965046',
    longitude: '-53.33152600',
    zoom: 4,
  },
  {
    id: 220,
    enabled: true,
    code3l: 'IOT',
    code: 'IO',
    name: 'British Indian Ocean Territory',
    name_official: 'The British Indian Ocean Territory',
    latitude: '-7.33461519',
    longitude: '72.42425280',
    zoom: 12,
  },
  {
    id: 24,
    enabled: true,
    code3l: 'BRN',
    code: 'BN',
    name: 'Brunei Darussalam',
    name_official: 'Brunei Darussalam',
    latitude: '4.54189364',
    longitude: '114.60132823',
    zoom: 9,
  },
  {
    id: 25,
    enabled: true,
    code3l: 'BGR',
    code: 'BG',
    name: 'Bulgaria',
    name_official: 'Republic of Bulgaria',
    latitude: '42.70160678',
    longitude: '25.48583200',
    zoom: 7,
  },
  {
    id: 26,
    enabled: true,
    code3l: 'BFA',
    code: 'BF',
    name: 'Burkina Faso',
    name_official: 'Burkina Faso',
    latitude: '12.22492458',
    longitude: '-1.56159100',
    zoom: 7,
  },
  {
    id: 27,
    enabled: true,
    code3l: 'BDI',
    code: 'BI',
    name: 'Burundi',
    name_official: 'Republic of Burundi',
    latitude: '-3.40499707',
    longitude: '29.88592902',
    zoom: 8,
  },
  {
    id: 31,
    enabled: true,
    code3l: 'CPV',
    code: 'CV',
    name: 'Cabo Verde',
    name_official: 'Republic of Cabo Verde',
    latitude: '15.11988711',
    longitude: '-23.60517010',
    zoom: 10,
  },
  {
    id: 28,
    enabled: true,
    code3l: 'KHM',
    code: 'KH',
    name: 'Cambodia',
    name_official: 'Kingdom of Cambodia',
    latitude: '12.83288883',
    longitude: '104.84814273',
    zoom: 7,
  },
  {
    id: 29,
    enabled: true,
    code3l: 'CMR',
    code: 'CM',
    name: 'Cameroon',
    name_official: 'Republic of Cameroon',
    latitude: '7.38622543',
    longitude: '12.72825915',
    zoom: 6,
  },
  {
    id: 30,
    enabled: true,
    code3l: 'CAN',
    code: 'CA',
    name: 'Canada',
    name_official: 'Canada',
    latitude: '60.36196817',
    longitude: '-106.69833150',
    zoom: 4,
  },
  {
    id: 222,
    enabled: true,
    code3l: 'CYM',
    code: 'KY',
    name: 'Cayman Islands',
    name_official: 'The Cayman Islands',
    latitude: '19.31322102',
    longitude: '-81.25459800',
    zoom: 11,
  },
  {
    id: 32,
    enabled: true,
    code3l: 'CAF',
    code: 'CF',
    name: 'Central African Republic',
    name_official: 'Central African Republic',
    latitude: '6.82541830',
    longitude: '20.64281514',
    zoom: 6,
  },
  {
    id: 33,
    enabled: true,
    code3l: 'TCD',
    code: 'TD',
    name: 'Chad',
    name_official: 'Republic of Chad',
    latitude: '14.80342407',
    longitude: '18.78714064',
    zoom: 5,
  },
  {
    id: 34,
    enabled: true,
    code3l: 'CHL',
    code: 'CL',
    name: 'Chile',
    name_official: 'Republic of Chile',
    latitude: '-38.01760790',
    longitude: '-71.40014474',
    zoom: 4,
  },
  {
    id: 35,
    enabled: true,
    code3l: 'CHN',
    code: 'CN',
    name: 'China',
    name_official: "People's Republic of China",
    latitude: '36.71457440',
    longitude: '103.55819197',
    zoom: 4,
  },
  {
    id: 206,
    enabled: true,
    code3l: 'CXR',
    code: 'CX',
    name: 'Christmas Island',
    name_official: 'Territory of Christmas Island',
    latitude: '-10.49170619',
    longitude: '105.68083796',
    zoom: 11,
  },
  {
    id: 204,
    enabled: true,
    code3l: 'CCK',
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    name_official: 'Territory of Cocos (Keeling) Islands',
    latitude: '-12.12890685',
    longitude: '96.84689104',
    zoom: 12,
  },
  {
    id: 36,
    enabled: true,
    code3l: 'COL',
    code: 'CO',
    name: 'Colombia',
    name_official: 'Republic of Colombia',
    latitude: '3.68182320',
    longitude: '-73.53927436',
    zoom: 5,
  },
  {
    id: 37,
    enabled: true,
    code3l: 'COM',
    code: 'KM',
    name: 'Comoros',
    name_official: 'Union of the Comoros',
    latitude: '-11.64529989',
    longitude: '43.33330200',
    zoom: 10,
  },
  {
    id: 38,
    enabled: true,
    code3l: 'COG',
    code: 'CG',
    name: 'Congo',
    name_official: 'Republic of the Congo',
    latitude: '-0.68967806',
    longitude: '15.69033190',
    zoom: 6,
  },
  {
    id: 181,
    enabled: true,
    code3l: 'COK',
    code: 'CK',
    name: 'Cook Islands',
    name_official: 'Cook Islands',
    latitude: '-21.23673066',
    longitude: '-159.77766900',
    zoom: 13,
  },
  {
    id: 39,
    enabled: true,
    code3l: 'CRI',
    code: 'CR',
    name: 'Costa Rica',
    name_official: 'Republic of Costa Rica',
    latitude: '9.98427463',
    longitude: '-84.09949534',
    zoom: 8,
  },
  {
    id: 44,
    enabled: true,
    code3l: 'CIV',
    code: 'CI',
    name: "C\u00f4te d'Ivoire",
    name_official: "Republic of C\u00f4te d'Ivoire",
    latitude: '7.59684148',
    longitude: '-5.49214636',
    zoom: 7,
  },
  {
    id: 40,
    enabled: true,
    code3l: 'HRV',
    code: 'HR',
    name: 'Croatia',
    name_official: 'Republic of Croatia',
    latitude: '44.81372482',
    longitude: '16.29039507',
    zoom: 7,
  },
  {
    id: 41,
    enabled: true,
    code3l: 'CUB',
    code: 'CU',
    name: 'Cuba',
    name_official: 'Republic of Cuba',
    latitude: '21.54513189',
    longitude: '-79.00064743',
    zoom: 6,
  },
  {
    id: 205,
    enabled: true,
    code3l: 'CUW',
    code: 'CW',
    name: 'Cura\u00e7ao',
    name_official: 'Cura\u00e7ao',
    latitude: '12.20710309',
    longitude: '-69.02160369',
    zoom: 11,
  },
  {
    id: 42,
    enabled: true,
    code3l: 'CYP',
    code: 'CY',
    name: 'Cyprus',
    name_official: 'Republic of Cyprus',
    latitude: '35.12450768',
    longitude: '33.42986100',
    zoom: 9,
  },
  {
    id: 43,
    enabled: true,
    code3l: 'CZE',
    code: 'CZ',
    name: 'Czechia',
    name_official: 'Czech Republic',
    latitude: '49.76026136',
    longitude: '15.53888197',
    zoom: 7,
  },
  {
    id: 188,
    enabled: true,
    code3l: 'PRK',
    code: 'KP',
    name: "Democratic People's Republic of Korea",
    name_official: "Democratic People's Republic of Korea",
    latitude: '40.00785500',
    longitude: '127.48812834',
    zoom: 6,
  },
  {
    id: 183,
    enabled: true,
    code3l: 'COD',
    code: 'CD',
    name: 'Democratic Republic of the Congo',
    name_official: 'Democratic Republic of Congo',
    latitude: '-4.05373938',
    longitude: '23.01110741',
    zoom: 5,
  },
  {
    id: 45,
    enabled: true,
    code3l: 'DNK',
    code: 'DK',
    name: 'Denmark',
    name_official: 'Kingdom of Denmark',
    latitude: '54.71794021',
    longitude: '9.41938953',
    zoom: 6,
  },
  {
    id: 46,
    enabled: true,
    code3l: 'DJI',
    code: 'DJ',
    name: 'Djibouti',
    name_official: 'Republic of Djibouti',
    latitude: '11.75959257',
    longitude: '42.65344839',
    zoom: 8,
  },
  {
    id: 47,
    enabled: true,
    code3l: 'DMA',
    code: 'DM',
    name: 'Dominica',
    name_official: 'Commonwealth of Dominica',
    latitude: '15.41473963',
    longitude: '-61.37097400',
    zoom: 10,
  },
  {
    id: 48,
    enabled: true,
    code3l: 'DOM',
    code: 'DO',
    name: 'Dominican Republic',
    name_official: 'Dominican Republic',
    latitude: '18.73076761',
    longitude: '-70.16264900',
    zoom: 8,
  },
  {
    id: 49,
    enabled: true,
    code3l: 'ECU',
    code: 'EC',
    name: 'Ecuador',
    name_official: 'Republic of Ecuador',
    latitude: '-1.22919037',
    longitude: '-78.55693916',
    zoom: 6,
  },
  {
    id: 50,
    enabled: true,
    code3l: 'EGY',
    code: 'EG',
    name: 'Egypt',
    name_official: 'Arab Republic of Egypt',
    latitude: '26.71650873',
    longitude: '30.80250000',
    zoom: 6,
  },
  {
    id: 51,
    enabled: true,
    code3l: 'SLV',
    code: 'SV',
    name: 'El Salvador',
    name_official: 'Republic of El Salvador',
    latitude: '13.79043561',
    longitude: '-88.89652800',
    zoom: 8,
  },
  {
    id: 52,
    enabled: true,
    code3l: 'GNQ',
    code: 'GQ',
    name: 'Equatorial Guinea',
    name_official: 'Republic of Equatorial Guinea',
    latitude: '1.65068442',
    longitude: '10.26789700',
    zoom: 9,
  },
  {
    id: 53,
    enabled: true,
    code3l: 'ERI',
    code: 'ER',
    name: 'Eritrea',
    name_official: 'State of Eritrea',
    latitude: '15.21227764',
    longitude: '39.61204792',
    zoom: 7,
  },
  {
    id: 54,
    enabled: true,
    code3l: 'EST',
    code: 'EE',
    name: 'Estonia',
    name_official: 'Republic of Estonia',
    latitude: '58.74041141',
    longitude: '25.38165099',
    zoom: 7,
  },
  {
    id: 55,
    enabled: true,
    code3l: 'ETH',
    code: 'ET',
    name: 'Ethiopia',
    name_official: 'Federal Democratic Republic of Ethiopia',
    latitude: '9.10727589',
    longitude: '39.84148164',
    zoom: 6,
  },
  {
    id: 184,
    enabled: true,
    code3l: 'EUR',
    code: 'EU',
    name: 'European Union',
    name_official: 'European Union',
    latitude: '48.76380654',
    longitude: '14.26843140',
    zoom: 3,
  },
  {
    id: 208,
    enabled: true,
    code3l: 'FLK',
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
    name_official: 'Falkland Islands',
    latitude: '-51.78838251',
    longitude: '-59.52361100',
    zoom: 8,
  },
  {
    id: 209,
    enabled: true,
    code3l: 'FRO',
    code: 'FO',
    name: 'Faroe Islands (Associate Member)',
    name_official: 'Faroe Islands',
    latitude: '61.88590482',
    longitude: '-6.91180400',
    zoom: 8,
  },
  {
    id: 56,
    enabled: true,
    code3l: 'FJI',
    code: 'FJ',
    name: 'Fiji',
    name_official: 'Republic of Fiji',
    latitude: '-17.71219757',
    longitude: '178.06503600',
    zoom: 9,
  },
  {
    id: 57,
    enabled: true,
    code3l: 'FIN',
    code: 'FI',
    name: 'Finland',
    name_official: 'Republic of Finland',
    latitude: '64.69610892',
    longitude: '26.36339137',
    zoom: 5,
  },
  {
    id: 58,
    enabled: true,
    code3l: 'FRA',
    code: 'FR',
    name: 'France',
    name_official: 'French Republic',
    latitude: '46.48372145',
    longitude: '2.60926281',
    zoom: 6,
  },
  {
    id: 210,
    enabled: true,
    code3l: 'GUF',
    code: 'GF',
    name: 'French Guiana',
    name_official: 'French Guiana',
    latitude: '4.01114381',
    longitude: '-52.97746057',
    zoom: 7,
  },
  {
    id: 230,
    enabled: true,
    code3l: 'PYF',
    code: 'PF',
    name: 'French Polynesia',
    name_official: 'Territory of French Polynesia',
    latitude: '-17.66243898',
    longitude: '-149.40683900',
    zoom: 10,
  },
  {
    id: 240,
    enabled: true,
    code3l: 'ATF',
    code: 'TF',
    name: 'French Southern and Antarctic Lands',
    name_official: 'Territory of the French Southern and Antarctic Lands',
    latitude: '-49.27235903',
    longitude: '69.34856300',
    zoom: 8,
  },
  {
    id: 59,
    enabled: true,
    code3l: 'GAB',
    code: 'GA',
    name: 'Gabon',
    name_official: 'Gabonese Republic',
    latitude: '-0.43426435',
    longitude: '11.43916591',
    zoom: 7,
  },
  {
    id: 60,
    enabled: true,
    code3l: 'GMB',
    code: 'GM',
    name: 'Gambia',
    name_official: 'Islamic Republic of the Gambia',
    latitude: '13.15921146',
    longitude: '-15.35956748',
    zoom: 8,
  },
  {
    id: 61,
    enabled: true,
    code3l: 'GEO',
    code: 'GE',
    name: 'Georgia',
    name_official: 'Georgia',
    latitude: '41.82754301',
    longitude: '44.17329916',
    zoom: 7,
  },
  {
    id: 62,
    enabled: true,
    code3l: 'DEU',
    code: 'DE',
    name: 'Germany',
    name_official: 'Federal Republic of Germany',
    latitude: '50.82871201',
    longitude: '10.97887975',
    zoom: 6,
  },
  {
    id: 63,
    enabled: true,
    code3l: 'GHA',
    code: 'GH',
    name: 'Ghana',
    name_official: 'Republic of Ghana',
    latitude: '7.69154199',
    longitude: '-1.29234904',
    zoom: 7,
  },
  {
    id: 212,
    enabled: true,
    code3l: 'GIB',
    code: 'GI',
    name: 'Gibraltar',
    name_official: 'Gibraltar',
    latitude: '36.14864641',
    longitude: '-5.34404779',
    zoom: 12,
  },
  {
    id: 64,
    enabled: true,
    code3l: 'GRC',
    code: 'GR',
    name: 'Greece',
    name_official: 'Hellenic Republic',
    latitude: '38.52254746',
    longitude: '24.53794505',
    zoom: 6,
  },
  {
    id: 213,
    enabled: true,
    code3l: 'GRL',
    code: 'GL',
    name: 'Greenland',
    name_official: 'Greenland',
    latitude: '71.42932629',
    longitude: '-34.38651956',
    zoom: 3,
  },
  {
    id: 65,
    enabled: true,
    code3l: 'GRD',
    code: 'GD',
    name: 'Grenada',
    name_official: 'Grenada',
    latitude: '12.11644807',
    longitude: '-61.67899400',
    zoom: 11,
  },
  {
    id: 214,
    enabled: true,
    code3l: 'GLP',
    code: 'GP',
    name: 'Guadeloupe',
    name_official: 'Department of Guadeloupe',
    latitude: '16.26472785',
    longitude: '-61.55099400',
    zoom: 10,
  },
  {
    id: 216,
    enabled: true,
    code3l: 'GUM',
    code: 'GU',
    name: 'Guam',
    name_official: 'Territory of Guam',
    latitude: '13.44410137',
    longitude: '144.80747791',
    zoom: 10,
  },
  {
    id: 66,
    enabled: true,
    code3l: 'GTM',
    code: 'GT',
    name: 'Guatemala',
    name_official: 'Republic of Guatemala',
    latitude: '15.72598421',
    longitude: '-89.96707712',
    zoom: 7,
  },
  {
    id: 211,
    enabled: true,
    code3l: 'GGY',
    code: 'GG',
    name: 'Guernsey',
    name_official: 'Bailiwick of Guernsey',
    latitude: '49.46565975',
    longitude: '-2.58527200',
    zoom: 12,
  },
  {
    id: 67,
    enabled: true,
    code3l: 'GIN',
    code: 'GN',
    name: 'Guinea',
    name_official: 'Republic of Guinea',
    latitude: '9.94301472',
    longitude: '-11.31711839',
    zoom: 7,
  },
  {
    id: 68,
    enabled: true,
    code3l: 'GNB',
    code: 'GW',
    name: 'Guinea-Bissau',
    name_official: 'Republic of Guinea-Bissau',
    latitude: '11.80050682',
    longitude: '-15.18040700',
    zoom: 8,
  },
  {
    id: 69,
    enabled: true,
    code3l: 'GUY',
    code: 'GY',
    name: 'Guyana',
    name_official: 'Republic of Guyana',
    latitude: '4.47957059',
    longitude: '-58.72692293',
    zoom: 6,
  },
  {
    id: 70,
    enabled: true,
    code3l: 'HTI',
    code: 'HT',
    name: 'Haiti',
    name_official: 'Republic of Haiti',
    latitude: '19.07430861',
    longitude: '-72.79607526',
    zoom: 8,
  },
  {
    id: 218,
    enabled: true,
    code3l: 'HMD',
    code: 'HM',
    name: 'Heard Island And McDonald Islands',
    name_official: 'Heard and McDonald Islands',
    latitude: '-53.08168847',
    longitude: '73.50415800',
    zoom: 11,
  },
  {
    id: 245,
    enabled: true,
    code3l: 'VAT',
    code: 'VA',
    name: 'Holy See',
    name_official: 'Holy see',
    latitude: '41.90377810',
    longitude: '12.45340142',
    zoom: 16,
  },
  {
    id: 71,
    enabled: true,
    code3l: 'HND',
    code: 'HN',
    name: 'Honduras',
    name_official: 'Republic of Honduras',
    latitude: '14.64994423',
    longitude: '-87.01643713',
    zoom: 7,
  },
  {
    id: 217,
    enabled: true,
    code3l: 'HKG',
    code: 'HK',
    name: 'Hong Kong',
    name_official:
      "Hong Kong Special Administrative Region of the People's Republic",
    latitude: '22.33728531',
    longitude: '114.14657786',
    zoom: 11,
  },
  {
    id: 72,
    enabled: true,
    code3l: 'HUN',
    code: 'HU',
    name: 'Hungary',
    name_official: 'Hungary',
    latitude: '46.97670384',
    longitude: '19.35499657',
    zoom: 7,
  },
  {
    id: 73,
    enabled: true,
    code3l: 'ISL',
    code: 'IS',
    name: 'Iceland',
    name_official: 'Republic of Iceland',
    latitude: '64.99294495',
    longitude: '-18.57038755',
    zoom: 6,
  },
  {
    id: 74,
    enabled: true,
    code3l: 'IND',
    code: 'IN',
    name: 'India',
    name_official: 'Republic of India',
    latitude: '20.46549519',
    longitude: '78.50146222',
    zoom: 4,
  },
  {
    id: 75,
    enabled: true,
    code3l: 'IDN',
    code: 'ID',
    name: 'Indonesia',
    name_official: 'Republic of Indonesia',
    latitude: '-2.46229680',
    longitude: '121.18329789',
    zoom: 4,
  },
  {
    id: 187,
    enabled: true,
    code3l: 'IRN',
    code: 'IR',
    name: 'Iran (Islamic Republic of)',
    name_official: 'Islamic Republic of Iran',
    latitude: '31.40240324',
    longitude: '51.28204814',
    zoom: 5,
  },
  {
    id: 76,
    enabled: true,
    code3l: 'IRQ',
    code: 'IQ',
    name: 'Iraq',
    name_official: 'Republic of Iraq',
    latitude: '32.90170182',
    longitude: '43.19590056',
    zoom: 6,
  },
  {
    id: 77,
    enabled: true,
    code3l: 'IRL',
    code: 'IE',
    name: 'Ireland',
    name_official: 'Ireland',
    latitude: '53.10101628',
    longitude: '-8.21092302',
    zoom: 6,
  },
  {
    id: 219,
    enabled: true,
    code3l: 'IMN',
    code: 'IM',
    name: 'Isle of Man',
    name_official: 'The Isle of Man',
    latitude: '54.23562697',
    longitude: '-4.54805400',
    zoom: 10,
  },
  {
    id: 78,
    enabled: true,
    code3l: 'ISR',
    code: 'IL',
    name: 'Israel',
    name_official: 'State of Israel',
    latitude: '30.85883075',
    longitude: '34.91753797',
    zoom: 7,
  },
  {
    id: 79,
    enabled: true,
    code3l: 'ITA',
    code: 'IT',
    name: 'Italy',
    name_official: 'Republic of Italy',
    latitude: '41.77810840',
    longitude: '12.67725128',
    zoom: 5,
  },
  {
    id: 80,
    enabled: true,
    code3l: 'JAM',
    code: 'JM',
    name: 'Jamaica',
    name_official: 'Jamaica',
    latitude: '18.10838487',
    longitude: '-77.29750600',
    zoom: 9,
  },
  {
    id: 81,
    enabled: true,
    code3l: 'JPN',
    code: 'JP',
    name: 'Japan',
    name_official: 'Japan',
    latitude: '37.51848822',
    longitude: '137.67066061',
    zoom: 5,
  },
  {
    id: 221,
    enabled: true,
    code3l: 'JEY',
    code: 'JE',
    name: 'Jersey',
    name_official: 'Bailiwick of Jersey',
    latitude: '49.21440771',
    longitude: '-2.13124600',
    zoom: 12,
  },
  {
    id: 82,
    enabled: true,
    code3l: 'JOR',
    code: 'JO',
    name: 'Jordan',
    name_official: 'Hashemite Kingdom of Jordan',
    latitude: '31.31616588',
    longitude: '36.37575510',
    zoom: 7,
  },
  {
    id: 83,
    enabled: true,
    code3l: 'KAZ',
    code: 'KZ',
    name: 'Kazakhstan',
    name_official: 'Republic of Kazakhstan',
    latitude: '45.38592596',
    longitude: '68.81334444',
    zoom: 4,
  },
  {
    id: 84,
    enabled: true,
    code3l: 'KEN',
    code: 'KE',
    name: 'Kenya',
    name_official: 'Republic of Kenya',
    latitude: '0.19582452',
    longitude: '37.97212297',
    zoom: 6,
  },
  {
    id: 85,
    enabled: true,
    code3l: 'KIR',
    code: 'KI',
    name: 'Kiribati',
    name_official: 'Republic of Kiribati',
    latitude: '1.87085244',
    longitude: '-157.36259310',
    zoom: 10,
  },
  {
    id: 86,
    enabled: true,
    code3l: 'KWT',
    code: 'KW',
    name: 'Kuwait',
    name_official: 'State of Kuwait',
    latitude: '29.43253341',
    longitude: '47.71798405',
    zoom: 8,
  },
  {
    id: 87,
    enabled: true,
    code3l: 'KGZ',
    code: 'KG',
    name: 'Kyrgyzstan',
    name_official: 'Kyrgyz Republic',
    latitude: '41.11509878',
    longitude: '74.25524574',
    zoom: 6,
  },
  {
    id: 88,
    enabled: true,
    code3l: 'LAO',
    code: 'LA',
    name: "Lao People's Democratic Republic",
    name_official: "Lao People's Democratic Republic",
    latitude: '17.76075593',
    longitude: '103.61611347',
    zoom: 6,
  },
  {
    id: 89,
    enabled: true,
    code3l: 'LVA',
    code: 'LV',
    name: 'Latvia',
    name_official: 'Republic of Latvia',
    latitude: '56.86697515',
    longitude: '24.54826936',
    zoom: 7,
  },
  {
    id: 90,
    enabled: true,
    code3l: 'LBN',
    code: 'LB',
    name: 'Lebanon',
    name_official: 'Lebanese Republic',
    latitude: '34.08249284',
    longitude: '35.66454309',
    zoom: 8,
  },
  {
    id: 91,
    enabled: true,
    code3l: 'LSO',
    code: 'LS',
    name: 'Lesotho',
    name_official: 'Kingdom of Lesotho',
    latitude: '-29.60303205',
    longitude: '28.23361200',
    zoom: 8,
  },
  {
    id: 92,
    enabled: true,
    code3l: 'LBR',
    code: 'LR',
    name: 'Liberia',
    name_official: 'Republic of Liberia',
    latitude: '6.44154681',
    longitude: '-9.39103485',
    zoom: 7,
  },
  {
    id: 93,
    enabled: true,
    code3l: 'LBY',
    code: 'LY',
    name: 'Libya',
    name_official: 'Libya',
    latitude: '27.06902914',
    longitude: '18.19513987',
    zoom: 5,
  },
  {
    id: 94,
    enabled: true,
    code3l: 'LIE',
    code: 'LI',
    name: 'Liechtenstein',
    name_official: 'Principality of Liechtenstein',
    latitude: '47.16587383',
    longitude: '9.55537700',
    zoom: 11,
  },
  {
    id: 95,
    enabled: true,
    code3l: 'LTU',
    code: 'LT',
    name: 'Lithuania',
    name_official: 'Republic of Lithuania',
    latitude: '55.25095948',
    longitude: '23.80987587',
    zoom: 7,
  },
  {
    id: 96,
    enabled: true,
    code3l: 'LUX',
    code: 'LU',
    name: 'Luxembourg',
    name_official: 'Grand Duchy of Luxembourg',
    latitude: '49.81327712',
    longitude: '6.12958700',
    zoom: 9,
  },
  {
    id: 224,
    enabled: true,
    code3l: 'MAC',
    code: 'MO',
    name: 'Macao',
    name_official: 'Macau Special Administrative Region',
    latitude: '22.19872287',
    longitude: '113.54387700',
    zoom: 12,
  },
  {
    id: 97,
    enabled: true,
    code3l: 'MDG',
    code: 'MG',
    name: 'Madagascar',
    name_official: 'Republic of Madagascar',
    latitude: '-19.79858543',
    longitude: '46.97898228',
    zoom: 5,
  },
  {
    id: 98,
    enabled: true,
    code3l: 'MWI',
    code: 'MW',
    name: 'Malawi',
    name_official: 'Republic of Malawi',
    latitude: '-12.48684092',
    longitude: '34.14223524',
    zoom: 6,
  },
  {
    id: 99,
    enabled: true,
    code3l: 'MYS',
    code: 'MY',
    name: 'Malaysia',
    name_official: 'Malaysia',
    latitude: '4.97345793',
    longitude: '106.54609050',
    zoom: 5,
  },
  {
    id: 100,
    enabled: true,
    code3l: 'MDV',
    code: 'MV',
    name: 'Maldives',
    name_official: 'Republic of Maldives',
    latitude: '-0.64224221',
    longitude: '73.13373313',
    zoom: 12,
  },
  {
    id: 101,
    enabled: true,
    code3l: 'MLI',
    code: 'ML',
    name: 'Mali',
    name_official: 'Republic of Mali',
    latitude: '17.69385811',
    longitude: '-1.96368730',
    zoom: 5,
  },
  {
    id: 102,
    enabled: true,
    code3l: 'MLT',
    code: 'MT',
    name: 'Malta',
    name_official: 'Republic of Malta',
    latitude: '35.89706403',
    longitude: '14.43687877',
    zoom: 11,
  },
  {
    id: 103,
    enabled: true,
    code3l: 'MHL',
    code: 'MH',
    name: 'Marshall Islands',
    name_official: 'Republic of the Marshall Islands',
    latitude: '7.30130732',
    longitude: '168.75512619',
    zoom: 10,
  },
  {
    id: 226,
    enabled: true,
    code3l: 'MTQ',
    code: 'MQ',
    name: 'Martinique',
    name_official: 'Department of Martinique',
    latitude: '14.64128045',
    longitude: '-61.02417600',
    zoom: 10,
  },
  {
    id: 104,
    enabled: true,
    code3l: 'MRT',
    code: 'MR',
    name: 'Mauritania',
    name_official: 'Islamic Republic of Mauritania',
    latitude: '20.28331239',
    longitude: '-10.21573334',
    zoom: 5,
  },
  {
    id: 105,
    enabled: true,
    code3l: 'MUS',
    code: 'MU',
    name: 'Mauritius',
    name_official: 'Republic of Mauritius',
    latitude: '-20.28368188',
    longitude: '57.56588291',
    zoom: 10,
  },
  {
    id: 249,
    enabled: true,
    code3l: 'MYT',
    code: 'YT',
    name: 'Mayotte',
    name_official: 'Overseas Department of Mayotte',
    latitude: '-12.82744522',
    longitude: '45.16624200',
    zoom: 11,
  },
  {
    id: 106,
    enabled: true,
    code3l: 'MEX',
    code: 'MX',
    name: 'Mexico',
    name_official: 'United Mexican States',
    latitude: '22.92036676',
    longitude: '-102.33305344',
    zoom: 5,
  },
  {
    id: 185,
    enabled: true,
    code3l: 'FSM',
    code: 'FM',
    name: 'Micronesia (Federated States of)',
    name_official: 'Federated States of Micronesia',
    latitude: '6.88747377',
    longitude: '158.21507170',
    zoom: 12,
  },
  {
    id: 107,
    enabled: true,
    code3l: 'MCO',
    code: 'MC',
    name: 'Monaco',
    name_official: 'Principality of Monaco',
    latitude: '43.70463620',
    longitude: '6.75444978',
    zoom: 9,
  },
  {
    id: 108,
    enabled: true,
    code3l: 'MNG',
    code: 'MN',
    name: 'Mongolia',
    name_official: 'Mongolia',
    latitude: '46.80556270',
    longitude: '104.30808978',
    zoom: 5,
  },
  {
    id: 109,
    enabled: true,
    code3l: 'MNE',
    code: 'ME',
    name: 'Montenegro',
    name_official: 'Montenegro',
    latitude: '42.71699590',
    longitude: '19.09699321',
    zoom: 8,
  },
  {
    id: 227,
    enabled: true,
    code3l: 'MSR',
    code: 'MS',
    name: 'Montserrat',
    name_official: 'Montserrat',
    latitude: '16.74774077',
    longitude: '-62.18736600',
    zoom: 12,
  },
  {
    id: 110,
    enabled: true,
    code3l: 'MAR',
    code: 'MA',
    name: 'Morocco',
    name_official: 'Kingdom of Morocco',
    latitude: '31.95441758',
    longitude: '-7.26839325',
    zoom: 6,
  },
  {
    id: 111,
    enabled: true,
    code3l: 'MOZ',
    code: 'MZ',
    name: 'Mozambique',
    name_official: 'Republic of Mozambique',
    latitude: '-19.07617816',
    longitude: '33.81570282',
    zoom: 5,
  },
  {
    id: 112,
    enabled: true,
    code3l: 'MMR',
    code: 'MM',
    name: 'Myanmar',
    name_official: 'Republic of Union of Myanmar',
    latitude: '19.20985380',
    longitude: '96.54949272',
    zoom: 5,
  },
  {
    id: 113,
    enabled: true,
    code3l: 'NAM',
    code: 'NA',
    name: 'Namibia',
    name_official: 'Republic of Namibia',
    latitude: '-22.70965620',
    longitude: '16.72161918',
    zoom: 6,
  },
  {
    id: 114,
    enabled: true,
    code3l: 'NRU',
    code: 'NR',
    name: 'Nauru',
    name_official: 'Republic of Nauru',
    latitude: '-0.52586763',
    longitude: '166.93270463',
    zoom: 13,
  },
  {
    id: 115,
    enabled: true,
    code3l: 'NPL',
    code: 'NP',
    name: 'Nepal',
    name_official: 'Federal Democratic Republic of Nepal',
    latitude: '28.28430770',
    longitude: '83.98119373',
    zoom: 7,
  },
  {
    id: 116,
    enabled: true,
    code3l: 'NLD',
    code: 'NL',
    name: 'Netherlands',
    name_official: 'Kingdom of Netherlands',
    latitude: '52.33939951',
    longitude: '4.98914998',
    zoom: 7,
  },
  {
    id: 228,
    enabled: true,
    code3l: 'NCL',
    code: 'NC',
    name: 'New Caledonia',
    name_official: 'Territory of New Caledonia and Dependencies',
    latitude: '-21.26104020',
    longitude: '165.58783760',
    zoom: 8,
  },
  {
    id: 117,
    enabled: true,
    code3l: 'NZL',
    code: 'NZ',
    name: 'New Zealand',
    name_official: 'New Zealand',
    latitude: '-40.95025298',
    longitude: '171.76586181',
    zoom: 5,
  },
  {
    id: 118,
    enabled: true,
    code3l: 'NIC',
    code: 'NI',
    name: 'Nicaragua',
    name_official: 'Republic of Nicaragua',
    latitude: '12.91806226',
    longitude: '-84.82270352',
    zoom: 7,
  },
  {
    id: 119,
    enabled: true,
    code3l: 'NER',
    code: 'NE',
    name: 'Niger',
    name_official: 'Republic of Niger',
    latitude: '17.23446679',
    longitude: '8.23547860',
    zoom: 6,
  },
  {
    id: 120,
    enabled: true,
    code3l: 'NGA',
    code: 'NG',
    name: 'Nigeria',
    name_official: 'Federal Republic of Nigeria',
    latitude: '9.02165273',
    longitude: '7.82933373',
    zoom: 6,
  },
  {
    id: 192,
    enabled: true,
    code3l: 'NIU',
    code: 'NU',
    name: 'Niue',
    name_official: 'Niue',
    latitude: '-19.04976362',
    longitude: '-169.86585571',
    zoom: 11,
  },
  {
    id: 229,
    enabled: true,
    code3l: 'NFK',
    code: 'NF',
    name: 'Norfolk Island',
    name_official: 'Norfolk Islands',
    latitude: '-29.02801043',
    longitude: '167.94303023',
    zoom: 13,
  },
  {
    id: 225,
    enabled: true,
    code3l: 'MNP',
    code: 'MP',
    name: 'Northern Mariana Islands',
    name_official: 'Commonwealth of the Northern Mariana Islands',
    latitude: '15.09783636',
    longitude: '145.67390000',
    zoom: 11,
  },
  {
    id: 121,
    enabled: true,
    code3l: 'NOR',
    code: 'NO',
    name: 'Norway',
    name_official: 'Kingdom of Norway',
    latitude: '65.04680297',
    longitude: '13.50069228',
    zoom: 4,
  },
  {
    id: 122,
    enabled: true,
    code3l: 'OMN',
    code: 'OM',
    name: 'Oman',
    name_official: 'Sultanate of Oman',
    latitude: '20.69906846',
    longitude: '56.69230596',
    zoom: 6,
  },
  {
    id: 123,
    enabled: true,
    code3l: 'PAK',
    code: 'PK',
    name: 'Pakistan',
    name_official: 'Islamic Republic of Pakistan',
    latitude: '29.90335974',
    longitude: '70.34487986',
    zoom: 5,
  },
  {
    id: 124,
    enabled: true,
    code3l: 'PLW',
    code: 'PW',
    name: 'Palau',
    name_official: 'Republic of Palau',
    latitude: '7.49856307',
    longitude: '134.57291496',
    zoom: 10,
  },
  {
    id: 234,
    enabled: true,
    code3l: 'PSE',
    code: 'PS',
    name: 'Palestinian Territory, Occupied',
    name_official: 'Occupied Palestinian Territory',
    latitude: '32.26367103',
    longitude: '35.21936714',
    zoom: 8,
  },
  {
    id: 125,
    enabled: true,
    code3l: 'PAN',
    code: 'PA',
    name: 'Panama',
    name_official: 'Republic of Panama',
    latitude: '8.52135102',
    longitude: '-80.04603702',
    zoom: 7,
  },
  {
    id: 126,
    enabled: true,
    code3l: 'PNG',
    code: 'PG',
    name: 'Papua New Guinea',
    name_official: 'Independent State of Papua New Guinea',
    latitude: '-6.62414046',
    longitude: '144.44993477',
    zoom: 7,
  },
  {
    id: 127,
    enabled: true,
    code3l: 'PRY',
    code: 'PY',
    name: 'Paraguay',
    name_official: 'Republic of Paraguay',
    latitude: '-23.38564782',
    longitude: '-58.29551057',
    zoom: 6,
  },
  {
    id: 128,
    enabled: true,
    code3l: 'PER',
    code: 'PE',
    name: 'Peru',
    name_official: 'Republic of Peru',
    latitude: '-8.50205247',
    longitude: '-76.15772412',
    zoom: 5,
  },
  {
    id: 129,
    enabled: true,
    code3l: 'PHL',
    code: 'PH',
    name: 'Philippines',
    name_official: 'Republic of Philippines',
    latitude: '12.82361200',
    longitude: '121.77401700',
    zoom: 6,
  },
  {
    id: 232,
    enabled: true,
    code3l: 'PCN',
    code: 'PN',
    name: 'Pitcairn Islands',
    name_official: 'Pitcairn Group of Islands',
    latitude: '-24.37673925',
    longitude: '-128.32423730',
    zoom: 13,
  },
  {
    id: 130,
    enabled: true,
    code3l: 'POL',
    code: 'PL',
    name: 'Poland',
    name_official: 'Republic of Poland',
    latitude: '52.10117636',
    longitude: '19.33190957',
    zoom: 6,
  },
  {
    id: 131,
    enabled: true,
    code3l: 'PRT',
    code: 'PT',
    name: 'Portugal',
    name_official: 'Portuguese Republic',
    latitude: '39.44879136',
    longitude: '-8.03768042',
    zoom: 6,
  },
  {
    id: 233,
    enabled: true,
    code3l: 'PRI',
    code: 'PR',
    name: 'Puerto Rico',
    name_official: 'Commonwealth of Puerto Rico',
    latitude: '18.21963053',
    longitude: '-66.59015100',
    zoom: 9,
  },
  {
    id: 132,
    enabled: true,
    code3l: 'QAT',
    code: 'QA',
    name: 'Qatar',
    name_official: 'State of Qatar',
    latitude: '25.24551555',
    longitude: '51.24431480',
    zoom: 8,
  },
  {
    id: 189,
    enabled: true,
    code3l: 'KOR',
    code: 'KR',
    name: 'Republic of Korea',
    name_official: 'Republic of Korea',
    latitude: '36.56344139',
    longitude: '127.51424646',
    zoom: 7,
  },
  {
    id: 190,
    enabled: true,
    code3l: 'MDA',
    code: 'MD',
    name: 'Republic of Moldova',
    name_official: 'Republic of Moldova',
    latitude: '47.10710437',
    longitude: '28.54018109',
    zoom: 7,
  },
  {
    id: 235,
    enabled: true,
    code3l: 'REU',
    code: 'RE',
    name: 'R\u00e9union',
    name_official: 'Department of Reunion',
    latitude: '-21.11480084',
    longitude: '55.53638200',
    zoom: 10,
  },
  {
    id: 133,
    enabled: true,
    code3l: 'ROU',
    code: 'RO',
    name: 'Romania',
    name_official: 'Romania',
    latitude: '45.56450023',
    longitude: '25.21945155',
    zoom: 6,
  },
  {
    id: 134,
    enabled: true,
    code3l: 'RUS',
    code: 'RU',
    name: 'Russian Federation',
    name_official: 'Russian Federation',
    latitude: '57.96812298',
    longitude: '102.41837137',
    zoom: 3,
  },
  {
    id: 135,
    enabled: true,
    code3l: 'RWA',
    code: 'RW',
    name: 'Rwanda',
    name_official: 'Republic of Rwanda',
    latitude: '-1.98589079',
    longitude: '29.94255855',
    zoom: 8,
  },
  {
    id: 200,
    enabled: true,
    code3l: 'BLM',
    code: 'BL',
    name: 'Saint Barth\u00e9lemy',
    name_official: 'Territorial collectivity of Saint Barth\u00e9lemy',
    latitude: '17.90042417',
    longitude: '-62.83376215',
    zoom: 13,
  },
  {
    id: 236,
    enabled: true,
    code3l: 'SHN',
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    name_official: 'Saint Helena, Ascension and Tristan da Cunha',
    latitude: '-37.10521846',
    longitude: '-12.27768580',
    zoom: 12,
  },
  {
    id: 136,
    enabled: true,
    code3l: 'KNA',
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    name_official: 'Saint Kitts and Nevis',
    latitude: '17.33453669',
    longitude: '-62.76411725',
    zoom: 12,
  },
  {
    id: 137,
    enabled: true,
    code3l: 'LCA',
    code: 'LC',
    name: 'Saint Lucia',
    name_official: 'Saint Lucia',
    latitude: '13.90938495',
    longitude: '-60.97889500',
    zoom: 11,
  },
  {
    id: 223,
    enabled: true,
    code3l: 'MAF',
    code: 'MF',
    name: 'Saint Martin',
    name_official: 'Saint Martin',
    latitude: '18.07637107',
    longitude: '-63.05019106',
    zoom: 12,
  },
  {
    id: 231,
    enabled: true,
    code3l: 'SPM',
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    name_official: 'Saint Pierre and Miquelon',
    latitude: '46.88469499',
    longitude: '-56.31590200',
    zoom: 10,
  },
  {
    id: 138,
    enabled: true,
    code3l: 'VCT',
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    name_official: 'Saint Vincent and the Grenadines',
    latitude: '13.25276143',
    longitude: '-61.19709800',
    zoom: 11,
  },
  {
    id: 139,
    enabled: true,
    code3l: 'WSM',
    code: 'WS',
    name: 'Samoa',
    name_official: 'Independent State of Samoa',
    latitude: '-13.57998954',
    longitude: '-172.45207363',
    zoom: 10,
  },
  {
    id: 140,
    enabled: true,
    code3l: 'SMR',
    code: 'SM',
    name: 'San Marino',
    name_official: 'Republic of San Marino',
    latitude: '43.94223356',
    longitude: '12.45777700',
    zoom: 11,
  },
  {
    id: 141,
    enabled: true,
    code3l: 'STP',
    code: 'ST',
    name: 'Sao Tome and Principe',
    name_official: 'Democratic Republic of Sao Tome and Principe',
    latitude: '0.23381910',
    longitude: '6.59935809',
    zoom: 10,
  },
  {
    id: 142,
    enabled: true,
    code3l: 'SAU',
    code: 'SA',
    name: 'Saudi Arabia',
    name_official: 'Kingdom of Saudi Arabia',
    latitude: '24.16687314',
    longitude: '42.88190638',
    zoom: 5,
  },
  {
    id: 143,
    enabled: true,
    code3l: 'SEN',
    code: 'SN',
    name: 'Senegal',
    name_official: 'Republic of Senegal',
    latitude: '14.43579003',
    longitude: '-14.68306489',
    zoom: 7,
  },
  {
    id: 144,
    enabled: true,
    code3l: 'SRB',
    code: 'RS',
    name: 'Serbia',
    name_official: 'Republic of Serbia',
    latitude: '44.06736041',
    longitude: '20.29725084',
    zoom: 7,
  },
  {
    id: 145,
    enabled: true,
    code3l: 'SYC',
    code: 'SC',
    name: 'Seychelles',
    name_official: 'Republic of Seychelles',
    latitude: '-4.68053204',
    longitude: '55.49061371',
    zoom: 11,
  },
  {
    id: 146,
    enabled: true,
    code3l: 'SLE',
    code: 'SL',
    name: 'Sierra Leone',
    name_official: 'Republic of Sierra Leone',
    latitude: '8.45575589',
    longitude: '-11.93368759',
    zoom: 8,
  },
  {
    id: 147,
    enabled: true,
    code3l: 'SGP',
    code: 'SG',
    name: 'Singapore',
    name_official: 'Republic of Singapore',
    latitude: '1.33873261',
    longitude: '103.83323559',
    zoom: 11,
  },
  {
    id: 238,
    enabled: true,
    code3l: 'SXM',
    code: 'SX',
    name: 'Sint Maarten',
    name_official: 'Sint Maarten',
    latitude: '18.04433885',
    longitude: '-63.05616320',
    zoom: 12,
  },
  {
    id: 148,
    enabled: true,
    code3l: 'SVK',
    code: 'SK',
    name: 'Slovakia',
    name_official: 'Slovak Republic',
    latitude: '48.66923253',
    longitude: '19.75396564',
    zoom: 7,
  },
  {
    id: 149,
    enabled: true,
    code3l: 'SVN',
    code: 'SI',
    name: 'Slovenia',
    name_official: 'Republic of Slovenia',
    latitude: '46.14315048',
    longitude: '14.99546300',
    zoom: 8,
  },
  {
    id: 150,
    enabled: true,
    code3l: 'SLB',
    code: 'SB',
    name: 'Solomon Islands',
    name_official: 'Solomon Islands',
    latitude: '-9.64554280',
    longitude: '160.15619400',
    zoom: 10,
  },
  {
    id: 151,
    enabled: true,
    code3l: 'SOM',
    code: 'SO',
    name: 'Somalia',
    name_official: 'Federal Republic of Somalia',
    latitude: '2.87224619',
    longitude: '45.27676444',
    zoom: 7,
  },
  {
    id: 152,
    enabled: true,
    code3l: 'ZAF',
    code: 'ZA',
    name: 'South Africa',
    name_official: 'Republic of South Africa',
    latitude: '-27.17706863',
    longitude: '24.50856092',
    zoom: 5,
  },
  {
    id: 215,
    enabled: true,
    code3l: 'SGS',
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    name_official: 'South Georgia and the South Sandwich Islands',
    latitude: '-54.38130284',
    longitude: '-36.67305304',
    zoom: 9,
  },
  {
    id: 250,
    enabled: true,
    code3l: 'SSD',
    code: 'SS',
    name: 'South Sudan',
    name_official: 'Republic of South Sudan',
    latitude: '7.91320803',
    longitude: '30.15342434',
    zoom: 6,
  },
  {
    id: 153,
    enabled: true,
    code3l: 'ESP',
    code: 'ES',
    name: 'Spain',
    name_official: 'Kingdom of Spain',
    latitude: '39.87299401',
    longitude: '-3.67089492',
    zoom: 6,
  },
  {
    id: 154,
    enabled: true,
    code3l: 'LKA',
    code: 'LK',
    name: 'Sri Lanka',
    name_official: 'Democratic Socialist Republic of Sri Lanka',
    latitude: '7.61264985',
    longitude: '80.83772497',
    zoom: 7,
  },
  {
    id: 155,
    enabled: true,
    code3l: 'SDN',
    code: 'SD',
    name: 'Sudan',
    name_official: 'Republic of Sudan',
    latitude: '15.96646839',
    longitude: '30.37145459',
    zoom: 5,
  },
  {
    id: 156,
    enabled: true,
    code3l: 'SUR',
    code: 'SR',
    name: 'Suriname',
    name_official: 'Republic of Suriname',
    latitude: '4.26470865',
    longitude: '-55.93988238',
    zoom: 7,
  },
  {
    id: 237,
    enabled: true,
    code3l: 'SJM',
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
    name_official: 'Svalbard and Jan Mayen',
    latitude: '77.92215764',
    longitude: '18.99010622',
    zoom: 4,
  },
  {
    id: 157,
    enabled: true,
    code3l: 'SWZ',
    code: 'SZ',
    name: 'Swaziland',
    name_official: 'Kingdom of Swaziland',
    latitude: '-26.53892570',
    longitude: '31.47960891',
    zoom: 9,
  },
  {
    id: 158,
    enabled: true,
    code3l: 'SWE',
    code: 'SE',
    name: 'Sweden',
    name_official: 'Kingdom of Sweden',
    latitude: '61.42370427',
    longitude: '16.73188991',
    zoom: 4,
  },
  {
    id: 159,
    enabled: true,
    code3l: 'CHE',
    code: 'CH',
    name: 'Switzerland',
    name_official: 'Swiss Confederation',
    latitude: '46.81010721',
    longitude: '8.22751200',
    zoom: 8,
  },
  {
    id: 160,
    enabled: true,
    code3l: 'SYR',
    code: 'SY',
    name: 'Syrian Arab Republic',
    name_official: 'Syrian Arab Republic',
    latitude: '34.71097430',
    longitude: '38.66723516',
    zoom: 6,
  },
  {
    id: 242,
    enabled: true,
    code3l: 'TWN',
    code: 'TW',
    name: 'Taiwan',
    name_official: 'Republic of China',
    latitude: '23.71891402',
    longitude: '121.10884043',
    zoom: 7,
  },
  {
    id: 161,
    enabled: true,
    code3l: 'TJK',
    code: 'TJ',
    name: 'Tajikistan',
    name_official: 'Republic of Tajikistan',
    latitude: '38.68075124',
    longitude: '71.23215769',
    zoom: 7,
  },
  {
    id: 162,
    enabled: true,
    code3l: 'THA',
    code: 'TH',
    name: 'Thailand',
    name_official: 'Kingdom of Thailand',
    latitude: '14.60009810',
    longitude: '101.38805881',
    zoom: 5,
  },
  {
    id: 191,
    enabled: true,
    code3l: 'MKD',
    code: 'MK',
    name: 'The former Yugoslav Republic of Macedonia',
    name_official: 'The former Yugoslav Republic of Macedonia',
    latitude: '41.60059479',
    longitude: '21.74527900',
    zoom: 8,
  },
  {
    id: 163,
    enabled: true,
    code3l: 'TLS',
    code: 'TL',
    name: 'Timor-Leste',
    name_official: 'Democratic Republic of Timor-Leste',
    latitude: '-8.88926365',
    longitude: '125.99671404',
    zoom: 9,
  },
  {
    id: 164,
    enabled: true,
    code3l: 'TGO',
    code: 'TG',
    name: 'Togo',
    name_official: 'Togolese Republic',
    latitude: '8.68089206',
    longitude: '0.86049757',
    zoom: 7,
  },
  {
    id: 241,
    enabled: true,
    code3l: 'TKL',
    code: 'TK',
    name: 'Tokelau (Associate Member)',
    name_official: 'Tokelau',
    latitude: '-9.16682644',
    longitude: '-171.83981693',
    zoom: 10,
  },
  {
    id: 165,
    enabled: true,
    code3l: 'TON',
    code: 'TO',
    name: 'Tonga',
    name_official: 'Kingdom of Tonga',
    latitude: '-21.17890075',
    longitude: '-175.19824200',
    zoom: 11,
  },
  {
    id: 166,
    enabled: true,
    code3l: 'TTO',
    code: 'TT',
    name: 'Trinidad and Tobago',
    name_official: 'Republic of Trinidad and Tobago',
    latitude: '10.43241863',
    longitude: '-61.22250300',
    zoom: 10,
  },
  {
    id: 167,
    enabled: true,
    code3l: 'TUN',
    code: 'TN',
    name: 'Tunisia',
    name_official: 'Republic of Tunisia',
    latitude: '33.88431940',
    longitude: '9.71878341',
    zoom: 6,
  },
  {
    id: 168,
    enabled: true,
    code3l: 'TUR',
    code: 'TR',
    name: 'Turkey',
    name_official: 'Republic of Turkey',
    latitude: '38.27069555',
    longitude: '36.28703317',
    zoom: 5,
  },
  {
    id: 169,
    enabled: true,
    code3l: 'TKM',
    code: 'TM',
    name: 'Turkmenistan',
    name_official: 'Turkmenistan',
    latitude: '38.94915421',
    longitude: '59.06190323',
    zoom: 6,
  },
  {
    id: 239,
    enabled: true,
    code3l: 'TCA',
    code: 'TC',
    name: 'Turks and Caicos Islands',
    name_official: 'Turks and Caicos Islands',
    latitude: '21.72816866',
    longitude: '-71.79654471',
    zoom: 9,
  },
  {
    id: 170,
    enabled: true,
    code3l: 'TUV',
    code: 'TV',
    name: 'Tuvalu',
    name_official: 'Tuvalu',
    latitude: '-8.45968122',
    longitude: '179.13310944',
    zoom: 12,
  },
  {
    id: 171,
    enabled: true,
    code3l: 'UGA',
    code: 'UG',
    name: 'Uganda',
    name_official: 'Republic of Uganda',
    latitude: '1.54760620',
    longitude: '32.44409759',
    zoom: 7,
  },
  {
    id: 172,
    enabled: true,
    code3l: 'UKR',
    code: 'UA',
    name: 'Ukraine',
    name_official: 'Ukraine',
    latitude: '48.89358596',
    longitude: '31.10516920',
    zoom: 6,
  },
  {
    id: 173,
    enabled: true,
    code3l: 'ARE',
    code: 'AE',
    name: 'United Arab Emirates',
    name_official: 'United Arab Emirates',
    latitude: '24.64324405',
    longitude: '53.62261227',
    zoom: 7,
  },
  {
    id: 186,
    enabled: true,
    code3l: 'GBR',
    code: 'GB',
    name: 'United Kingdom',
    name_official: 'United Kingdom of Great Britain and Northern Ireland',
    latitude: '53.36540813',
    longitude: '-2.72184767',
    zoom: 6,
  },
  {
    id: 193,
    enabled: true,
    code3l: 'TZA',
    code: 'TZ',
    name: 'United Republic of Tanzania',
    name_official: 'United Republic of Tanzania',
    latitude: '-6.37551085',
    longitude: '34.85587302',
    zoom: 6,
  },
  {
    id: 243,
    enabled: true,
    code3l: 'UMI',
    code: 'UM',
    name: 'United States Minor Outlying Islands',
    name_official: 'United States Minor Outlying Islands',
    latitude: '19.46305694',
    longitude: '177.98631092',
    zoom: 5,
  },
  {
    id: 244,
    enabled: true,
    code3l: 'USA',
    code: 'US',
    name: 'United States of America',
    name_official: 'United States of America',
    latitude: '37.66895362',
    longitude: '-102.39256450',
    zoom: 4,
  },
  {
    id: 247,
    enabled: true,
    code3l: 'VIR',
    code: 'VI',
    name: 'United States Virgin Islands',
    name_official: 'Virgin Islands of the United States',
    latitude: '18.01000938',
    longitude: '-64.77411410',
    zoom: 9,
  },
  {
    id: 174,
    enabled: true,
    code3l: 'URY',
    code: 'UY',
    name: 'Uruguay',
    name_official: 'Eastern Republic of Uruguay',
    latitude: '-32.49342987',
    longitude: '-55.76583300',
    zoom: 7,
  },
  {
    id: 175,
    enabled: true,
    code3l: 'UZB',
    code: 'UZ',
    name: 'Uzbekistan',
    name_official: 'Republic of Uzbekistan',
    latitude: '41.30829147',
    longitude: '62.62970960',
    zoom: 6,
  },
  {
    id: 176,
    enabled: true,
    code3l: 'VUT',
    code: 'VU',
    name: 'Vanuatu',
    name_official: 'Republic of Vanuatu',
    latitude: '-15.37256614',
    longitude: '166.95916000',
    zoom: 8,
  },
  {
    id: 194,
    enabled: true,
    code3l: 'VEN',
    code: 'VE',
    name: 'Venezuela (Bolivarian Republic of)',
    name_official: 'Bolivarian Republic of Venezuela',
    latitude: '5.98477766',
    longitude: '-65.94152264',
    zoom: 6,
  },
  {
    id: 177,
    enabled: true,
    code3l: 'VNM',
    code: 'VN',
    name: 'Viet Nam',
    name_official: 'Socialist Republic of Viet Nam',
    latitude: '17.19931699',
    longitude: '107.14012804',
    zoom: 5,
  },
  {
    id: 246,
    enabled: true,
    code3l: 'VGB',
    code: 'VG',
    name: 'Virgin Islands',
    name_official: 'British Virgin Islands',
    latitude: '17.67004187',
    longitude: '-64.77411010',
    zoom: 10,
  },
  {
    id: 248,
    enabled: true,
    code3l: 'WLF',
    code: 'WF',
    name: 'Wallis and Futuna',
    name_official: 'Territory of the Wallis and Futuna Islands',
    latitude: '-14.29378486',
    longitude: '-178.11649800',
    zoom: 12,
  },
  {
    id: 207,
    enabled: true,
    code3l: 'ESH',
    code: 'EH',
    name: 'Western Sahara',
    name_official: 'Western Sahara',
    latitude: '24.79324356',
    longitude: '-13.67683563',
    zoom: 6,
  },
  {
    id: 178,
    enabled: true,
    code3l: 'YEM',
    code: 'YE',
    name: 'Yemen',
    name_official: 'Republic of Yemen',
    latitude: '15.60865453',
    longitude: '47.60453676',
    zoom: 6,
  },
  {
    id: 179,
    enabled: true,
    code3l: 'ZMB',
    code: 'ZM',
    name: 'Zambia',
    name_official: 'Republic of Zambia',
    latitude: '-13.01812188',
    longitude: '28.33274444',
    zoom: 6,
  },
  {
    id: 180,
    enabled: true,
    code3l: 'ZWE',
    code: 'ZW',
    name: 'Zimbabwe',
    name_official: 'Republic of Zimbabwe',
    latitude: '-19.00784952',
    longitude: '30.18758584',
    zoom: 6,
  },
];
