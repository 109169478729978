import { Component } from '@angular/core';
import { IUsersStateModel, UsersState } from '@frontend-shared/state/users.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-freelancer-details',
  templateUrl: './freelancer-details.component.html',
  styleUrls: ['./freelancer-details.component.scss'],
})
export class FreelancerDetailsComponent {
  @Select(UsersState.getUser) userData$: Observable<IUsersStateModel>;
}
