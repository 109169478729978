import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { PAYOUT_PATH } from '../../model-shared/collection-names';
import { IPayout } from '../../model-shared/payout';

@Injectable({
  providedIn: 'root',
})
export class PayoutRequestsFirestore extends NgxsFirestore<IPayout> {
  protected path = PAYOUT_PATH;
}
