import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { nickNameValidator } from '@frontend-shared/validators/nickNamesValidator';
import { IFreelancer } from '@model-shared/user';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-update-nick-name',
  templateUrl: './update-nick-name.component.html',
  styleUrls: ['./update-nick-name.component.scss'],
})
export class UpdateNickNameComponent implements OnInit {
  disabledSubmitButton = false;
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;
  updateForm = new FormGroup({
    nickname: new FormControl('', [Validators.required]),
  });
  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (user && user.details) {
        this.updateForm.controls['nickname'].setValidators([
          nickNameValidator(user.details.firstNames, user.details.lastName),
          Validators.required,
          Validators.minLength(5),
        ]);
      } else {
        // if no user => we did not go through the guards
        window.location.href = '/';
      }
    });
  }
  nickNameValSet(nickName: string) {
    this.updateForm.patchValue({
      nickname: nickName,
    });
  }
  @Dispatch()
  onSubmit() {
    this.disabledSubmitButton = true;
    setTimeout(() => {
      this.disabledSubmitButton = false;
    }, 2000);
    const user: Partial<IFreelancer> = {};
    user.nickname = this.updateForm.value.nickname;
    return [new UsersActionsUpdate(user)];
  }
}
