import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentRequestsActionsCreate } from '@frontend-shared/actions/payment-requests.actions';
import { AuthState } from '@frontend-shared/state/auth.state';
// import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { IVaultStateModel } from '@frontend-shared/state/vault.state';
import { minMaxValidator } from '@frontend-shared/validators/minMaxValidator';
import { USD_CURRENCY } from '@model-shared/typedef';
import { Select, Store } from '@ngxs/store';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Observable } from 'rxjs';

function validatePhoneNumber(c: AbstractControl): { [key: string]: any } {
  if (c.value) {
    try {
      // deepcode ignore WrongNumberOfArgs: working
      const phone = parsePhoneNumber(c.value);
      if (!phone.isValid()) {
        return { validPhoneNo: true };
      }
    } catch {
      return { validPhoneNo: true };
    }
  }
  return null;
}
@Component({
  selector: 'app-payment-create-form',
  styleUrls: ['./payment-form.component.scss'],
  templateUrl: './payment-form.component.html',
})
export class PaymentRequestCreateFormComponent implements OnInit {
  disabledSubmitButton = false;
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;
  @Select((s) => s.vault) vault$: Observable<IVaultStateModel>;
  paymentRequest = new FormGroup({
    phone: new FormControl('+', Validators.compose([Validators.required, validatePhoneNumber])),
    amount: new FormControl('', [Validators.required]),
    reason: new FormControl('', [Validators.required, Validators.maxLength(25)]),
    freelanceID: new FormControl(''),
    currency: new FormControl(USD_CURRENCY),
    from: new FormControl(''),
    reference: new FormControl(''),
  });
  constructor(private store: Store) {}
  ngOnInit() {
    this.vault$.subscribe((data) =>
      this.paymentRequest.controls['amount'].setValidators([
        minMaxValidator(data.vault.minPaymentRequestAmount, data.vault.maxPaymentRequestAmount),
      ]),
    );
  }
  async onSubmit() {
    if (this.paymentRequest.valid) {
      this.disabledSubmitButton = true;
      const userData = this.store.selectSnapshot(AuthState.getAuthUser);
      setTimeout(() => {
        this.disabledSubmitButton = false;
      }, 2000);
      this.paymentRequest.patchValue({
        freelanceID: userData.uid,
        from: userData.displayName ? userData.displayName : '',
        reference: userData.displayName ? userData.displayName : '',
      });
      await this.store
        .dispatch(new PaymentRequestsActionsCreate({ ...this.paymentRequest.value }))
        .toPromise();
      this.paymentRequest.reset();
    }
  }
}
