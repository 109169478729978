import { Injectable } from '@angular/core';
import * as FullStory from '@fullstory/browser';

@Injectable({
  providedIn: 'root',
})
export class VisualTracker {
  started = false;
  start() {
    if (!this.started)
      try {
        FullStory.init({ orgId: '10RMZY' });

        //Sentry.setTag('asayer_session_id', VisualTracker.tracker.sessionID());
        // console.log('On track', prod);
        this.started = true;
      } catch (error) {
        console.error(error);
      }
  }

  stop() {
    if (this.started)
      try {
        FullStory.shutdown();
        this.started = false;
      } catch (error) {
        console.error(error);
      }
  }

  restart() {
    if (!this.started)
      try {
        FullStory.restart();
        this.started = true;
      } catch (error) {
        console.error(error);
      }
  }

  setUserId(uid: string) {
    console.log(uid);
    //if (VisualTracker.tracker) VisualTracker.tracker.userID(uid);
    // FullStory.identify(uid, { uid });
  }

  setAnonymousId(uid: string) {
    console.log(uid);
    // FullStory.identify(uid, { uid });
  }

  metadata(key: string, value: string) {
    console.log(key, value);
    //FullStory.setUserVars({ ''+key: ''+value });
  }
}
