<nb-card id="transOrderList">
  <nb-card-header>
    <div class="row">
      <div class="col-6 text-left">
        <h5 class="float-left text-left">
          Activity
        </h5>
      </div>
    </div>

  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <tr>
            <th class="transfer-request-time-column">Date</th>
            <th>{{ 'TRANSFER_ORDER.type' | translate }}</th>
            <th>{{ 'TRANSFER_ORDER.amount' | translate }}</th>
          </tr>
          <tr *ngFor="let transferRequest of
                (transferRequests$ | async | async)
                | paginate: { itemsPerPage: 10, currentPage: page }; let i = index">
            <td  class="transfer-request-time-column">{{transferRequest.timestamp |  date:'medium'}}</td>
            <td id="typeTransfer_{{i}}">{{('TRANSFER_ORDER_TYPE.'+transferRequest?.type) | translate}}</td>
            <td *ngIf="transferRequest.transferOrderID ===undefined" >
              {{transferRequest?.amount | currency:'USD'}}
              <small *ngIf="(currency$|async) !== 'USD'">
                ( {{transferRequest?.amount | currencyConvert | async | currency:(currency$|async)}} )
              </small>
            </td>
            <td *ngIf="transferRequest.transferOrderID !==undefined" id="amountTransfer_{{i}}">
              {{transferRequest.transferOrderID | TransferOrderAmount | async | currency:'USD'}}
              <small *ngIf="(currency$|async) !== 'USD'">
                ( {{transferRequest.transferOrderID | TransferOrderAmount| async | currencyConvert | async | currency:(currency$|async)}}
                )
              </small>
            </td>
          </tr>
          <tr *ngIf="(transferRequests$ | async)?.length == 0">
            <td class="text-center" colspan="5">{{ 'PAYOUT_REQUEST.no_record_found' | translate }}</td>
          </tr>
        </table>
        <div class="pagination">
          <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
