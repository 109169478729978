import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { FREELANCER_DETAIL_PATH } from '../../model-shared/collection-names';
import { IFreelancerDetails } from '../../model-shared/freelancerDetails';

@Injectable({
  providedIn: 'root',
})
export class FreelancerDetailsFirestore extends NgxsFirestore<IFreelancerDetails> {
  protected path = FREELANCER_DETAIL_PATH;
}
