import { Clipboard } from '@angular/cdk/clipboard';
import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-invite-list',
  templateUrl: './invite-list.component.html',
  styleUrls: ['./invite-list.component.scss'],
})
export class InviteListComponent {
  webaddress =
    environment.firebaseKey.projectId === 'indie-preprod'
      ? 'https://indie-preprod.web.app/'
      : 'https://my.indie.money/';
  page = 1;
  @Select((state) => state.user.details) codes$: Observable<any>;
  constructor(private clipboard: Clipboard) {}

  copyDynamicText() {
    this.clipboard.copy('');
  }
}
