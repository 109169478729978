import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthState } from '@frontend-shared/state/auth.state';
import { IUsersStateModel, UsersState } from '@frontend-shared/state/users.state';
import { IFreelancerDetails } from '@model-shared/freelancerDetails';
import { IFreelancer, IUser } from '@model-shared/user';
import { NgxsAfterBootstrap, Select, State, StateContext } from '@ngxs/store';
import { merge, Observable } from 'rxjs';
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { navigateToDashboard } from '../navigation/navigation.action';

@State<string | null>({
  name: 'version',
  defaults: null,
})
@Injectable({
  providedIn: 'root',
})
export class NavigationState implements NgxsAfterBootstrap {
  @Select(UsersState.getUser) user$: Observable<IUsersStateModel>;

  @Select(AuthState.getAuthUser) userAuth$: Observable<IUser>;

  constructor(public auth: AngularFireAuth) {}

  /* previousUserVersion: IFreelancerDetails;
  previousUserAuthVersion: IUser;
  isEmailVerified = false;*/
  ngxsAfterBootstrap(ctx: StateContext<string | null>) {
    // the goal in this state is to redirect to dashboard to test the guards each time a guard condition has changed
    // passbase guard => kyc accepted
    // email guard => email verified
    // nationality guard => nationality changed
    // email entry guard => email exists
    // to check if it has changed we could use distinct)

    const userDetailsClean$ = this.user$.pipe(
      filter((u) => u?.details !== null),
      map((u) => u.details),
    );
    const userClean$ = this.user$.pipe(
      filter((u) => u?.user !== null),
      map((u) => u.user),
    );

    const refreshUserUntilEmailVerified = this.auth.user.pipe(
      auditTime(1000),
      tap(async (user) => {
        await this.auth.updateCurrentUser(user);
        await user?.reload();
      }),
      filter((user) => !!user?.emailVerified),
      take(1),
    );

    const email = userClean$.pipe(
      map((u) => u?.email),
      distinctUntilChanged(),
    );
    const phoneNumber = this.userAuth$.pipe(
      map((u) => u?.phoneNumber),
      distinctUntilChanged(),
    );
    const nationality = userClean$.pipe(
      map((u: IFreelancer) => u?.nationality),
      distinctUntilChanged(),
    );
    const domicile = userClean$.pipe(
      map((u: IFreelancer) => u?.domicile),
      distinctUntilChanged(),
    );
    const kycAccepted = userDetailsClean$.pipe(
      map((u: IFreelancerDetails) => u?.kycAccepted),
      distinctUntilChanged(),
    );

    const sponsorCode = userClean$.pipe(
      map((u: IFreelancer) => u?.sponsorCode),
      distinctUntilChanged(),
    );

    this.userAuth$
      .pipe(
        distinctUntilChanged(),
        switchMap(() => userClean$),
      )
      .subscribe(() => ctx.dispatch(navigateToDashboard));

    merge([
      refreshUserUntilEmailVerified,
      email,
      phoneNumber,
      nationality,
      domicile,
      kycAccepted,
      sponsorCode,
    ])
      .pipe(
        debounceTime(1000),
        switchMap((a) => a),
      )
      .subscribe(() => {
        ctx.dispatch(navigateToDashboard);
      });
  }
}
