export const freeLanceFrontLanguages = [
  { name: 'English', lang: 'en', class: 'flag-icon flag-icon-us' },
  { name: 'Français', lang: 'fr', class: 'flag-icon flag-icon-fr' },
];
export const freeLanceFrontCurrencies = [
  'USD',
  'EUR',
  'GBP',
  'CAD',
  'HRK',
  'CZK',
  'DKK',
  'CHF',
  'BGN',
  'PLN',
  'RON',
  'THB',
];
