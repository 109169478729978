import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { TRANSFER_REQUEST_ARCHIVE_PATH } from '../../model-shared/collection-names';
import { ITransferRequest } from '../../model-shared/transferRequest';

@Injectable({
  providedIn: 'root',
})
export class TransferRequestFirestore extends NgxsFirestore<ITransferRequest> {
  protected path = TRANSFER_REQUEST_ARCHIVE_PATH;
}
