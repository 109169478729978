import { Injectable } from '@angular/core';
import { WhereFilterOp } from '@firebase/firestore-types';
import { Action, NgxsOnInit, Select, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Emitted, NgxsFirestoreConnect, StreamEmitted } from '@ngxs-labs/firestore-plugin';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, flatMap, map, startWith, switchMap, take } from 'rxjs/operators';

import { PaymentRequestState } from '../../model-shared/payment-request-status';
import { AccountEnum } from '../../model-shared/transfer';
import { VaultActionsGet } from '../actions/vault.actions';
import { PaymentRequestStatusFirestore } from '../firestore/payment-request-status.firestore';
import { PaymentRequestsFirestore } from '../firestore/payment-requests.firestore';
import { ScheduledTransferFirestore } from '../firestore/scheduledtransfer.firestore';
import { TransferFirestore } from '../firestore/transfer.firestore';
import { IUserModel } from './auth.state';
import { IUsersStateModel, UsersState } from './users.state';

dayjs.extend(utc);

export interface IVaultCollection {
  vault_1: number;
  vault_3: number;
  pending_payout: number;
  paid_out: number;
  vault_3_7days: number;

  vault_3_30days: number;
  vault_3_1day: number;
  vault_3_moreThan30days: number;
  vault_3_7days_left: number;
  maxPaymentRequestAmount: number;
  minPaymentRequestAmount: number;
  minimumToTransferOut: number;
  guarantee: number;
  interest_vault_3: number;
  sponsorship_vault_3: number;
  currentBonus: number;
  nextBonus: number;
  nextVaultAmount: number;
  thisMonthTotalTransferBackToInbox: number;
  thisMonth2TotalTransferBackToInbox: number;
  thisMonth3TotalTransferBackToInbox: number;
  thisMonth4TotalTransferBackToInbox: number;
  nowMonthDate: Date;
  nowPlus1MonthDate: Date;
  nowPlus2MonthDate: Date;
  nowPlus3MonthDate: Date;
  total: number;
}

export interface IVaultStateModel {
  vault: IVaultCollection;
}

const nowPlusOneDayEpoch = dayjs().subtract(1, 'day').valueOf();
const nowPlus7DaysEpoch = dayjs().subtract(7, 'day').valueOf();
const nowPlusOneMonthEpoch = dayjs().subtract(1, 'month').valueOf();
const nowMonth = dayjs().startOf('month');
const nowPlusOneMonth = dayjs().startOf('month').add(1, 'month');
const nowPlusTwoMonth = dayjs().add(2, 'month').startOf('month');
const nowPlusThreeMonth = dayjs().add(3, 'month').startOf('month');
const nowPlusFourMonth = dayjs().add(4, 'month').startOf('month');

@State<IVaultStateModel>({
  name: 'vault',
  defaults: {
    vault: null,
  },
})
@Injectable({ providedIn: 'root' })
export class VaultState implements NgxsOnInit {
  @Select((a: any) => a.userData) authUser$: Observable<IUserModel>;

  @Select(UsersState.getUser) user$: Observable<IUsersStateModel>;

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(VaultActionsGet, {
      to: () =>
        this.authUser$.pipe(
          filter((user: any) => user?.userData?.uid !== undefined),
          map((user) => user.userData.uid),
          flatMap((uid) => {
            const obs$ = [];

            obs$.push(this.getAccountTotal(uid, [AccountEnum[AccountEnum.VAULT_L1]]));

            obs$.push(this.getAccountTotal(uid, [AccountEnum[AccountEnum.VAULT_L3]]));

            obs$.push(
              this.getAccountTotal(uid, [
                AccountEnum[AccountEnum.AWAITING_PAYOUT],
                AccountEnum[AccountEnum.PENDING_PAYOUT],
              ]),
            );

            obs$.push(this.getAccountTotal(uid, [AccountEnum[AccountEnum.PAID_OUT]]));

            obs$.push(this.getTotalPaymentRequestsLast7Days(uid, nowPlus7DaysEpoch, '>'));

            obs$.push(this.getAccountTotal(uid, [AccountEnum[AccountEnum.VAULT_L3_INTEREST_PAID]]));

            obs$.push(
              this.getAccountTotalSpecific(
                uid,
                AccountEnum[AccountEnum.VAULT_L3],
                AccountEnum[AccountEnum.SPONSORSHIP],
              ),
            );

            obs$.push(this.getTotalConfirmedPaymentRequests(uid, nowPlusOneDayEpoch, '>'));
            obs$.push(this.getTotalConfirmedPaymentRequests(uid, nowPlusOneMonthEpoch, '>'));
            obs$.push(this.getTotalConfirmedPaymentRequests(uid, nowPlusOneMonthEpoch, '<'));

            obs$.push(this.getTotalScheduledTransfers(uid, nowMonth, nowPlusOneMonth));
            obs$.push(this.getTotalScheduledTransfers(uid, nowPlusOneMonth, nowPlusTwoMonth));
            obs$.push(this.getTotalScheduledTransfers(uid, nowPlusTwoMonth, nowPlusThreeMonth));
            obs$.push(this.getTotalScheduledTransfers(uid, nowPlusThreeMonth, nowPlusFourMonth));

            //obs$.push(this.getAccountTotal(uid, [AccountEnum[AccountEnum.]]));

            //{"vault_1": obs$[0], "vault_2": obs$[1], "vault_3": obs$[2]}
            return combineLatest(obs$);
          }),
        ),
    });
  }

  private getAccountTotal = (userId: string, where: string[]) =>
    combineLatest([this.getTo$(userId, where), this.getFrom$(userId, where)]).pipe(
      map(
        ([vaultTo, vaultFrom]) =>
          vaultTo.reduce((acc, curr) => acc + curr.amount, 0) -
          vaultFrom.reduce((acc, curr) => acc + curr.amount, 0),
      ),
      startWith(0),
      map((a: number) => a.toFixed(2)),
      catchError((e, c) => {
        console.error(e);
        return c;
      }),
    );

  private getAccountTotalSpecific = (userId: string, to: string, from: string) =>
    combineLatest([this.getSpecific$(userId, from, to), this.getSpecific$(userId, to, from)]).pipe(
      map(
        ([vaultTo, vaultFrom]) =>
          vaultTo.reduce((acc, curr) => acc + curr.amount, 0) -
          vaultFrom.reduce((acc, curr) => acc + curr.amount, 0),
      ),
      startWith(0),
      map((a: number) => a.toFixed(2)),
      catchError((e, c) => {
        console.log(e);
        return c;
      }),
    );

  private getTotalPaymentRequestsLast7Days = (
    userId: string,
    since: number,
    higherOrLower: WhereFilterOp,
  ) =>
    // get all payment request  of the last 7 days with last status not EXPIRED or SEND_FAILED or INVALID (take only one)
    // add amount
    this.prFS
      .collection$((ref) =>
        ref.where('freelanceID', '==', userId).where('timestamp', higherOrLower, since),
      )
      .pipe(
        map((a) => a.map((pr) => combineLatest([of(pr), this.prsFS.docOnce$(pr.lastStatusID)]))),
        switchMap((_) => combineLatest(_)),
        map((combinedResults) =>
          combinedResults.filter(
            (b) =>
              b[1].state === PaymentRequestState[PaymentRequestState.SENT] ||
              b[1].state === PaymentRequestState[PaymentRequestState.PAID] ||
              b[1].state === PaymentRequestState[PaymentRequestState.CONFIRMED] ||
              b[1].state === PaymentRequestState[PaymentRequestState.RETRY] ||
              b[1].state === PaymentRequestState[PaymentRequestState.PROCESSING] ||
              b[1].state === PaymentRequestState[PaymentRequestState.CHARGED_BACK],
          ),
        ),
        map((a) => a.map((b) => b[0])),
        map((a) => a.map((c) => c.amount).reduce((e, b) => e + b, 0)),
        startWith(0),
        map((a: number) => Math.max(a, 0)),
        map((a: number) => a.toFixed(2)),
        catchError((e, c) => {
          console.error(e);
          return c;
        }),
      );

  private getTotalConfirmedPaymentRequests = (
    userId: string,
    since: number,
    higherOrLower: WhereFilterOp,
  ) =>
    this.prFS
      .collection$((ref) =>
        ref.where('freelanceID', '==', userId).where('timestamp', higherOrLower, since),
      )
      .pipe(
        map((a) => a.map((pr) => combineLatest([of(pr), this.prsFS.docOnce$(pr.lastStatusID)]))),
        switchMap((_) => combineLatest(_)),
        map((combinedResults) =>
          combinedResults.filter(
            (b) =>
              b[1].state === PaymentRequestState[PaymentRequestState.PAID] ||
              b[1].state === PaymentRequestState[PaymentRequestState.CONFIRMED],
          ),
        ),
        map((a) => a.map((b) => b[0])),
        map((a) => a.map((c) => c.amount).reduce((d, b) => d + b, 0)),
        startWith(0),
        map((a: number) => a.toFixed(2)),
        catchError((e, c) => {
          console.error(e);
          return c;
        }),
      );

  private getFrom$ = (userId: string, where: string[]) =>
    this.transferFS.collection$((ref) =>
      ref.where('freelanceID', '==', userId).where('from', 'in', where),
    );

  private getTo$ = (userId: string, where: string[]) =>
    this.transferFS.collection$((ref) =>
      ref.where('freelanceID', '==', userId).where('to', 'in', where),
    );

  private getSpecific$ = (userId: string, from: string, to: string) =>
    this.transferFS.collection$((ref) =>
      ref.where('freelanceID', '==', userId).where('from', '==', from).where('to', '==', to),
    );

  private getScheduledTransferForMonth = (userId: string, fromMonth: Dayjs, toMonth: Dayjs) =>
    this.scheduledTransfersFS.collection$((ref) =>
      ref
        .where('freelanceID', '==', userId)
        .where('to', '==', 'VAULT_L1')
        .where('from', '==', 'VAULT_L3')
        .where('effectiveDateUTC', '>', fromMonth.utc().format())
        .where('effectiveDateUTC', '<', toMonth.utc().format()),
    );

  private getTotalScheduledTransfers = (userId: string, fromMonth: Dayjs, toMonth: Dayjs) =>
    this.getScheduledTransferForMonth(userId, fromMonth, toMonth).pipe(
      map((a) => a.map((a) => a.amount).reduce((a, b) => a + b, 0)),
      startWith(0),
      map((a: number) => a.toFixed(2)),
      catchError((e, c) => {
        console.error(e);
        return c;
      }),
    );

  constructor(
    private transferFS: TransferFirestore,
    private prFS: PaymentRequestsFirestore,
    private prsFS: PaymentRequestStatusFirestore,
    private scheduledTransfersFS: ScheduledTransferFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
  ) {}

  @Action(StreamEmitted(VaultActionsGet))
  async get(ctx: StateContext<IVaultStateModel>, { payload }: Emitted<VaultActionsGet, any>) {
    const vault_1 = Number(payload[0] ? payload[0] : 0);
    const vault_3 = Number(payload[1] ? payload[1] : 0);
    const pending_payout = Number(payload[2] ? payload[2] : 0);
    const paid_out = Number(payload[3] ? payload[3] : 0);
    const vault_3_7days = Number(payload[4] ? payload[4] : 0);
    const interest_vault_3 = -Number(payload[5] ? payload[5] : 0);
    const sponsorship_vault_3 = Number(payload[6] ? payload[6] : 0);

    const vault_3_1day = Number(payload[7] ? payload[7] : 0);
    const vault_3_30days = Number(payload[8] ? payload[8] : 0);
    const vault_3_moreThan30days = Number(payload[9] ? payload[9] : 0);
    const thisMonthTotalTransferBackToInbox = Number(payload[10] ? payload[10] : 0);
    const thisMonth2TotalTransferBackToInbox = Number(payload[11] ? payload[11] : 0);
    const thisMonth3TotalTransferBackToInbox = Number(payload[12] ? payload[12] : 0);
    const thisMonth4TotalTransferBackToInbox = Number(payload[13] ? payload[13] : 0);

    const total =
      thisMonthTotalTransferBackToInbox +
      thisMonth2TotalTransferBackToInbox +
      thisMonth3TotalTransferBackToInbox +
      thisMonth4TotalTransferBackToInbox +
      vault_1 +
      pending_payout;
    // 1) get freelance plan (in freelancer details)
    const pricing = await this.user$
      .pipe(
        map((a) => a.plan),
        filter((a) => !!a),
        take(1),
      )
      .toPromise();
    // 2) get vault 3 amount

    // 3) find out limit
    let i = 0;
    while (
      i <= pricing.maximumPaymentRequestVaultMinimum.length &&
      vault_3 >= pricing.maximumPaymentRequestVaultMinimum[i]
    ) {
      i++;
    }
    const nextVaultAmount = i < 4 ? pricing.maximumPaymentRequestVaultMinimum[i] : undefined;
    const currentBonus = pricing.vaultL3Bonus[i - 1];
    const nextBonus = i < 4 ? pricing.vaultL3Bonus[i] : undefined;
    // 4) calc maximum
    const maxPaymentRequestAmount = i === 0 ? 0 : pricing.maximum7DaysPaymentRequest[i - 1];
    const guarantee = 100 - pricing.howMuchToKeepInVaultRatio[0];
    // 5) withdraw 7 days sliding window
    const vault_3_7days_left = maxPaymentRequestAmount - vault_3_7days;
    ctx.setState(
      patch({
        vault: {
          vault_1,
          vault_3,
          pending_payout,
          paid_out,
          vault_3_7days,
          vault_3_7days_left,
          maxPaymentRequestAmount,
          minPaymentRequestAmount: pricing.minimumPaymentRequest,
          minimumToTransferOut: pricing.minimumToTransferOut,
          guarantee,
          interest_vault_3,
          sponsorship_vault_3,
          currentBonus,
          nextBonus,
          nextVaultAmount,
          vault_3_1day,
          vault_3_30days,
          vault_3_moreThan30days,
          thisMonthTotalTransferBackToInbox,
          thisMonth2TotalTransferBackToInbox,
          thisMonth3TotalTransferBackToInbox,
          thisMonth4TotalTransferBackToInbox,
          nowMonthDate: nowMonth.toDate(),
          nowPlus1MonthDate: nowPlusOneMonth.toDate(),
          nowPlus2MonthDate: nowPlusTwoMonth.toDate(),
          nowPlus3MonthDate: nowPlusThreeMonth.toDate(),
          total,
        },
      }),
    );
  }
}
