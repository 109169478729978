import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'ngx-save-code',
  templateUrl: './save-code.component.html',
  styleUrls: ['./save-code.component.scss'],
})
export class SaveCodeComponent {
  webaddress =
    environment.firebaseKey.projectId === 'indie-preprod'
      ? 'https://indie-preprod.web.app/'
      : 'https://my.indie.money/';

  constructor(private route: ActivatedRoute, private cookieService: CookieService) {
    this.route.params.subscribe((params) => {
      this.cookieService.set('referral', params.id, 7, '/');
      window.location.href = 'https://indie.money';
    });
  }
}
