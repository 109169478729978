import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Emitted, NgxsFirestoreConnect, StreamEmitted } from '@ngxs-labs/firestore-plugin';
import { combineLatest, from, Observable } from 'rxjs';
import { distinct, filter, map, scan, switchMap } from 'rxjs/operators';

import { ITransfer } from '../../model-shared/transfer';
import { ITransferOrder } from '../../model-shared/transferOrder';
import { ITransferRequest } from '../../model-shared/transferRequest';
import {
  TransferOrderActionsGet,
  TransferOrderActionsGetAll,
  TransferOrderActionsSubmit,
} from '../actions/transferOrders.actions';
import { ExchangeRateFirestore } from '../firestore/exchange-rate.firestore';
import { TransferFirestore } from '../firestore/transfer.firestore';
import { TransferOrderFirestore } from '../firestore/transferOrder.firestore';
import { TransferRequestFirestore } from '../firestore/transferRequest.firestore';
import { FireFunctionsService } from '../services/fire-functions.service';
import { IUserModel } from './auth.state';
import { IPaymentRequestsStateModel } from './payment-requests.state';

export interface ITransferOrderStateModel {
  transferOrders: ITransferOrder[];
  transfersList: ITransfer[];
  transferRequestList: ITransferRequest[];

  transOrderTotal: number;
  transListTotal: number;
  exchangeQuotes: {
    [index: string]: number;
  };
}
@State<ITransferOrderStateModel>({
  name: 'transferOrder',
  defaults: {
    transferOrders: [],
    transfersList: [],
    transferRequestList: [],
    transOrderTotal: 0,
    transListTotal: 0,
    exchangeQuotes: undefined,
  },
})
@Injectable({ providedIn: 'root' })
export class TransferOrdersState {
  @Select((a: any) => a.userData) authentifiedUser$: Observable<IUserModel>;

  @Selector() static getTransferOrders(state: ITransferOrderStateModel) {
    return state.transferOrders;
  }
  @Selector()
  static getTransferList(state: ITransferOrderStateModel) {
    return (vault: string) => state.transfersList.filter((d) => d.from === vault || d.to === vault);
  }

  @Selector()
  static getTransferRequestDistinctOrderNoSettlement(
    state: ITransferOrderStateModel,
  ): Observable<ITransferRequest[]> {
    return from(state.transferRequestList).pipe(
      filter((a) => a.type !== 'SCHEDULED_TRANSFER' && a.type !== 'CIRCLE_CHARGE_SETTLEMENT'),
      distinct((a) => (a.transferOrderID ? a.transferOrderID : a.id)),
      scan((a, c) => [...a, c], []),
    );
  }

  @Selector()
  static getTransferListTwoParams(state: ITransferOrderStateModel) {
    return (vault: string, vault1: string) =>
      state.transfersList.filter(
        (d) => d.from === vault || d.from === vault1 || d.to === vault || d.to === vault1,
      );
  }
  @Selector()
  static getTransferListFourParams(state: ITransferOrderStateModel) {
    return (vault1: string, vault2: string, vault3: string, vault4: string) => {
      return state.transfersList.filter(
        (d) =>
          d.from === vault1 ||
          d.from === vault2 ||
          d.from === vault3 ||
          d.from === vault4 ||
          d.to === vault1 ||
          d.to === vault2 ||
          d.to === vault3 ||
          d.to === vault4,
      );
    };
  }
  @Selector()
  static getTransferListThreeParams(state: ITransferOrderStateModel) {
    return (vault1: string, vault2: string, vault3: string) => {
      return state.transfersList.filter(
        (d) =>
          d.from === vault1 ||
          d.from === vault2 ||
          d.from === vault3 ||
          d.to === vault1 ||
          d.to === vault2 ||
          d.to === vault3,
      );
    };
  }
  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(TransferOrderActionsGetAll, {
      to: () =>
        this.authentifiedUser$.pipe(
          filter((user: any) => user?.userData?.uid !== undefined),
          map((user) => user.userData.uid),
          switchMap((userId) =>
            combineLatest([
              this.gteTransferOrders(userId),
              this.getTransfers(userId),
              this.getLastExchangeRates(),
              this.getTransferRequests(userId),
            ]),
          ),
        ),
    });
  }

  private gteTransferOrders = (userId: string) =>
    this.transOrderRFS.collection$((ref) =>
      ref.where('freelanceID', '==', userId).orderBy('timestamp', 'desc'),
    );

  private getTransfers = (userId: string) =>
    this.transfersFS.collection$((ref) =>
      ref.where('freelanceID', '==', userId).orderBy('timestamp', 'desc'),
    );

  private getTransferRequests = (userId: string) =>
    this.transferRequestFS.collection$((ref) =>
      ref.where('freelanceID', '==', userId).orderBy('timestamp', 'desc'),
    );

  private getLastExchangeRates = () =>
    this.erFS.collection$((ref) => ref.orderBy('timestamp', 'desc').limit(1));

  constructor(
    private transOrderRFS: TransferOrderFirestore,
    private transferRequestFS: TransferRequestFirestore,
    private transfersFS: TransferFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private erFS: ExchangeRateFirestore,
    private fireFuncs: FireFunctionsService,
  ) {}
  @Action(StreamEmitted(TransferOrderActionsGetAll))
  getAllEmitted(
    ctx: StateContext<ITransferOrderStateModel>,
    { payload }: Emitted<TransferOrderActionsGet, any>,
  ) {
    let exchangeQuotes;
    // console.log(payload[2][0].quotes);
    // console.log(payload[2][0].quotes['USDBYR']); //
    if (payload[2] && payload[2][0] && payload[2][0]?.quotes) exchangeQuotes = payload[2][0].quotes;
    ctx.setState(
      patch({
        transferOrders: payload[0],
        transfersList: payload[1],
        transferRequestList: payload[3],
        transOrderTotal: payload[0].length,
        transListTotal: payload[1].length,
        exchangeQuotes,
      }),
    );
  }

  @Action(TransferOrderActionsSubmit)
  async create(_: StateContext<IPaymentRequestsStateModel>, { payload }: any) {
    await this.fireFuncs
      .submitTransferOrder()(payload)
      .toPromise()
      .catch((error) => {
        console.error(error);
        window.location.reload();
      });
  }
}
