import { AbstractControl, ValidatorFn } from '@angular/forms';

export const minMaxValidator =
  (min: number, max: number): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    Number(control.value) >= min && Number(control.value) <= max ? null : { wrongAmount: true };

export const minValidator =
  (min: number): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    Number(control.value) >= min ? null : { wrongAmount: true };
