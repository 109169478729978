import { ITransferOrder } from '../../model-shared/transferOrder';

export class TransferOrderActionsGet {
  static readonly type = '[TransferOrder] Get';
  constructor(public payload: string) {}
}
export class TransferOrderActionsGetAll {
  static readonly type = '[TransferOrder] GetAll';
}

export class TransferOrderActionsSubmit {
  static readonly type = '[TransferOrder] Submit';
  constructor(public payload: ITransferOrder) {}
}
