<div class="form-group" >
  <p> A good nickname says who you are to your customers.</p>
  <p> You need to use parts of your first and last names :</p>
  <p> <strong>{{(user$| async).details.firstNames}} {{(user$| async).details.lastName}}</strong> </p>
  <p> Add a title or a profession to make it unique ! </p>

  <p class="label" for="pnickname">DOCTOR DOE / DESIGNER JOHN DOE </p>
  <p class="label" for="pnickname">TRANSLATOR J DOE / YOGA GURU DOE</p>

  <div class="form-group">
    <input fullWidth nbInput id="pnickname" type="text" maxChars="20" name="nickname" [formControl]="nickname" id="inputfield"
           placeholder="Nick name" />
  </div>
  <span class="caption status-danger ng-star-inserted" *ngIf="nickname.errors?.required" id="required">
      *required
    </span>
  <span class="caption status-danger ng-star-inserted"
        *ngIf="nickname.errors?.minlength || nickname.errors?.wrongInput;" id="wrongInput">
      Try again with a name closer to your first and last names
    </span>
</div>
