import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const USER_LANG_LOCAL_STORAGE = 'userLang';
const DEFAULT_LANG = 'en';

@Injectable({
  providedIn: 'root',
})
export class TranslationFrontEndService {
  constructor(private http: HttpClient, private translate: TranslateService) {
    translate.setDefaultLang(DEFAULT_LANG);
    translate.use(this.getTranslationStored() ? this.getTranslationStored() : DEFAULT_LANG);
    const currentStoreLang = localStorage.getItem(USER_LANG_LOCAL_STORAGE);
    if (currentStoreLang) this.setTranslation(currentStoreLang);
    else localStorage.setItem(USER_LANG_LOCAL_STORAGE, DEFAULT_LANG);
    this.translate.onLangChange.subscribe(() => this.translate.use(this.getTranslationStored()));
  }
  getTranslationStored = () => {
    return localStorage.getItem(USER_LANG_LOCAL_STORAGE);
  };
  setStorageItem = (event: string) => {
    localStorage.setItem(USER_LANG_LOCAL_STORAGE, event);
  };
  setTranslation = (lang: string) =>
    this.http.get(`./assets/i18n/${lang}.json`).subscribe(
      () => {
        localStorage.setItem(USER_LANG_LOCAL_STORAGE, lang);
        this.translate.use(lang);
      },
      () => console.warn('failed loading translation'),
    );
}
