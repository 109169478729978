import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

/**
 *  For the profile image form
 */
@State({
  name: 'editforms',
  defaults: {
    newEditForm: {
      model: undefined,
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable({
  providedIn: 'root',
})
export class EditformsState {}

/**
 *  For the update country form
 */
@State({
  name: 'updateCountry',
  defaults: {
    updateCountryForm: {
      model: undefined,
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable({
  providedIn: 'root',
})
export class UpdateCountryFormState {}

/**
 *  For the update country form
 */
@State({
  name: 'updatePayoutAddress',
  defaults: {
    updatePayoutAddressForm: {
      model: undefined,
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable({
  providedIn: 'root',
})
export class UpdatePayoutAddressFormState {}
@State({
  name: 'cashOutModal',
  defaults: {
    details: {
      model: {},
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable({
  providedIn: 'root',
})
export class CashOutModalFromState {}
@State({
  name: 'StakeModal',
  defaults: {
    details: {
      model: {},
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable({
  providedIn: 'root',
})
export class StakeModalFormState {}
