<div class="vault-container">
    <nb-card id="vault1">
        <nb-card-header>
            <div class="row">
                <div class="col text-left">
                    <h5 class="float-left text-left">
                        Vault
                        <a href="https://indie.money/faq/vault/" target="_blank">
                            <i class='fa fa-question-circle' aria-hidden="true"
                            ></i></a>                    </h5>
                </div>
                <div class="col text-right">
                    <h5 *ngIf="(currency$|async) === 'USD'" class="align-middle float-right price">
                        <img class="usdc-header-icon" alt="USDC icon" src="/assets/images/usdc.png">
                        {{(vault$ | async)?.vault?.vault_3 }}</h5>
                    <h5 *ngIf="(currency$|async) !== 'USD'" class="align-middle float-right price">
                        <span id="vaultCurrencyChange">({{(vault$ | async)?.vault?.vault_3 | currencyConvert | async | currency:(currency$|async)}})&nbsp;</span>
                        <img class="usdc-header-icon" alt="USDC icon" src="/assets/images/usdc.png">
                        {{(vault$ | async)?.vault?.vault_3 }}
                    </h5>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body class="scrollable">
            <div class="row card-content-description">
                <div class="col-sm-12">
                    <span style="float:left;">
                        All Time Interest:
                    </span>
                    <span style="float:right;">
                        <span *ngIf="(currency$|async) === 'USD'" class="align-middle float-right price">
                            <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                            {{(vault$ | async)?.vault?.interest_vault_3 }}</span>
                        <span *ngIf="(currency$|async) !== 'USD'" class="align-middle float-right price">
                            ({{(vault$ | async)?.vault?.interest_vault_3 | currencyConvert | async | currency:(currency$|async)}})
                            <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                            {{(vault$ | async)?.vault?.interest_vault_3 }}
                        </span>
                    </span>
                </div>
            </div>

            <div class="row card-content-description">
                <div class="col-sm-12">
                    <span style="float:left;">
                        All Time Referral Bonus:
                    </span>
                    <span style="float:right;">
                        <span *ngIf="(currency$|async) === 'USD'" class="align-middle float-right price">
                            <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                            {{(vault$ | async)?.vault?.sponsorship_vault_3 }}</span>
                        <span *ngIf="(currency$|async) !== 'USD'" class="align-middle float-right price">
                            ({{(vault$ | async)?.vault?.sponsorship_vault_3 | currencyConvert | async | currency:(currency$|async)}})
                            <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                            {{(vault$ | async)?.vault?.sponsorship_vault_3 }}
                        </span>
                    </span>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>
