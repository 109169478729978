import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { IFreelancer } from '@model-shared/user';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-invite-only',
  templateUrl: './invite-only.component.html',
  styleUrls: ['./invite-only.component.scss'],
})
export class InviteOnlyComponent implements OnInit {
  disabledSubmitButton = false;
  @Select((a) => a.user.user) user$: Observable<string>;
  updateForm = new FormGroup({
    code: new FormControl('', [Validators.required]),
  });

  constructor(private cookieService: CookieService) {}

  @Dispatch()
  onSubmit() {
    this.disabledButtonForTwoSecs();
    const user: Partial<IFreelancer> = {};
    user.sponsorCode = this.updateForm.value.code;
    return [new UsersActionsUpdate(user)];
  }
  @Dispatch()
  noCode() {
    this.disabledButtonForTwoSecs();
    const user: Partial<IFreelancer> = {};
    // user.sponsorCode = 'ITESTCODE' as InviteCodeType; // any other code will result to rerouting here too for now.
    return [new UsersActionsUpdate(user)];
  }

  private disabledButtonForTwoSecs() {
    this.disabledSubmitButton = true;
    setTimeout(() => {
      this.disabledSubmitButton = false;
    }, 2000);
  }

  ngOnInit(): void {
    const referral = 'referral';
    if (this.cookieService.check(referral)) {
      console.log(this.cookieService.get(referral));
      this.updateForm.controls.code.setValue(this.cookieService.get(referral));
    }
  }
}
