import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { USD_CURRENCY } from '../../model-shared/typedef';

@Injectable({
  providedIn: 'root',
})
export class CurrencyexchangeService {
  constructor(private store: Store) {}

  getCurrency(amount: number, currency: string): number {
    const exchangeQuotes: {
      [index: string]: number;
    } = this.store.selectSnapshot((state) => state.transferOrder.exchangeQuotes);
    if (exchangeQuotes === undefined || exchangeQuotes === null) {
      return amount;
    } else {
      return currency !== USD_CURRENCY
        ? Number.parseFloat(((amount * 1) / exchangeQuotes[USD_CURRENCY + currency]).toFixed(2))
        : amount;
    }
  }
  getCurrencyFromUSD(amount: number, currency: string): number {
    const exchangeQuotes: {
      [index: string]: number;
    } = this.store.selectSnapshot((state) => state.transferOrder.exchangeQuotes);
    if (exchangeQuotes === undefined || exchangeQuotes === null) {
      return amount;
    } else {
      return currency !== 'USD'
        ? Number.parseFloat((amount * exchangeQuotes['USD' + currency]).toFixed(2))
        : amount;
    }
  }
}
