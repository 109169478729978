import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentRequestsActionsGetAll } from '@frontend-shared/actions/payment-requests.actions';
import { TransferOrderActionsGetAll } from '@frontend-shared/actions/transferOrders.actions';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { VaultActionsGet } from '@frontend-shared/actions/vault.actions';
import { PaymentRequestStatusFirestore } from '@frontend-shared/firestore/payment-request-status.firestore';
import { IUserModel } from '@frontend-shared/state/auth.state';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { IVaultStateModel } from '@frontend-shared/state/vault.state';
import { isPreproductionFE } from '@frontend-shared/tools/tools';
import { Select, Store } from '@ngxs/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {
  navigateToInbox,
  navigateToPayoutTransfer,
  navigateToPendingTransfer,
  navigateToVaultTransfer,
} from '../../navigation/navigation.action';
import { TourService } from '../../service/tour.service';

@Component({
  selector: 'ngx-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnDestroy, OnInit, AfterViewInit {
  @SelectSnapshot((s) => s.userData) userInfo: IUserModel;
  @Select((s) => s.vault) vault$: Observable<IVaultStateModel>;
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;

  isPreproduction = isPreproductionFE(environment);

  public sentRequest$: Observable<{ total_count: number; total_amount: number }>;
  public paidRequest$: Observable<{ total_count: number; total_amount: number }>;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private paymentRSFS: PaymentRequestStatusFirestore,
    private tourService: TourService,
    private store: Store,
  ) {}

  navigate(index: number) {
    switch (index) {
      case 1:
        this.store.dispatch(navigateToInbox);
        break;
      case 2:
        this.store.dispatch(navigateToVaultTransfer);
        break;
      case 3:
        this.store.dispatch(navigateToPendingTransfer);
        break;
      case 4:
        this.store.dispatch(navigateToPayoutTransfer);
        break;
      default:
        break;
    }
  }
  ngOnInit() {
    // load dashboard components
    this.store.dispatch(new VaultActionsGet());
    this.store.dispatch(new TransferOrderActionsGetAll());
    this.store.dispatch(new PaymentRequestsActionsGetAll());
    this.sentRequest$ = this.getPaymentRequestStatusByState$('SENT');
    this.paidRequest$ = this.getPaymentRequestStatusByState$('PAID');
  }
  private getPaymentRequestStatusByState$(state: string) {
    return this.paymentRSFS
      .collection$((ref) => {
        return ref
          .where('freelanceID', '==', this.userInfo.userData.uid)
          .where('state', '==', state);
      })
      .pipe(
        map((res: [any]) => {
          return {
            // eslint-disable-next-line unicorn/no-array-reduce
            total_amount: res.reduce(
              (accumulator, currentValue) => Number(accumulator) + Number(currentValue.amount),
              0,
            ),
            total_count: res.length,
          };
        }),
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.user$.pipe(take(1)).subscribe((data) => {
      if (!data.user.tourDone) {
        this.tourService.init(() => {
          console.log('done ');
          this.store.dispatch(
            new UsersActionsUpdate({
              tourDone: true,
            }),
          );
        });
        this.tourService.start();
      }
    });
  }
}
