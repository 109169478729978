import { Pipe, PipeTransform } from '@angular/core';

import { countries } from '../../model-shared/countries';

@Pipe({ name: 'ngxCapitalize' })
export class CapitalizePipe implements PipeTransform {
  public transform(input: string): string {
    const found = countries.filter((c: any) => c.name === input);
    return found.length > 0 ? found[0].code : input;
  }
}
