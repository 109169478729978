import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IFireOperation } from '@frontend-shared/interface/fire.interface';
import { FireState } from '@frontend-shared/state/fire.state';
import { IUser } from '@model-shared/user';
// import { MENU_ITEMS } from '../pages-menu';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-editprofile',
  styleUrls: ['./editprofile.component.scss'],
  templateUrl: './editprofile.component.html',
})
export class EditprofileComponent implements OnInit {
  user: any;

  @Select((s) => s.userData) userDataSub: Observable<IUser>;
  @Select(FireState.getFireStoreStorage) myOperation: Observable<IFireOperation>;
  editForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    full_name: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30),
    ]),
  });

  isEmail = false;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.isEmail = false;
  }
}
