import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { IPaymentRequest } from '../../model-shared/payment-request';
import { PaymentRequestsActionsUpdate } from '../actions/payment-requests.actions';

export interface IUpdatePaymentStateModel {
  paymentRequest: IPaymentRequest;
}
@State<IUpdatePaymentStateModel>({
  name: 'updatePayment',
  defaults: {
    paymentRequest: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class UpdatePaymentRequestState {
  @Action(PaymentRequestsActionsUpdate)
  prUpdate(ctx: StateContext<IUpdatePaymentStateModel>, { payload }: PaymentRequestsActionsUpdate) {
    ctx.patchState({
      paymentRequest: payload,
    });
  }
}
