import { Pipe, PipeTransform } from '@angular/core';

import { ScheduledTransferFirestore } from '../firestore/scheduledtransfer.firestore';

@Pipe({
  name: 'dateBack',
})
export class DateBackPipe implements PipeTransform {
  constructor(public scheduledFS: ScheduledTransferFirestore) {}

  async transform(value: string): Promise<string> {
    return (
      await this.scheduledFS
        .collectionOnce$((ref) => ref.where('transferID', '==', value).limit(1))
        .toPromise()
    )[0].effectiveDateUTC;
  }
}
