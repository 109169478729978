import { Component, OnInit } from '@angular/core';
import { SignOutUser } from '@frontend-shared/actions/auth.actions';
import { VisualTracker } from '@frontend-shared/services/visualtracker.service';
import { AuthState } from '@frontend-shared/state/auth.state';
import { isLocalHost, isPreproductionFE } from '@frontend-shared/tools/tools';
import { IUser } from '@model-shared/user';
import { NbDialogRef, NbDialogService, NbMenuItem, NbMenuService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { MENU_ITEMS } from './pages-menu';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu tag="menu" [items]="menu" (click)="getSelectedItems()"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent implements OnInit {
  menu: NbMenuItem[];
  save_menu: NbMenuItem[];
  @Select(AuthState.getAuthUser) userAuth$: Observable<IUser>;
  screenWidth: any;
  resDialogRef: NbDialogRef<any>;
  constructor(
    private translateService: TranslateService,
    private store: Store,
    private menuService: NbMenuService,
    private dialogService: NbDialogService,
    private vt: VisualTracker,
  ) {
    this.save_menu = JSON.parse(JSON.stringify(MENU_ITEMS));
    if (!isPreproductionFE(environment) && !isLocalHost) this.vt.start();
  }
  ngOnInit() {
    this.menu = MENU_ITEMS;
    this.translateService.onLangChange.subscribe(() => {
      this.menu = this.save_menu;
      this.save_menu = JSON.parse(JSON.stringify(this.save_menu));
      this.translateMenuItems();
    });
    this.translateMenuItems();
  }

  getSelectedItems() {
    this.menuService.getSelectedItem('menu').subscribe((menuBag) => {
      if (menuBag) {
        switch (menuBag.item.title) {
          case 'Log out':
            this.store.dispatch(new SignOutUser(true));
            break;
          default:
        }
      }
    });
  }
  translateMenuItems() {
    for (const item of this.menu) this.translateMenuItem(item);
  }
  translateMenuItem(menuItem: NbMenuItem) {
    const tmp = menuItem;
    if (tmp.children) {
      for (const item of tmp.children) this.translateMenuItem(item);
    }
    if (tmp.data === undefined) {
      tmp.data = tmp.title;
      tmp.title = this.translateService.instant(menuItem.title);
    } else {
      tmp.title = this.translateService.instant(menuItem.data);
    }
  }
}
