export const PAGES_PATH = 'pages';
export const LOGIN_PATH = 'login';
export const LOGIN_WITH_EMAIL_PASS_PATH = 'login-with-email-pass';
export const REGISTER_PATH = 'register';
export const EMAIL_VERIFY_PATH = 'emailVerify';
export const DASHBOARD_PATH = 'dashboard';
export const EDIT_PROFILE_PATH = 'edit-profile';
export const UPDATE_EMAIL_PATH = 'update-email';
export const VERIFY_EMAIL_PATH = 'verify-email';
export const PROFILE_IMAGE_PATH = 'profile-image';
export const COUNTRY_UPDATE_PATH = 'update-country';
export const GETID_UPDATE_PATH = 'update-kyc2';
export const PAYOUT_ADDRESS_UPDATE_PATH = 'update-payout-address';
export const PAYOUT_LISTING = 'payout-listing';
export const PAYOUT_PATH = 'payout-transfer';
export const PENDINGTRANSFER_PATH = 'pending-transfer';
export const PAYMENT_DETAILS = 'payout-details';
export const LOADING_PATH = 'loading';
export const FREELANCER_DETAILS = 'account';
export const INBOX_PATH = 'inbox';
export const VAULTTRANSFER_PATH = 'vault-transfer';
export const SAVECODE_PATH = 'invite';
export const UPDATE_NICKNAME_PATH = 'update-nickname';
export const ACCEPT_TERMS_PATH = 'accept-terms';
export const INVITE_ONLY_PATH = 'invite-only';
export const MAINTENANCE_PATH = 'maintenance';
export const INVITE_CODE_PATH = 'invite-codes';
