import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[maxChars]',
})
export class MaxCharactersDirective {
  inputElement: HTMLInputElement;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  @Input() maxChars = 0;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line complexity
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.includes(e.key) ||
      (e.key === 'a' && e.ctrlKey) ||
      (e.key === 'c' && e.ctrlKey) ||
      (e.key === 'v' && e.ctrlKey) ||
      (e.key === 'x' && e.ctrlKey) ||
      (e.key === 'a' && e.metaKey) ||
      (e.key === 'c' && e.metaKey) ||
      (e.key === 'v' && e.metaKey) ||
      (e.key === 'x' && e.metaKey) // Allow: Cmd+X (Mac)
    ) {
      return;
    }
    if (this.inputElement.value.length <= this.maxChars - 1) {
      return;
    } else {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedInput: string = clipboardData.getData('text/plain');
      if (pastedInput.length <= this.maxChars) {
        return;
      } else {
        event.preventDefault();
      }
    }
  }
}
