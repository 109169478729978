import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { FREELANCER_PATH } from '../../model-shared/collection-names';
import { IFreelancer } from '../../model-shared/user';

@Injectable({
  providedIn: 'root',
})
export class UsersFirestore extends NgxsFirestore<IFreelancer> {
  protected path = FREELANCER_PATH;
}
