import { Component } from '@angular/core';
import { IUserModel } from '@frontend-shared/state/auth.state';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { IVaultStateModel } from '@frontend-shared/state/vault.state';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Observable } from 'rxjs';

import { navigateToVaultTransfer } from '../../../../navigation/navigation.action';

@Component({
  selector: 'indie-vault-widget',
  templateUrl: './vault.component.html',
  styleUrls: ['./vault.component.scss'],
})
export class VaultComponent {
  @SelectSnapshot((s) => s.userData) userInfo: IUserModel;
  @Select((s) => s.vault) vault$: Observable<IVaultStateModel>;
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;

  public vault_entries: any[] = [
    { reason: 'Gaurantee Fund 5%', currency: 'fas fa-dollar-sign', amount: '1,237.45' },
    { reason: 'Referral Program', currency: 'fas fa-dollar-sign', amount: '1,237.45' },
    { reason: 'Personal Stake', currency: 'fas fa-dollar-sign', amount: '1,237.45' },
    { reason: 'Gaurantee Fund 5%', currency: 'fas fa-dollar-sign', amount: '1,237.45' },
    { reason: 'Referral Program', currency: 'fas fa-dollar-sign', amount: '1,237.45' },
    { reason: 'Personal Stake', currency: 'fas fa-dollar-sign', amount: '1,237.45' },
    { reason: 'Personal Stake', currency: 'fas fa-dollar-sign', amount: '1,237.45' },
    { reason: 'Gaurantee Fund 5%', currency: 'fas fa-dollar-sign', amount: '1,237.45' },
  ];

  constructor(private store: Store) {}

  @Dispatch() navigate = () => navigateToVaultTransfer;
}
