import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PayoutService } from '../../service/payout.service';

//declare let require: any;
//const Web3Utils = require('web3-utils');
@Component({
  selector: 'ngx-payout-address',
  styleUrls: ['./payout-address.component.scss'],
  templateUrl: './payout-address.component.html',
})
export class PayoutAddressComponent implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject<boolean>();
  updateAddress = new FormGroup({
    payoutAddress: new FormControl('', [Validators.required]),
  });
  payoutAddress = '';
  incorrect = true;
  @Select((a) => a.user.user.payoutAddress) payoutAdd$: Observable<string>;

  constructor(private payoutService: PayoutService, private store: Store) {}
  ngOnInit(): void {
    this.payoutAdd$.pipe(takeUntil(this.destroy)).subscribe((addr) => {
      if (addr) {
        this.payoutAddress = addr;
        this.updateAddress.patchValue({
          payoutAddress: addr,
        });
      }
    });
    this.updateAddress.controls.payoutAddress.valueChanges.subscribe((val) => {
      if (this.payoutService.getValidAddress(val)) {
        this.updateAddress.controls.payoutAddress.setErrors(null);
      } else {
        this.updateAddress.controls.payoutAddress.setErrors({ incorrect: true });
      }
    });
  }
  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }
  @Dispatch() onSubmit = () =>
    new UsersActionsUpdate({
      payoutAddress: this.updateAddress.value.payoutAddress,
    });
}
