import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { allowedFreelancerCountries } from '@model-shared/allowed-freelancer-countries';
import { countries } from '@model-shared/countries';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';
@Component({
  selector: 'ngx-country',
  styleUrls: ['./country.component.scss'],
  templateUrl: './country.component.html',
})
export class CountryComponent implements OnInit {
  statusMessage: string;
  waitingList = false;
  public countries = countries.filter((a) => allowedFreelancerCountries.includes(a.code));
  updateCountry = new FormGroup({
    country: new FormControl('', [Validators.required]),
    domicile: new FormControl('', [Validators.required]),
  });
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;

  ngOnInit() {
    // this.store.dispatch(new LayoutActions.SetPartial(true));

    // eslint-disable-next-line complexity
    this.user$.subscribe((data) => {
      if (data.user !== undefined && data.user !== null) {
        this.updateCountry.patchValue({
          country: data.user.nationality === undefined ? '' : data.user.nationality,
          domicile: data.user.domicile === undefined ? '' : data.user.domicile,
        });

        this.waitingList =
          data.user.nationality !== undefined &&
          data.user.domicile !== undefined &&
          data.user.domicile.length > 0 &&
          data.user.nationality.length > 0 &&
          (!allowedFreelancerCountries.includes(data.user.nationality) ||
            !allowedFreelancerCountries.includes(data.user.domicile));
      }
    });
  }
  @Dispatch()
  onSubmit() {
    return new UsersActionsUpdate({
      nationality: this.updateCountry.value.country,
      domicile: this.updateCountry.value.domicile,
    });
  }
}
