import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[decimals]',
})
export class DecimalsDirective {
  private specialKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  @Input() decimals = 0;
  private check(value: string, decimals: number) {
    if (decimals <= 0) {
      return new RegExp(/^\d+$/).exec(String(value));
    } else {
      const regExpString = `^\\s*((\\d+(\\.\\d{0,${decimals}})?)|((\\d*(\\.\\d{1,${decimals}}))))\\s*$`;
      return new RegExp(regExpString).exec(String(value));
    }
  }
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !this.check(next, this.decimals)) {
      event.preventDefault();
    }
  }
}
