import { Component } from '@angular/core';
import { IUserModel } from '@frontend-shared/state/auth.state';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { IVaultStateModel } from '@frontend-shared/state/vault.state';
import { Select } from '@ngxs/store';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Observable } from 'rxjs';
@Component({
  selector: 'indie-cash-out',
  templateUrl: './cash-out.component.html',
  styleUrls: ['./cash-out.component.scss'],
})
export class CashOutComponent {
  @SelectSnapshot((s) => s.userData) userInfo: IUserModel;
  @Select((s) => s.vault) vault$: Observable<IVaultStateModel>;
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;
  @Select((state: any) => state.user.user.currency) currency$: Observable<string>;
}
