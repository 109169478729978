import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { STATUS_GLOBAL_PATH } from '../../model-shared/collection-names';

@Injectable({
  providedIn: 'root',
})
export class StatusFirestore extends NgxsFirestore<any> {
  protected path = STATUS_GLOBAL_PATH;
}
