import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashBoardUserStateGuard } from '../guard/dashboard.guard';
import {
  ACCEPT_TERMS_PATH,
  COUNTRY_UPDATE_PATH,
  DASHBOARD_PATH,
  EDIT_PROFILE_PATH,
  FREELANCER_DETAILS,
  GETID_UPDATE_PATH,
  INVITE_CODE_PATH,
  INVITE_ONLY_PATH,
  MAINTENANCE_PATH,
  PAYMENT_DETAILS,
  PAYOUT_ADDRESS_UPDATE_PATH,
  PAYOUT_LISTING,
  PROFILE_IMAGE_PATH,
  UPDATE_EMAIL_PATH,
  UPDATE_NICKNAME_PATH,
  VERIFY_EMAIL_PATH,
} from '../navigation/navigation.paths';
import { AcceptTermsComponent } from './accept-terms/accept-terms.component';
import { CountryComponent } from './country/country.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FreelancerDetailsComponent } from './dashboard/freelancer-details/freelancer-details.component';
import { InviteListComponent } from './dashboard/invite-list/invite-list.component';
import { EditprofileComponent } from './editProfile/editprofile.component';
import { GetIdVerifyComponent } from './getid-verify/get-id-verify.component';
import { InviteOnlyComponent } from './invite-only/invite-only.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PagesComponent } from './pages.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { PayoutAddressComponent } from './payout-address/payout-address.component';
import { PayoutListComponent } from './payout-list/payout-list.component';
import { ProfileImageComponent } from './profileImage/profileImage.component';
import { UpdateNickNameComponent } from './update-nick-name/update-nick-name.component';
import { UpdateEmailComponent } from './updateEmail/updateEmail.component';
import { VerifyEmailComponent } from './verifyEmail/verify-email.component';

const guardsToAccessApp = [DashBoardUserStateGuard];
const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: DASHBOARD_PATH,
        component: DashboardComponent,
        canActivate: guardsToAccessApp,
      },
      {
        path: MAINTENANCE_PATH,
        component: MaintenanceComponent,
      },
      {
        path: INVITE_ONLY_PATH,
        component: InviteOnlyComponent,
      },
      {
        path: EDIT_PROFILE_PATH,
        component: EditprofileComponent,
        canActivate: guardsToAccessApp,
      },
      {
        path: ACCEPT_TERMS_PATH,
        component: AcceptTermsComponent,
      },
      {
        path: FREELANCER_DETAILS,
        component: FreelancerDetailsComponent,
        canActivate: guardsToAccessApp,
      },
      {
        path: INVITE_CODE_PATH,
        component: InviteListComponent,
        canActivate: guardsToAccessApp,
      },
      {
        path: UPDATE_EMAIL_PATH,
        component: UpdateEmailComponent,
      },
      {
        path: VERIFY_EMAIL_PATH,
        component: VerifyEmailComponent,
      },
      {
        path: UPDATE_NICKNAME_PATH,
        component: UpdateNickNameComponent,
      },
      {
        path: PROFILE_IMAGE_PATH,
        component: ProfileImageComponent,
        canActivate: guardsToAccessApp,
      },
      {
        path: COUNTRY_UPDATE_PATH,
        component: CountryComponent,
      },
      {
        path: PAYOUT_ADDRESS_UPDATE_PATH,
        component: PayoutAddressComponent,
        canActivate: guardsToAccessApp,
      },
      {
        path: GETID_UPDATE_PATH,
        component: GetIdVerifyComponent,
      },

      {
        path: `${PAYOUT_LISTING}/:id`,
        component: PayoutListComponent,
        canActivate: guardsToAccessApp,
      },
      {
        path: `${PAYMENT_DETAILS}/:id`,
        component: PaymentDetailsComponent,
        canActivate: guardsToAccessApp,
      },

      {
        path: 'logout',
        component: DashboardComponent,
      },
      {
        path: '',
        redirectTo: DASHBOARD_PATH,
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: DASHBOARD_PATH,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
