import { Component, OnInit } from '@angular/core';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { AuthState } from '@frontend-shared/state/auth.state';
import { IUsersStateModel, UsersState } from '@frontend-shared/state/users.state';
import { countries } from '@model-shared/countries';
import { IUser } from '@model-shared/user';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import * as Bowser from 'bowser';
import { init } from 'getid-launcher';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-getid-verify',
  styleUrls: ['./get-id-verify.component.scss'],
  templateUrl: './get-id-verify.component.html',
})
export class GetIdVerifyComponent implements OnInit {
  statusMessage: string;
  @Select((state) => state.userData.userData.kycID) ongoincheck$: Observable<string>;
  @Select(AuthState.getAuthUser) userInfo$: Observable<IUser>;

  @Select(UsersState.getUser) user$: Observable<IUsersStateModel>;

  ongoincCheck$ = this.user$.pipe(
    filter((a) => a !== null && a.user !== null),
    map((a) => a.user.kycID),
  );

  noconcerns$ = this.user$.pipe(
    filter((a) => a !== null && a.details !== null),
    map(
      (a) =>
        a.details.kycConcerns === undefined ||
        a.details.kycConcerns === null ||
        (a.details.kycConcerns && a.details.kycConcerns.length === 0),
    ),
  );
  // switchToPhone = false;

  dialog: NbDialogRef<any>;
  isValidBrowser = true;

  constructor(private dialogService: NbDialogService, private store: Store) {}

  ngOnInit(): void {
    const browser = Bowser.getParser(window.navigator.userAgent);
    this.isValidBrowser = browser.satisfies({
      // or in general
      chrome: '>=84',
      firefox: '>=999',
      opera: '>=999',
      safari: '>=13',
      samsung_internet: '>=999',
      android: '>=999',
      uc: '>=999',
      edge: '>=999',
    });

    this.user$
      .pipe(
        filter((u) => u !== null),
        take(1),
      )
      .subscribe((u) => {
        // if no user => we did not go through the guards
        if (!u.user) {
          window.location.href = '/';
          return;
        }
        const nationality = u.user.nationality.toLowerCase();
        const residence = countries.find((c) => c.code === u.user.domicile).name;
        const verificationTypes = ['face-matching', 'data-extraction'];
        const config = {
          apiUrl: environment.getidapiurl,
          containerId: 'getid-component',
          metadata: {
            country: nationality,
          },
          verificationTypes,
          htmlProperties: {
            isShadowDom: false,
            disableSwitchDevice: false,
            disableSwitchLocales: true,
            isPopUp: true,
          },
          //'locale': 'de',
          flow: [
            {
              component: 'Liveness',
            },
            {
              component: 'DocumentPhoto',
              showRules: true,
              interactive: true,
              enableCheckPhoto: true,
              country: nationality,
              type: 'id-card',
            },
            {
              component: 'Selfie',
              showRules: true,
              enableCheckPhoto: true,
            },
            {
              component: 'Form',
              fields: [
                {
                  label:
                    'Proof of residence in ' +
                    residence +
                    ' ( utility bill, bank statement, public authority letter )',
                  type: 'file',
                  name: 'ResidenceDocument',
                  required: true,
                },
                {
                  label: 'I confirm that this proof of residence is less than three months old',
                  type: 'consent',
                  name: 'not-expired',
                  required: true,
                },
                {
                  label:
                    'I understand that any falsification will result in the seizure of my funds',
                  type: 'consent',
                  name: 'no-falsification',
                  required: true,
                },
              ],
            },
            /*  {
              component: 'Record',
              phrases: ['I am currently a resident of ' + residence],
            },*/
            {
              component: 'ThankYou',
            },
          ],
          onComplete: (kycID) => {
            this.store.dispatch(new UsersActionsUpdate({ kycID: kycID.id }));
            // this.dialog.close();
          },
          onFail: ({ code, message }) => {
            console.error('getid kyc problem:', code, message);
            // this.dialog.close();
          },
          onSortDocuments: (country) => (country === nationality ? ['id-card', 'passport'] : []),
        };
        init('getid-component', environment.getidapikey, config);
      });
  }
}
