import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateProfile } from '@frontend-shared/actions/auth.actions';
import { UsersActionsUpdate } from '@frontend-shared/actions/users.actions';
import { IUserModel } from '@frontend-shared/state/auth.state';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-profileimage',
  styleUrls: ['./profileImage.component.scss'],
  templateUrl: './profileImage.component.html',
})
export class ProfileImageComponent implements OnInit, OnDestroy {
  disabledSubmitButton = false;
  constructor(private store: Store) {}
  private destroy$: Subject<void> = new Subject<void>();
  @SelectSnapshot((s) => s.userData) userInfo: IUserModel;

  // @Select(FireState.getFireStoreStorage) myFiles: Observable<IFireOperation>;
  // @Emitter(FireState.saveFile) private uploadFile: Emittable<IFileUpload>;

  @Select((a) => a.user) user$: Observable<IUsersStateModel>;

  messageString: string;
  updateForm = new FormGroup({
    displayName: new FormControl('', [Validators.required]),
    image: new FormControl(''),
    nickname: new FormControl('', [Validators.required]),
  });
  ngOnInit() {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateForm.setValue({
        displayName: this.userInfo.userData.displayName,
        image: '',
        nickname: '',
      });
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  nickNameValSet(nickName: string) {
    this.updateForm.patchValue({
      nickname: nickName,
    });
  }
  // onFileSelected(event) {
  //   this.messageString = '';
  //   const n = Date.now();
  //   const file = event.target.files[0];
  //   const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  //   if (!allowedExtensions.exec(file.name)) {
  //     this.messageString = 'Please upload valid image';
  //   } else if (file.size > 99999) {
  //     this.messageString = 'Please upload image of size less than 100kb';
  //   } else {
  //     const filePath = `profileImages/${n}`;
  //     this.uploadFile.emit({ file, filePath, action: photoUpload });
  //   }
  // }
  @Dispatch()
  onSubmit() {
    this.disabledSubmitButton = true;
    setTimeout(() => {
      this.disabledSubmitButton = false;
    }, 2000);
    return [
      new UsersActionsUpdate({ nickname: this.updateForm.value.nickname }),
      new UpdateProfile({ displayName: this.updateForm.value.displayName }),
    ];
  }
}
