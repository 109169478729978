import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import { PAYMENT_REQUEST_STATUS_PATH } from '../../model-shared/collection-names';
import { IPaymentRequestStatus } from '../../model-shared/payment-request-status';

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestStatusFirestore extends NgxsFirestore<IPaymentRequestStatus> {
  protected path = PAYMENT_REQUEST_STATUS_PATH;
}
