<nb-flip-card [showToggleButton]="false" [flipped]="flipped" id="paymentRequest">
  <nb-card-front>
    <nb-card>
      <nb-card-header>
        <div class="row">
          <div style="padding-right: 0px;" class="col-5 text-left">
            <h5 class="float-left text-left">
              New Request <a href="https://indie.money/fees-and-limits/" target="_blank">
              <i class='fa fa-question-circle' aria-hidden="true"
              ></i></a>
            </h5>
          </div>
          <div style="padding-left: 0px;" class="col-7 text-right">
            <h5 *ngIf="(currency$|async) === 'USD'" class="align-middle float-right price">
              {{(vault$ | async)?.vault?.vault_3_7days_left | currency:'USD'}}
              left
            </h5>
            <h5 *ngIf="(currency$|async) !== 'USD'" class="align-middle float-right price" id="leftOfCurrency">
              ({{(vault$ | async)?.vault?.vault_3_7days_left | currencyConvert | async | currency:(currency$|async)}})
              {{(vault$ | async)?.vault?.vault_3_7days_left | currency:'USD'}} left
            </h5>
          </div>
        </div>
      </nb-card-header>

      <nb-card-body>
        <div class="row card-content-description">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-12">

                <!--
                div class="row" style="margin-bottom: 5px;">
                  <div class="col-12">
                    <div class="row p-0 mt-1">
                      <div class="col p-0">
                        <textarea rows="1" readonly nbInput fullWidth placeholder="" fieldSize="small"
                          value="{{ reviewStringTo }}"></textarea>
                      </div>
                    </div>
                    <div class="row p-0">
                      <div class="col p-0 mb-n1">
                        <textarea rows="2" readonly nbInput fullWidth placeholder="" fieldSize="small"
                          value="{{ reviewStringReason | async }}"></textarea>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="row" id="reviewPaymentCard">
                  <div id="sms-card" class="col-12 card-content-description">
                    <nb-card accent="danger" size="tiny">
                      <nb-card-header>
                        <div style="margin-top: 8px;" id="reviewHeader">
                          {{ reviewStringTo() }}
                        </div>
                        <hr class="new4">
                      </nb-card-header>
                      <nb-card-body id="reviewBody">
                        {{ reviewStringReason() | async }}
                      </nb-card-body>
                    </nb-card>

                    <!-- <nb-chat-message 
                      type="text"
                      sender="{{ reviewStringTo }}"
                      message="{{ reviewStringReason | async }}"
                      reply="true"
                    >
                    </nb-chat-message> -->
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="p-0 col-6 float-left text-left mb-2">7 days limit:</div>
                    <div *ngIf="(currency$|async) === 'USD'" class="p-0 col-5 float-right text-right">
                      {{(vault$ | async)?.vault?.maxPaymentRequestAmount | currency:'USD'}} </div>
                    <div *ngIf="(currency$|async) !== 'USD'" class="p-0 col-5 float-right text-right">
                      {{(vault$ | async)?.vault?.maxPaymentRequestAmount | currencyConvert | async | currency:(currency$|async)}}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="p-0 col-6 float-left text-left mb-2">Last 7 days:</div>
                    <div *ngIf="(currency$|async) === 'USD'" class="p-0 col-5 float-right text-right">
                      {{(vault$ | async)?.vault?.vault_3_7days | currency:'USD'}} </div>
                    <div *ngIf="(currency$|async) !== 'USD'" class="p-0 col-5 float-right text-right">
                      {{(vault$ | async)?.vault?.vault_3_7days | currencyConvert | async | currency:(currency$|async)}}
                    </div>
                  </div>
                </div>


                <!--  <div class="row">
                  <div class="col-12">
                    <div class="p-0 col-6 float-left text-left mb-2">Left:</div>
                    <div *ngIf="(currency$|async) === 'USD'" class="p-0 col-5 float-right text-right">
                      {{(vault$ | async)?.vault?.vault_3_7days_left | currency:'USD'}} </div>
                    <div *ngIf="(currency$|async) !== 'USD'" class="p-0 col-5 float-right text-right">
                      {{(vault$ | async)?.vault?.vault_3_7days_left | currencyConvert | async | currency:(currency$|async)}}
                    </div>
                  </div>
                </div>-->
              </div>
            </div>


          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div [formGroup]="paymentRequest" ngxsForm="paymentRequestForm.details">
              <div class="row inputs-con">
                <div class="col-8 col-sm-7  form-field-left">
                  <nb-form-field>
                    <input type="text" [fieldSize]="fieldSizeVariable" formControlName="amount" nbInput decimals="2"
                      fullWidth id="amountPayment"
                      placeholder="Min:{{(vault$ | async)?.vault?.minPaymentRequestAmount | currencyConvert:paymentRequest.value.currencyDisplay:true | async}} Max:{{(vault$ | async)?.vault?.vault_3_7days_left | currencyConvert:paymentRequest.value.currencyDisplay:true | async}}" />
                    <nb-icon *ngIf="paymentRequest.controls.amount.value.length != 0" nbSuffix
                      [icon]="paymentRequest.controls.amount.errors ? 'close-outline' : 'checkmark-outline'" pack="eva"
                      [attr.aria-label]="paymentRequest.controls.amount.errors ? 'Incorrect' : 'Correct'">
                    </nb-icon>
                  </nb-form-field>
                </div>
                <div class="col-4 col-sm-5 form-field-right">
                  <ng-select [clearable]="false" formControlName="currencyDisplay" id="currencyDisplay" (change)="currencyChanged()">
                      <ng-option *ngFor="let cur of currencies" [value]="cur">
                        {{cur}}
                      </ng-option>
                  </ng-select>

                  <!-- <nb-form-field class="select-inputs">
                    <nb-select [selected]="paymentRequest.controls.currencyDisplay.value" status="primary" fullWidth
                      formControlName="currencyDisplay" (selectedChange)="currenyChanged($event);">
                      <nb-option *ngFor="let cur of currencies" [value]="cur">{{cur}}</nb-option>
                    </nb-select>
                  </nb-form-field> -->
                </div>
              </div>

              <div class="row inputs-con">
                <div class="col-12 ">
                  <nb-form-field>
                    <input [fieldSize]="fieldSizeVariable" type="text" (focus)="focusPhone()"
                      (focusout)="focusoutPhone()" nbInput fullWidth id="phonePayment" [appNospace]="true" appPhoneno
                      formControlName="phone" placeholder="{{ 'PAYMENT_REQUEST.phone' | translate }}"
                      (focusout)="transformPhone($event);" />
                    <nb-icon *ngIf="paymentRequest.controls.phone.value.length > 1" nbSuffix
                      [icon]="paymentRequest.controls.phone.errors ? 'close-outline' : 'checkmark-outline'" pack="eva"
                      [attr.aria-label]="paymentRequest.controls.phone.errors ? 'Incorrect' : 'Correct'">
                    </nb-icon>
                    <nb-icon *ngIf="contactsSupported" nbSuffix
                             [icon]="'people-outline'" pack="eva" (click)="displayContacts()">
                    </nb-icon>

                  </nb-form-field>
                </div>
              </div>
              <div class="row inputs-con">
                <div class="col-8 col-sm-7 form-field-left">
                  <nb-form-field>
                    <input [fieldSize]="fieldSizeVariable" type="text" maxChars="25" nbInput fullWidth id="reasonPayment"
                      formControlName="reason" placeholder="{{ 'PAYMENT_REQUEST.reason' | translate }}" />
                    <nb-icon *ngIf="paymentRequest.controls.reason.value.length != 0" nbSuffix
                      [icon]="paymentRequest.controls.reason.errors ? 'close-outline' : 'checkmark-outline'" pack="eva"
                      [attr.aria-label]="paymentRequest.controls.reason.errors ? 'Incorrect' : 'Correct'">
                    </nb-icon>
                  </nb-form-field>
                </div>
                <div class="col-4 col-sm-5 form-field-right">

                  <ng-select [clearable]="false" class="custom" formControlName="lang">
                    <ng-option *ngFor="let lang of languages" [value]="lang.lang">
                      <span [ngClass]="lang.class"></span> &nbsp; {{ lang.lang }}
                    </ng-option>
                  </ng-select>


                  <!-- <nb-form-field class="select-inputs">
                    <nb-select fullWidth [selected]="paymentRequest.controls.lang.value" status="primary"
                      formControlName="lang">
                      <nb-option *ngFor="let lang of languages" [value]="lang.lang"><span
                          [ngClass]="lang.class"></span>{{ lang.lang }}
                      </nb-option>
                    </nb-select>
                  </nb-form-field> -->
                </div>
              </div>


            </div>
            <br>
            <button [size]="fieldSizeVariable" type="submit"
              [disabled]="!paymentRequest.valid || disabledSubmitButton" nbButton [status]="'primary'" fullWidth
              class="btn-send" (click)="flipped = !flipped" id="submitButton">
              {{submitButtonVal}}
              &nbsp;
              <i class="fas fa-arrow-right"></i>
            </button>
          </div>


        </div>
      </nb-card-body>

    </nb-card>
  </nb-card-front>
  <nb-card-back>
    <nb-card id="paymentRequestInformation">
      <nb-card-header>
        <div class="row card-content-description">
          <div class="col-6 text-left">
            <h5 class="float-left text-left">
              Transaction Fees
            </h5>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>

        <div class="row">
          <div class="col-12">
            <div class="p-0 col-6 float-left text-left">Amount</div>
              <div id="amountLabel" class="p-0 col-5 float-right text-right">
                {{paymentRequest.value.amount}} {{paymentRequest.value.currencyDisplay}}
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="p-0 col-4 float-left text-left">Fees</div>
              <div id="transactionCostLabel" class="p-0 col-7 float-right text-right">
                -{{((paymentRequest.value.amount * ((user$ | async).plan.creditCardTxVariable) +
                  ((user$ | async).plan.creditCardTxFixed | currencyConvert:paymentRequest.value.currencyDisplay:true | async))

              ) | number:'1.2-2'}} {{paymentRequest.value.currencyDisplay}}
                ({{(user$ | async).plan.creditCardTxVariable * 100 | number:'1.2-2'}}%
                +
                {{(user$ | async).plan.creditCardTxFixed | currencyConvert:paymentRequest.value.currencyDisplay:true | async | number:'1.2-2'}}
                {{paymentRequest.value.currencyDisplay}})
              </div>

          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="p-0 col-6 float-left text-left">Earnings</div>
            <div id="earningsPaymentInfo" class="p-0 col-5 float-right text-right">
              {{(paymentRequest.value.amount - ((paymentRequest.value.amount * ((user$ | async).plan.creditCardTxVariable) + ((user$ | async).plan.creditCardTxFixed | currencyConvert:paymentRequest.value.currencyDisplay:true | async)))) | number:'1.2-2'}}
              {{paymentRequest.value.currencyDisplay}}</div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-12">
            <div class="p-0 col-6 float-left text-left">95% directly to your Inbox</div>
            <div id="toInboxLabel" class="p-0 col-5 float-right text-right">
              {{((paymentRequest.value.amount - ((paymentRequest.value.amount * ((user$ | async).plan.creditCardTxVariable) + ((user$ | async).plan.creditCardTxFixed | currencyConvert:paymentRequest.value.currencyDisplay:true | async)))) * ((user$ | async).plan.howMuchToKeepInVaultRatio[0])) | number:'1.2-2'}}
              {{paymentRequest.value.currencyDisplay}}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="p-0 col-6 float-left text-left"><a href="https://indie.money/faq/payment/" target="_blank">5% to our Vault</a></div>
            <div id="guaranteeVaultLabel" class="p-0 col-5 float-right text-right">
              {{((paymentRequest.value.amount - ((paymentRequest.value.amount * ((user$ | async).plan.creditCardTxVariable) + ((user$ | async).plan.creditCardTxFixed | currencyConvert:paymentRequest.value.currencyDisplay:true | async)))) * (1 - (user$ | async).plan.howMuchToKeepInVaultRatio[0])) | number:'1.2-2'}}
              {{paymentRequest.value.currencyDisplay}}</div>
          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <div class="p-0 col-6 float-left text-left">and back to your Inbox on</div>
            <div class="p-0 col-5 float-right text-right">{{transferDateStake | date}}</div>
          </div>
        </div>

      </nb-card-body>
      <nb-card-footer>
        <div style="float: right;">
          <button nbButton status="danger" style="margin-right: 10px" (click)="onSubmit(false)" id="cancelSubmit">Cancel</button>
          <button nbButton status="primary" (click)="onSubmit(true)" id="confirmSubmit">Confirm</button>
        </div>
      </nb-card-footer>
    </nb-card>
  </nb-card-back>
</nb-flip-card>
