import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPhoneno]',
})
export class PhonenoDirective {
  inputElement: HTMLInputElement;
  @Input() appPhoneno: boolean;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line complexity
  onKeyDown(e: KeyboardEvent) {
    // if (this.inputElement.selectionStart === 0 && this.inputElement.selectionEnd === this.inputElement.value.length && e.key === 'Backspace') {
    //   console.log('all selecteddd');
    // }
    if (e.key === 'Backspace') {
      if (this.inputElement.value.length <= 1) {
        e.preventDefault();
      } else {
        return;
      }
    }
    if (
      this.navigationKeys.includes(e.key) ||
      (e.key === 'a' && e.ctrlKey) ||
      (e.key === 'c' && e.ctrlKey) ||
      (e.key === 'v' && e.ctrlKey) ||
      (e.key === 'x' && e.ctrlKey) ||
      (e.key === 'a' && e.metaKey) ||
      (e.key === 'c' && e.metaKey) ||
      (e.key === 'v' && e.metaKey) ||
      (e.key === 'x' && e.metaKey)
    ) {
      return;
    }
    if (
      (this.el.nativeElement.selectionStart === 0 && e.key === '+') ||
      (this.el.nativeElement.selectionStart !== 0 && !Number.isNaN(Number(e.key)))
    ) {
      return;
    } else {
      e.preventDefault();
    }
  }
}
