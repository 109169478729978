declare const uniqueSymbol: unique symbol;
export type EmailType = string & {
  [uniqueSymbol]: true;
};

export function assertValidEmail(input: unknown): asserts input is EmailType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (!input.includes('@')) {
    throw new Error(`The string: ${input} is not a valid email address`);
  }
}

export type FreelanceIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidFreelancerID(
  input: unknown
): asserts input is FreelanceIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  // size 17 is for demo data

  if (
    input.length !== 28 &&
    input.length !== 17 &&
    input !== NONE_FREELANCE_ID
  ) {
    throw new Error(`firestore id size must be 28 ` + input);
  }
}

export type TransferIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidTransferID(
  input: unknown
): asserts input is TransferIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20 && input !== IGNORED_TRANSFER_ID) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type PaymentRequestIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidPaymentRequestID(
  input: unknown
): asserts input is PaymentRequestIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20 && input !== EMPTY_ID) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type PaymentRequestStatusIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidPaymentRequestStatusID(
  input: unknown
): asserts input is PaymentRequestStatusIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type TransferRequestIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidTransferRequestID(
  input: unknown
): asserts input is TransferRequestIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type ChargeIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidChargeID(
  input: unknown
): asserts input is ChargeIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type ChargeStatusIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidChargeStatusID(
  input: unknown
): asserts input is ChargeStatusIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type PayoutIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidPayoutID(
  input: unknown
): asserts input is PayoutIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20 && input !== EMPTY_ID) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type TransferOrderIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidTransferOrderID(
  input: unknown
): asserts input is TransferOrderIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20 && input !== EMPTY_ID) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type NotificationIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidNotificationID(
  input: unknown
): asserts input is NotificationIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 20) {
    throw new Error(`firestore id size must be 20 ` + input);
  }
}

export type SettlementIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidSettlementID(
  input: unknown
): asserts input is SettlementIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');
}

export type PaymentIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidPaymentID(
  input: unknown
): asserts input is PaymentIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');
}

export type ChargeBackIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidChargeBackID(
  input: unknown
): asserts input is ChargeBackIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');
}

export type KYCIDType = string & {
  [uniqueSymbol]: true;
};

export function assertValidKYCID(input: unknown): asserts input is KYCIDType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');
}

export type NicknameType = string & {
  [uniqueSymbol]: true;
};

export function assertValidNickname(
  input: unknown
): asserts input is NicknameType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  /*if (input.length > 20) {
    throw new Error(`nickname length too long`);
  }*/
}

export type CountryType = string & {
  [uniqueSymbol]: true;
};

export function assertValidCountry(
  input: unknown
): asserts input is CountryType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');
  if (input.length !== 2) {
    throw new Error(`country is two letters country ISO code`);
  }
}

export type InviteCodeType = string & {
  [uniqueSymbol]: true;
};

export function assertValidInvite(
  input: unknown
): asserts input is InviteCodeType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 13 && input !== INVALID_SPONSOR_CODE) {
    throw new Error(`bad invite code`);
  }
}

export type ETHAddressType = string & {
  [uniqueSymbol]: true;
};

export function assertValidETHAddress(
  input: unknown
): asserts input is ETHAddressType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 42) {
    throw new Error(`bad eth address should be 42 chars`);
  }
}

export type CurrencyType = string & {
  [uniqueSymbol]: true;
};

export function assertValidCurrency(
  input: unknown
): asserts input is CurrencyType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 3) {
    throw new Error(`bad currency iso code`);
  }
}

export type LanguageType = string & {
  [uniqueSymbol]: true;
};

export function assertValidLanguage(
  input: unknown
): asserts input is LanguageType {
  if (typeof input !== 'string') throw new Error('Bad type - should be string');

  if (input.length !== 2) {
    throw new Error(`bad lang code`);
  }
}

export const IGNORED_TRANSFER_ID = '';
export const NONE_FREELANCE_ID = 'NONE';
export const INVALID_SPONSOR_CODE = 'INVALID';
export const EMPTY_ID = '';
export const USD_CURRENCY = 'USD';
