import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appDigitOnly]',
})
export class DigitOnlyDirective implements OnChanges {
  private hasDecimalPoint = false;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  @Input() decimal = false;
  @Input() decimalSeparator = '.';
  @Input() min = Number.NEGATIVE_INFINITY;
  @Input() max = Number.POSITIVE_INFINITY;
  @Input() pattern?: string | RegExp;
  private regex: RegExp | null = new RegExp('');
  inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pattern) {
      this.regex = this.pattern ? new RegExp(this.pattern) : null;
    }

    if (changes.min) {
      const maybeMin = Number(this.min);
      this.min = Number.isNaN(maybeMin) ? Number.NEGATIVE_INFINITY : maybeMin;
    }

    if (changes.max) {
      const maybeMax = Number(this.max);
      this.max = Number.isNaN(maybeMax) ? Number.POSITIVE_INFINITY : maybeMax;
    }
  }

  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line complexity
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.includes(e.key) ||
      (e.key === 'a' && e.ctrlKey) ||
      (e.key === 'c' && e.ctrlKey) ||
      (e.key === 'v' && e.ctrlKey) ||
      (e.key === 'x' && e.ctrlKey) ||
      (e.key === 'a' && e.metaKey) ||
      (e.key === 'c' && e.metaKey) ||
      (e.key === 'v' && e.metaKey) ||
      (e.key === 'x' && e.metaKey) || // Allow: Cmd+X (Mac)
      (this.decimal && e.key === this.decimalSeparator && !this.hasDecimalPoint) // Allow: only one decimal point
    ) {
      // let it happen, don't do anything
      return;
    }

    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || Number.isNaN(Number(e.key))) {
      e.preventDefault();
    }
    // check the input pattern RegExp
    if (this.regex) {
      const newValu = this.forecastValue(e.key);
      if (!this.regex.test(newValu)) {
        e.preventDefault();
      }
    }

    const newValue = Number(this.forecastValue(e.key));

    if (newValue > this.max || newValue < this.min) {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp() {
    this.updateDecimalPoint();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedInput: string = clipboardData.getData('text/plain');
      this.pasteData(pastedInput);
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer) {
      const textData = dataTransfer.getData('text');
      this.inputElement.focus();
      this.pasteData(textData);
      event.preventDefault();
    }
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanitizeInput(pastedContent);
    const pasted = document.execCommand('insertText', false, sanitizedContent);
    if (!pasted) {
      const { selectionStart: start, selectionEnd: end } = this.inputElement;
      if (start && end) this.inputElement.setRangeText(sanitizedContent, start, end, 'end');
    }
    this.updateDecimalPoint();
  }

  private sanitizeInput = (input: string): string => {
    const isValidDec = (s: string): boolean => {
      if (!this.hasDecimalPoint) {
        return s.split(this.decimalSeparator).length <= 2;
      } else {
        // the input element already has a decimal separator
        const selectedText = this.getSelection();
        return selectedText && selectedText.includes(this.decimalSeparator)
          ? s.split(this.decimalSeparator).length <= 2
          : !s.includes(this.decimalSeparator);
      }
    };

    let result;
    if (this.decimal && isValidDec(input)) {
      const regex = new RegExp(`[^0-9${this.decimalSeparator}]`, 'g');
      result = input.replace(regex, '');
    } else {
      result = input.replace(/\D/g, '');
    }

    const maxLength = this.inputElement.maxLength;
    if (maxLength > 0) {
      // the input element has maxLength limit
      const allowedLength = maxLength - this.inputElement.value.length;
      result = allowedLength > 0 ? result.slice(0, Math.max(0, allowedLength)) : '';
    }
    return result;
  };

  updateDecimalPoint(): void {
    if (this.decimal) {
      this.hasDecimalPoint = this.inputElement.value.includes(this.decimalSeparator);
    }
  }

  private getSelection(): string {
    if (this.inputElement.selectionStart && this.inputElement.selectionEnd)
      return this.inputElement.value.substring(
        this.inputElement.selectionStart,
        this.inputElement.selectionEnd,
      );
    return '';
  }

  private forecastValue(key: string): string {
    const selectionStart = this.inputElement.selectionStart;
    const selectionEnd = this.inputElement.selectionEnd;
    if (selectionStart && selectionEnd) {
      const oldValue = this.inputElement.value;
      const selection = oldValue.substring(selectionStart, selectionEnd);
      return selection
        ? oldValue.replace(selection, key)
        : oldValue.slice(0, Math.max(0, selectionStart)) +
            key +
            oldValue.slice(Math.max(0, selectionStart));
    }
    return '';
  }
}
