<nb-card accent="info">
    <nb-card-header>how would you like us to address you?</nb-card-header>
    <nb-card-body>
        <form class="login100-form validate-form" [formGroup]="updateForm" (ngSubmit)="onSubmit()">
        <ngx-nickname (nickNameEmit)="nickNameValSet($event);"></ngx-nickname>
        <div class="form-group">
          <button type="submit" [disabled]="!updateForm.valid || disabledSubmitButton"  nbButton status="danger">Set Nickname</button>
        </div>
      </form>
    </nb-card-body>
</nb-card>
