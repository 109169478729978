import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ITransferOrder } from '../../model-shared/transferOrder';
import { TransferOrderFirestore } from '../firestore/transferOrder.firestore';

@Pipe({ name: 'TransferOrderAmount' })
export class TransferOrderAmountPipe implements PipeTransform {
  constructor(private transferOrderFS: TransferOrderFirestore) {}

  transform(transferOrderId: string): Observable<number> {
    return this.transferOrderFS.doc$(transferOrderId).pipe(
      filter((a) => a !== null && a !== undefined),
      map((res: ITransferOrder) => res.amount),
    );
  }
}
