import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';

@Pipe({
  name: 'payoutaddress',
})
export class PayoutaddressPipe implements PipeTransform {
  constructor(private store: Store) {}
  transform(_: boolean): boolean {
    const addr = this.store.selectSnapshot((a) => a.user.user.payoutAddress);
    if (addr !== undefined && addr !== null && addr.length > 0) {
      return false;
    }
    return true;
  }
}
