export const environment = {
  production: false,
  firebaseConfig: {},
  firebaseKey: {
    apiKey: 'AIzaSyA4biQ4L9sM5yQ9TTuCd8PSQb54u-Yyums', // # pragma: allowlist secret
    authDomain: 'indie-preprod.firebaseapp.com',
    databaseURL: 'https://indie-preprod.firebaseio.com',
    projectId: 'indie-preprod',
    storageBucket: 'indie-preprod.appspot.com',
    messagingSenderId: '439560949232',
    appId: '1:439560949232:web:a704ee82a263ed4955a135',
    measurementId: 'G-HERT6P9ERC',
  },
  hereMapsApiKey: 'JJq7Vn7I07ELX9u2x1FUqgt5mZUOe-0v2iaEGnCwQUg',
  hereMapsDiscoverBaseUrl: 'https://discover.search.hereapi.com/v1/discover',
  PublicDSN: 'https://ae2f9e2bc95d4d8f958b1a027d3503db@o451229.ingest.sentry.io/5436807',
};
