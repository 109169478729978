import { NgModule } from '@angular/core';
import { RuntimeConfigLoaderModule } from 'runtime-config-loader';

import { AddrAutoCompleteDirective } from './directives/addressAutoComplete.directive';
import { AlphaSpecialDirective } from './directives/alpha-special.directive';
import { AlphanumericDirective } from './directives/alphanumeric.directive';
import { AlphasSpaceDirective } from './directives/alphas-space.directive';
import { CharsOnlyDirective } from './directives/charsOnly.directive';
import { DecimalsDirective } from './directives/decimals.directive';
import { MaxCharactersDirective } from './directives/max-chars.directive';
import { NoSpaceDirective } from './directives/nospace.directive';
import { DigitOnlyDirective } from './directives/onlyDigit.directive';
import { PhonenoDirective } from './directives/phoneno.directive';
import { CapitalizePipe } from './pipes/country.pipe';
import { CurrencyConvertPipe } from './pipes/currency.pipe';
import { DateBackPipe } from './pipes/date-back.pipe';
import { DateHumanizePipe } from './pipes/date-humanize.pipe';
import { HighlightSearchPipe } from './pipes/highlight-text.pipe';
import { PaymentRequestStatusPipe } from './pipes/payment-request-status.pipe';
import { PaymentStatusIconPipe } from './pipes/paymentStatusIcon.pipe';
import { IsPayoutPipe } from './pipes/payout.pipe';
import { PayoutaddressPipe } from './pipes/payoutaddress.pipe';
import { TransferOrderAmountPipe } from './pipes/transfer-order-amount.pipe';

@NgModule({
  imports: [RuntimeConfigLoaderModule.forRoot({ configUrl: 'assets/config.json ' })],
  exports: [
    NoSpaceDirective,
    DigitOnlyDirective,
    AlphaSpecialDirective,
    AlphanumericDirective,
    AlphasSpaceDirective,
    DecimalsDirective,
    CharsOnlyDirective,
    MaxCharactersDirective,
    CapitalizePipe,
    HighlightSearchPipe,
    PhonenoDirective,
    PaymentRequestStatusPipe,
    TransferOrderAmountPipe,
    IsPayoutPipe,
    PayoutaddressPipe,
    CurrencyConvertPipe,
    PaymentStatusIconPipe,
    DateHumanizePipe,
    DateBackPipe,
    AddrAutoCompleteDirective,
  ],
  declarations: [
    NoSpaceDirective,
    DigitOnlyDirective,
    AlphaSpecialDirective,
    AlphanumericDirective,
    AlphasSpaceDirective,
    AddrAutoCompleteDirective,
    DecimalsDirective,
    CharsOnlyDirective,
    MaxCharactersDirective,
    CapitalizePipe,
    HighlightSearchPipe,
    PhonenoDirective,
    DateHumanizePipe,
    PaymentRequestStatusPipe,
    TransferOrderAmountPipe,
    IsPayoutPipe,
    PayoutaddressPipe,
    CurrencyConvertPipe,
    DateBackPipe,
    PaymentStatusIconPipe,
  ],
})
export class FrontEndSharedModule {}
