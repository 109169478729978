import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);
@Pipe({ name: 'dateHumanize' })
export class DateHumanizePipe implements PipeTransform {
  transform(value: number): string {
    return dayjs.duration(dayjs().diff(dayjs(value))).humanize(false) + ' ago';
  }
}
