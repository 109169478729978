<div class="cash-out-container">
        <nb-card id="cashedout">
            <nb-card-header>
                <div class="row">
                    <div class="col text-left">
                        <h5 class="float-left text-left">
                            Blockchain Wallet <a href="https://indie.money/get-paid/" target="_blank">
                            <i class='fa fa-question-circle' aria-hidden="true"
                              ></i></a>
                            <!--  <a class="text-indie-blue" (click)="navigate();">Vault</a> -->
                        </h5>
                    </div>
                    <div class="col text-right">
                        <h5 *ngIf="(currency$|async) === 'USD'" class="align-middle float-right price">
                            <img class="usdc-header-icon" alt="USDC icon" src="/assets/images/usdc.png">
                            {{(vault$ | async)?.vault?.paid_out | number:'1.2-2' }}
                        </h5>
                        <h5 *ngIf="(currency$|async) !== 'USD'" class="align-middle float-right price">
                            ({{(vault$ | async)?.vault?.paid_out | currencyConvert | async | currency:(currency$|async)}})
                            <img class="usdc-header-icon" alt="USDC icon" src="/assets/images/usdc.png">
                            {{(vault$ | async)?.vault?.paid_out | number:'1.2-2' }}
                        </h5>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body class="scrollable">
                <br>


                <div class="row card-content-description">
                    <div class="col-sm-12">
                        <span style="float:left;">Pending Transfers:</span>
                        <span style="float:right;"><span *ngIf="(currency$|async) === 'USD'"
                            class="align-middle float-right price"><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                            {{(vault$ | async)?.vault?.pending_payout | number:'1.2-2' }}
                        </span>
                        <span *ngIf="(currency$|async) !== 'USD'" class="align-middle float-right price">
                            ({{(vault$ | async)?.vault?.pending_payout | currencyConvert | async | currency:(currency$|async)}})
                            <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                            {{(vault$ | async)?.vault?.pending_payout | number:'1.2-2' }}
                        </span></span>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
</div>
