import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNospace]',
})
export class NoSpaceDirective {
  @Input() appNospace: boolean;
  inputElement: HTMLInputElement;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line complexity
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.includes(e.key) ||
      (e.key === 'a' && e.ctrlKey) ||
      (e.key === 'c' && e.ctrlKey) ||
      (e.key === 'v' && e.ctrlKey) ||
      (e.key === 'x' && e.ctrlKey) ||
      (e.key === 'a' && e.metaKey) ||
      (e.key === 'c' && e.metaKey) ||
      (e.key === 'v' && e.metaKey) ||
      (e.key === 'x' && e.metaKey) // Allow: Cmd+X (Mac)
    ) {
      return;
    }
    if (this.appNospace === true && e.key === ' ') {
      e.preventDefault();
    }
  }
}
