import { NgModule } from '@angular/core';
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { EmailVerifyComponent } from './auth/emailVerify/emailVerify.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginWithEmailPassComponent } from './auth/login-with-email-pass/login-with-email-pass.component';
import { RegisterComponent } from './auth/register/register.component';
import {
  DASHBOARD_PATH,
  EMAIL_VERIFY_PATH,
  LOADING_PATH,
  LOGIN_PATH,
  LOGIN_WITH_EMAIL_PASS_PATH,
  PAGES_PATH,
  REGISTER_PATH,
  SAVECODE_PATH,
} from './navigation/navigation.paths';
import { LoadingWaitComponent } from './pages/loading-wait/loading-wait.component';
import { SaveCodeComponent } from './save-code/save-code.component';

const redirectAuthorizedToDashboard = () => redirectLoggedInTo([`${PAGES_PATH}/${DASHBOARD_PATH}`]);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([LOGIN_PATH]);

export const routes: Routes = [
  {
    path: PAGES_PATH,
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: `${SAVECODE_PATH}/:id`, // contains referral code
    component: SaveCodeComponent,
  },
  {
    path: LOGIN_PATH,
    component: LoginComponent,
    ...canActivate(redirectAuthorizedToDashboard),
  },
  {
    path: LOADING_PATH,
    component: LoadingWaitComponent,
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: LOGIN_WITH_EMAIL_PASS_PATH,
    component: LoginWithEmailPassComponent,
  },
  {
    path: REGISTER_PATH,
    component: RegisterComponent,
    ...canActivate(redirectAuthorizedToDashboard),
  },
  {
    path: EMAIL_VERIFY_PATH,
    component: EmailVerifyComponent,
  },
  { path: '', redirectTo: LOGIN_PATH, pathMatch: 'full' },
  { path: '**', redirectTo: LOGIN_PATH },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
