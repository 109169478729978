<div class="header-container" *ngIf="(layout$ | async)?.restricted || (layout$ | async)?.partialRestrict">
  <div class="logo-container">
    <!-- <a (click)="toggleSidebar()">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a> -->
    <img class="logo" src="../../../../assets/images/logo-300x150.png" (click)="navigateHome()" />
  </div>

</div>
<div class="header-container" *ngIf="(layout$ | async)?.restricted" id="header">
  <ng-select id="lang-selecter-header" *ngIf="isPreproduction" [(ngModel)]="currentLang" [clearable]="false"
    (change)="changeLang($event)">
    <ng-option *ngFor="let lang of languages" [value]="lang.lang">
      <span [ngClass]="lang.class" style="margin-right: 10px;"></span>
      <span class="lang-names">{{ lang.name }}</span>
    </ng-option>
  </ng-select>
  <ng-select *ngIf="currency" id="currency-selecter-header" [(ngModel)]="currency" style="width: 75px;" [clearable]="false"
    (ngModelChange)="setCurrency($event)">
    <ng-option *ngFor="let cur of currencies" [value]="cur" ><span class="curr_{{cur}}">{{cur}}</span></ng-option>
  </ng-select>

  <nb-action class="control-item" style="margin-right: 10px; margin-left: 10px;">
    <button class="signout" nbButton [status]="'primary'" (click)="navigateToProfile()" id="nickname">{{(nickname$ | async)}}</button>
  </nb-action>

  <nb-action class="control-item">
    <button class="signout" nbButton [status]="'primary'" (click)="signOut()">{{'HEADER.signOut' | translate}}</button>
  </nb-action>

  <nb-action class="small-screen-control-item" id="actions-header-icon-user">
    <button class="signout" nbButton
      [status]="'primary'" (click)="navigateToProfile()">
      <i class='fa fa-user' aria-hidden="true" style="font-size: 13px;"></i>
    </button>
  </nb-action>

  <nb-action class="small-screen-control-item" id="actions-header-icon-sign-out">
    <button [status]="'primary'" class="signout" nbButton
      [status]="'primary'" (click)="signOut()">
      <i class='fa fa-sign-out-alt' aria-hidden="true" style="font-size: 13px;"></i></button>
  </nb-action>

</div>
<div class="header-container" *ngIf="(layout$ | async).partialRestrict">
  <nb-action class="control-item">
    <button class="signout" nbButton [status]="'primary'" (click)="signOut()">{{'HEADER.signOut' | translate}}</button>
  </nb-action>

  <nb-action class="small-screen-control-item" id="actions-header-icon-sign-out2">
    <button [status]="'primary'" class="signout" nbButton
            [status]="'primary'" (click)="signOut()">
      <i class='fa fa-sign-out-alt' aria-hidden="true" style="font-size: 13px;"></i></button>
  </nb-action>
</div>
