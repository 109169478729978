import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentRequestStatusFirestore } from '@frontend-shared/firestore/payment-request-status.firestore';
import { PaymentRequestsFirestore } from '@frontend-shared/firestore/payment-requests.firestore';
import { IPaymentRequest } from '@model-shared/payment-request';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-payment-details',
  styleUrls: ['./payment-details.component.scss'],
  templateUrl: './payment-details.component.html',
})
export class PaymentDetailsComponent implements OnInit {
  paymentDetail = {} as IPaymentRequest;
  paymentStatuses: any;
  @Select((a) => a.paymentRequest.paymentRequest.data) paymentRequest$: Observable<
    IPaymentRequest[]
  >;
  constructor(
    private paymentRequestFS: PaymentRequestsFirestore,
    private paymentRequestStatusFS: PaymentRequestStatusFirestore,
    private router: ActivatedRoute,
  ) {}
  ngOnInit() {
    this.router.params.pipe(take(1)).subscribe(async (p) => {
      this.paymentDetail = await this.paymentRequestFS.docOnce$(p.id).toPromise();
      this.paymentStatuses = await this.paymentRequestStatusFS
        .collectionOnce$((ref) => ref.where('paymentRequestID', '==', p.id))
        .toPromise();
    });
  }
}
