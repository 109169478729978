<div class="row">
  <div class="col-12">
    <nb-flip-card [showToggleButton]="false" [flipped]="flipped" id="available">
      <nb-card-front>
        <nb-card >
          <nb-card-header>
            <div class="row">
              <div class="col-6 text-left">
                <h5 class="float-left text-left">
                  Total Earnings
                </h5>
              </div>
              <div class="col text-right">
                <h5 *ngIf="(currency$|async) === 'USD'"
                    class="align-middle float-right price"><img class="usdc-header-icon" alt="USDC icon" src="/assets/images/usdc.png">
                  {{(vault$ | async)?.vault?.total| number:'1.2-2' }}
                  </h5>
                <h5 *ngIf="(currency$|async) !== 'USD'" class="align-middle float-right price">
                  ({{(vault$ | async)?.vault?.total | currencyConvert | async | currency:(currency$|async)}})
                  <img class="usdc-header-icon" alt="USDC icon" src="/assets/images/usdc.png">
                  {{(vault$ | async)?.vault?.total | number:'1.2-2'}}
                </h5>
              </div>
            </div>
            <div>
              <span></span>
            </div>
          </nb-card-header>
          <nb-card-body>
            <br>


            <div class="row card-content-description">
              <div class="col-lg-6 col-md-12 col-sm-12 card-body-first-column">
                <div class="row">
                  <div class="col-6 text-left">
                    <span>Available now:</span>
                  </div>
                  <div class="col-6 text-right">
                    <span><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                      {{(vault$ | async)?.vault?.vault_1 | number:'1.2-2'}}</span>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-6 text-left">
                    <span>In transit:</span>
                  </div>
                  <div class="col-6 text-right">
                    <span><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                      {{(vault$ | async)?.vault?.pending_payout| number:'1.2-2'}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 text-left">
                    <span>Back in {{(vault$ | async)?.vault?.nowMonthDate | date : 'MMM-yy'}}:</span>
                  </div>
                  <div class="col-6 text-right">
                    <span><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                      {{(vault$ | async)?.vault?.thisMonthTotalTransferBackToInbox| number:'1.2-2' }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 text-left">
                    <span>Back in {{(vault$ | async)?.vault?.nowPlus1MonthDate | date : 'MMM-yy'}}:</span>
                  </div>
                  <div class="col-6 text-right">
                    <span><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                      {{(vault$ | async)?.vault?.thisMonth2TotalTransferBackToInbox | number:'1.2-2'}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 text-left">
                    <span>Back in {{(vault$ | async)?.vault?.nowPlus2MonthDate | date : 'MMM-yy'}}:</span>
                  </div>
                  <div class="col-6 text-right">
                    <span><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                      {{(vault$ | async)?.vault?.thisMonth3TotalTransferBackToInbox | number:'1.2-2'}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 text-left">
                    <span>Back in {{(vault$ | async)?.vault?.nowPlus3MonthDate | date : 'MMM-yy'}}:</span>
                  </div>
                  <div class="col-6 text-right">
                    <span><img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                      {{(vault$ | async)?.vault?.thisMonth4TotalTransferBackToInbox | number:'1.2-2'}}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div [formGroup]="transferOrder">
                  <div class="row">
                    <div class="col">
                      <div class="form-group ">
                        <nb-form-field>
                          <input fullWidth type="text" decimals="2" nbInput id="amountCashOut" formControlName="amount"
                                 placeholder="Amount to Cash Out (min. 20)" name="amount" />
                          <nb-icon nbSuffix *ngIf="transferOrder.controls.amount.value.length != 0"
                                   [icon]="transferOrder.controls.amount.errors ? 'close-outline' : 'checkmark-outline'"
                                   pack="eva"
                                   [attr.aria-label]="transferOrder.controls.amount.errors ? 'Incorrect' : 'Correct'">
                          </nb-icon>
                        </nb-form-field>
                      </div>
                    </div>
                  </div>
                  <button nbButton id="cashOutSubmit"
                  size="medium"
                  fullWidth
                  [status]="'primary'"
                          [disabled]="(!transferOrder.valid || (myBtn | payoutaddress) || disabledButton) && !setup"
                           (click)="flip()">
                    {{buttonVal}}
                    <i class="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </nb-card-front>
      <nb-card-back>
        <nb-card>
          <nb-card-header>
            <div class="row">
              <div class="col-12 text-left">
                <h5 class="float-left text-left">
                  Total Earnings
                </h5>
              </div>
            </div>
          </nb-card-header>
          <nb-card-body>

            <div class="row card-content-description">
              <div class="col-sm-12">
                  <span style="float:left;">
                    Amount to transfer
                  </span>
                  <span style="float:right;">
                    <span id="amountTransfer">{{transferOrder.value.amount}}</span> <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                  </span>
              </div>
            </div>

            <div class="row card-content-description">
              <div class="col-sm-12">
                  <span style="float:left;">
                    Blockchain
                  </span>
                  <span style="float:right;">
                    Ethereum
                  </span>
              </div>
            </div>


            <div class="row card-content-description">
              <div class="col-sm-12">
                  <span style="float:left;">
                    Arrival date
                  </span>
                  <span style="float:right;">
                    {{transferDateCashOut | date}}
                  </span>
              </div>
            </div>

            <div class="row card-content-description">
              <div class="col-sm-12">
                  <span style="float:left;">
                    Gas fee
                  </span>
                  <span style="float:right;">
                    <span id="gasFee">{{(user$ | async).plan.cashOutFee}}</span> <img class="usdc-content-icon" alt="USDC icon" src="/assets/images/usdc.png">
                  </span>
              </div>
            </div>
          </nb-card-body>
          <nb-card-footer>
            <div style="float: right;">
              <button nbButton size="medium" status="danger" style="margin-right: 10px"
                      (click)="submitVal(false, 'TRANSFER_FROM_L1_TO_OUT' , 'CASH OUT')">Cancel
              </button>
              <button nbButton size="medium" id="confirm" status="primary" (click)="submitVal(true, 'TRANSFER_FROM_L1_TO_OUT' , 'CASH OUT')">
                Confirm
              </button>
            </div>
          </nb-card-footer>
        </nb-card>
      </nb-card-back>
    </nb-flip-card>
  </div>
</div>
