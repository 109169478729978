import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IUsersStateModel } from '@frontend-shared/state/users.state';
import { nickNameValidator } from '@frontend-shared/validators/nickNamesValidator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
@Component({
  selector: 'ngx-nickname',
  templateUrl: './nickname.component.html',
  styleUrls: ['./nickname.component.scss'],
})
export class NicknameComponent implements OnInit {
  @Select((a) => a.user) user$: Observable<IUsersStateModel>;
  nickname = new FormControl('');
  @Output() nickNameEmit = new EventEmitter();
  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (user && user.details) {
        this.nickname.setValidators([
          nickNameValidator(user.details.firstNames, user.details.lastName),
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(25),
        ]);
        if (user.user === null || user.user.nickname === null || user.user.nickname === undefined) {
          this.nickname.setValue(`${user.details.firstNames} ${user.details.lastName}`);
        } else {
          this.nickname.setValue(user.user.nickname);
        }
        this.nickname.valueChanges.subscribe((val) => {
          if (this.nickname.valid) this.nickNameEmit.emit(val);
          else this.nickNameEmit.emit('');
        });
      }
    });
  }
}
