import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { FrontEndSharedModule } from '@frontend-shared/frontshared.module';
import {
  NbButtonModule,
  NbCardModule,
  NbChatModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbOptionModule,
  NbPopoverModule,
  NbSelectModule,
} from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { AvailableComponent } from './available/available.component';
import { InboxComponent } from './inbox/inbox.component';
import { NewPaymentComponent } from './new-payment/new-payment.component';
import { VaultComponent } from './vault/vault.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    NbButtonModule,
    NbFormFieldModule,
    NbOptionModule,
    NbSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    FrontEndSharedModule,
    NgSelectModule,
    NbPopoverModule,
    NbChatModule,
  ],
  declarations: [InboxComponent, AvailableComponent, VaultComponent, NewPaymentComponent],
  exports: [InboxComponent, AvailableComponent, VaultComponent, NewPaymentComponent],
})
export class IndieModule {
  constructor(private title: Title) {
    title.setTitle('Indie');
  }
}
