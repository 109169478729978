import { IFreelancer } from '../../model-shared/user';

export type UsersActionsPayloadsUpdate = Partial<IFreelancer>;
export type UsersActionsPayloadsInsert = Partial<IFreelancer>;
export type UsersActionsPayloadsCreate = Partial<IFreelancer>;

export class UsersActionsGet {
  static readonly type = '[Users] Get from auth uid';
}

export class UsersActionsUpdate {
  static readonly type = '[Users] Update';
  constructor(public payload: UsersActionsPayloadsUpdate) {}
}
