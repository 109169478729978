import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Select, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Emitted, NgxsFirestoreConnect, StreamEmitted } from '@ngxs-labs/firestore-plugin';
import { combineLatest, Observable } from 'rxjs';
import { filter, flatMap, map, switchMap, take } from 'rxjs/operators';

import { IFreelancerDetails } from '../../model-shared/freelancerDetails';
import { IPlan, PlanEnum, pricingPlans } from '../../model-shared/plan';
import { assertValidCurrency, USD_CURRENCY } from '../../model-shared/typedef';
import { IFreelancer } from '../../model-shared/user';
import { UsersActionsGet, UsersActionsUpdate } from '../actions/users.actions';
import { FreelancerDetailsFirestore } from '../firestore/freelancer-details-firestore.service';
import { StatusFirestore } from '../firestore/status.firestore';
import { UsersFirestore } from '../firestore/users.firestore';
import { IUserModel } from './auth.state';

export interface IUsersStateModel {
  user: IFreelancer;
  details: IFreelancerDetails;
  plan: IPlan;
  maintenance: boolean;
}

@State<IUsersStateModel>({
  name: 'user',
  defaults: {
    user: null,
    details: null,
    plan: null,
    maintenance: null,
  },
})
@Injectable({ providedIn: 'root' })
export class UsersState implements NgxsOnInit {
  @Select((a: any) => a.userData) authentifiedUser$: Observable<IUserModel>;
  @Selector()
  static getUser(state: IUsersStateModel): IUsersStateModel {
    return !state ? undefined : state;
  }
  ngxsOnInit(ctx: any) {
    // connect the get action to the authentication observable
    // emit a new get each time we have a valid user id
    this.ngxsFirestoreConnect.connect(UsersActionsGet, {
      to: () =>
        this.authentifiedUser$.pipe(
          filter((user) => user.userData !== null),
          map((user) => user.userData.uid),
          switchMap((userId) =>
            combineLatest([
              this.usersFS.doc$(userId),
              this.detailsFS.doc$(userId),
              this.statusFS.collection$(),
            ]),
          ),
        ),
    });
    // start listening when a user logged in
    this.authentifiedUser$
      .pipe(
        filter((user: any) => user?.userData?.uid !== undefined),
        take(1),
      )
      .subscribe(() => ctx.dispatch(new UsersActionsGet()));
  }

  constructor(
    private usersFS: UsersFirestore,
    private detailsFS: FreelancerDetailsFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private statusFS: StatusFirestore,
  ) {}
  // we don't need the id to update, we get back the user id ( no null check, we are logged in )
  @Action(UsersActionsUpdate)
  update(_: StateContext<IUsersStateModel>, { payload }: UsersActionsUpdate): Observable<string> {
    return this.authentifiedUser$.pipe(
      flatMap((user) => this.usersFS.update$(user.userData.uid, payload)),
    );
  }
  @Action(StreamEmitted(UsersActionsGet))
  get(ctx: StateContext<IUsersStateModel>, { payload }: Emitted<UsersActionsGet, any>) {
    let details: IFreelancerDetails = payload[1];
    let user: IFreelancer = payload[0];
    if (user === undefined || user === null) {
      user = {} as IFreelancer;
    }
    if (details === undefined || details === null) {
      details = {} as IFreelancerDetails;
      details.plan = '';
    }
    if (details.inviteCode === undefined || details.inviteCode === null) details.inviteCode = [];
    if (user && !user?.currency) {
      const usd = USD_CURRENCY;
      assertValidCurrency(usd);
      user.currency = usd;
    }
    ctx.setState(
      patch({
        user,
        details,
        plan:
          details?.plan === 'BASIC' ? pricingPlans[PlanEnum.BASIC] : pricingPlans[PlanEnum.TEST],
        maintenance:
          payload[2] !== null &&
          payload[2] !== undefined &&
          payload[2][payload[2]] !== undefined &&
          payload[2][payload[2].length - 1] !== undefined
            ? payload[2][payload[2].length - 1].maintenance
            : false,
      }),
    );
  }
}
