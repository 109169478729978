import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphasSpace]',
})
export class AlphasSpaceDirective {
  inputElement: HTMLInputElement;
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];
  //regex = /^[a-zA-Z ]*$/;
  regex = /^[A-Za-z]+(([ ',.-][ A-Za-z])?[A-Za-z]*)*$/;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  // eslint-disable-next-line complexity
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.includes(e.key) ||
      (e.key === 'a' && e.ctrlKey) ||
      (e.key === 'c' && e.ctrlKey) ||
      (e.key === 'v' && e.ctrlKey) ||
      (e.key === 'x' && e.ctrlKey) ||
      (e.key === 'a' && e.metaKey) ||
      (e.key === 'c' && e.metaKey) ||
      (e.key === 'v' && e.metaKey) ||
      (e.key === 'x' && e.metaKey) // Allow: Cmd+X (Mac)
    ) {
      return;
    }
    if (e.key !== ' ' && (!Number.isNaN(Number(e.key)) || !this.regex.test(e.key))) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const pastedInput: string = clipboardData.getData('text/plain');
      if (!this.regex.test(pastedInput)) {
        event.preventDefault();
      }
    }
  }
}
