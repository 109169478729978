import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Emitted, NgxsFirestoreConnect, StreamEmitted } from '@ngxs-labs/firestore-plugin';
import { Observable } from 'rxjs';
import { catchError, filter, flatMap, map } from 'rxjs/operators';

import { IPaymentRequest } from '../../model-shared/payment-request';
import { assertValidCurrency, assertValidLanguage } from '../../model-shared/typedef';
import {
  PaymentRequestsActionsCreate,
  PaymentRequestsActionsGet,
  PaymentRequestsActionsGetAll,
} from '../actions/payment-requests.actions';
import { UsersActionsUpdate } from '../actions/users.actions';
import { PaymentRequestsFirestore } from '../firestore/payment-requests.firestore';
import { FireFunctionsService } from '../services/fire-functions.service';
import { IUserModel } from './auth.state';
import { PaymentRequestFormState } from './payment-request-form-status';

export interface IPaymentRequestsStateModel {
  paymentRequest: {
    data: IPaymentRequest[];
    total: number;
  };
}
@State<IPaymentRequestsStateModel>({
  name: 'paymentRequest',
  defaults: {
    paymentRequest: { data: [], total: 0 },
  },
})
@Injectable({ providedIn: 'root' })
export class PaymentRequestsState {
  @Select((a: any) => a.userData) authentifiedUser$: Observable<IUserModel>;
  @Select(PaymentRequestFormState) form$: Observable<any>;
  @Selector() static paymentRequest(state: IPaymentRequestsStateModel) {
    return state.paymentRequest;
  }
  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(PaymentRequestsActionsGetAll, {
      to: () =>
        this.authentifiedUser$.pipe(
          filter((user: any) => user?.userData?.uid !== undefined),
          map((user) => user.userData.uid),
          flatMap((uid) =>
            this.paymentRFS.collection$((ref) =>
              ref.where('freelanceID', '==', uid).orderBy('timestamp', 'desc'),
            ),
          ),
          map((res: IPaymentRequest[]) => ({ data: res, total: res.length })),
          catchError((e, c) => {
            console.log(e);
            return c;
          }),
        ),
    });
  }
  constructor(
    private paymentRFS: PaymentRequestsFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private fireFuncs: FireFunctionsService,
  ) {}
  @Action(StreamEmitted(PaymentRequestsActionsGetAll))
  getAllEmitted(
    ctx: StateContext<IPaymentRequestsStateModel>,
    { payload }: Emitted<PaymentRequestsActionsGet, { data: IPaymentRequest[]; total: number }>,
  ) {
    ctx.setState(patch({ paymentRequest: payload }));
  }

  @Action(PaymentRequestsActionsCreate)
  async create(
    ctx: StateContext<IPaymentRequestsStateModel>,
    { payload }: PaymentRequestsActionsCreate,
  ) {
    await this.fireFuncs
      .submitPaymentRequest()(payload)
      .toPromise()
      .then(() => {
        const paymentRequestLang = payload.lang;
        assertValidLanguage(paymentRequestLang);
        const paymentRequestCurrency = payload.currencyDisplay;
        assertValidCurrency(paymentRequestCurrency);
        ctx.dispatch(
          new UsersActionsUpdate({
            paymentRequestLang,
            paymentRequestCurrency,
          }),
        );
      })
      .catch((error) => {
        console.error(error);
        window.location.href = '/' + payload.id;
      });
  }
}
